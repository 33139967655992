import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spin, Modal, Input, Form, Checkbox } from "antd";
import { MailOutlined } from "@ant-design/icons";
import "./Login.scss";
import PetcoIcon from "../../assets/icons/bone-biscuits.png";
import { login, forgotPassword } from "../../graphql/queryExecuters";
import {
  Notification,
  getStorage,
  validateEmail,
} from "../../utilities";
import { AuthActions } from "../../redux/slices/auth.slice";
import { checkAuthorization } from "../../redux/actionCreators/auth.actions";
import { getCookie } from "../../utilities/helper";
import {
  generic_email_error,
  email_sent_success,
  login_error,
  password_error,
  unregistered_email_id_error,
} from "../../constants/displayStrings";

const Login = () => {
  const dispatch = useDispatch();
  const cookieData = getCookie("rememberMe");
  const [loading, setLoading] = useState(false);
  const [forgotPasswordLoader, setForgotPasswordLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [rememberMe, setRememberMe] = useState(cookieData || false);
  const [loginForm, setLoginForm] = useState({
    username: cookieData || "",
    password: "",
  });
  const [forgotForm, setForgotForm] = useState({
    email: "",
  });

  const channelId = useSelector((state) => state.channelData.channelId);

  useEffect(() => {
    dispatch(checkAuthorization());
    const storage = getStorage();
    if (storage.getItem("token")) {
      // window.location.href = "/dashboard";
      //navigate("/dashboard");
      window.location.href = `/channels/${channelId}/sku_data`
    }
  }, []); //eslint-disable-line

  //Keep loading until page moves to home page
  useEffect(
    () => () => {
      setLoading(false);
    },
    []
  ); //eslint-disable-line

  const onClickLoginHandler = async () => {
    const isEmailValid = validateEmail(loginForm.username);
    if (isEmailValid) {
      // const isPasswordValid = validatePassword(loginForm.password);
      const isPasswordValid = !!loginForm.password.trim();

      if (isPasswordValid) {
        setLoading(true);
        const res = await login(
          loginForm.username.toLowerCase(),
          loginForm.password.trim()
        );
        if (res.__typename === "Error") {
          setLoading(false);
          if (res.status === 500) {
            Notification("error", login_error);
          } else {
            Notification("error", res.message);
          }
        } else {
          localStorage.setItem("token", res.accessToken);
          localStorage.setItem("refresh", res.refreshToken);
          window.dispatchEvent(new Event("storage"));
          const state = {
            id: res.id,
            name: res.name,
            email: res.email,
            userType: res.userType,
            accessRole: res.accessRole,
            createdBy: res.createdBy,
            editAccess: res.editAccess ? res.editAccess : "",
            accessToken: res.accessToken,
            // channelDetails: JSON.parse(res.channelDetails),
          };
          const cookieData = rememberMe ? loginForm.username : "";
          document.cookie = `rememberMe=${cookieData}`;

          dispatch(AuthActions.setAuth({ ...state }));
         // window.location.href = "/dashboard";
          //navigate("/dashboard");
          window.location.href = `/channels/${channelId}/sku_data`
        }
      } else {
        Notification("warning", password_error);
      }
    } else {
      Notification("warning", generic_email_error);
    }
  };

  const onClickForgotHandler = async () => {
    setForgotPasswordLoader(true);
    const isValidEmail = validateEmail(forgotForm.email);
    if (isValidEmail) {
      const res = await forgotPassword(forgotForm.email);
      if (res.__typename === "Error") {
        if (res.status === 429){
          Notification("error", res.message)
        }
        else{
          Notification("error", unregistered_email_id_error);
        }
      } else if (res.__typename === "defaultMessage") {
        if (res.message === "Email sent successfully.") {
          Notification("success", email_sent_success);
          setShowModal(false);
        } else {
          Notification("warning", res.message);
        }
      }
    } else {
      Notification("error", generic_email_error);
    }
    setForgotPasswordLoader(false);
  };

  const onChangeValueHandler = (field, value) => {
    setLoginForm((prevState) => {
      const state = { ...prevState };
      state[field] = value;
      return state;
    });
  };

  const forgotPasswordHandler = (field, value) => {
    setForgotForm((prevState) => {
      const state = { ...prevState };
      state[field] = value;
      return state;
    });
  };

  return (
    <Spin spinning={loading}>
      <div className="login-page-container">
        <Form className="frame-75">
          <img alt="Petco Icon" className="group-1" src={PetcoIcon} />
          <span className="login">Login</span>
          <Input
            className="input-global"
            type="text"
            placeholder="Username/email"
            defaultValue={loginForm.username}
            onChange={(e) => {
              onChangeValueHandler("username", e.target.value);
            }}
          />

          <Input.Password
            className="input-global"
            type="password"
            placeholder="Password"
            onChange={(e) => onChangeValueHandler("password", e.target.value)}
          />
          <div className="login-actions">
            <Form.Item name="remember" noStyle>
              <Checkbox
                className="remember-me"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              >
                Remember Me
              </Checkbox>
            </Form.Item>
            <div className="forget-password" onClick={() => setShowModal(true)}>
              Forgot Password?
            </div>
          </div>
          <button
            type="submit"
            className="frame-4"
            onClick={onClickLoginHandler}
          >
            <span className="login-1">Login</span>
          </button>
        </Form>
      </div>
      <Modal
        open={showModal}
        title={"Forgot Password"}
        okText="Send Email"
        okButtonProps={{
          disabled: forgotPasswordLoader,
          loading: forgotPasswordLoader,
        }}
        cancelButtonProps={{
          disabled: forgotPasswordLoader,
        }}
        cancelText="Close"
        destroyOnClose={true}
        onCancel={() => {
          setShowModal(false);
        }}
        onOk={onClickForgotHandler}
      >
        <Input
          style={{
            marginTop: 32,
            marginBottom: 32,
            paddingTop: 8,
            paddingBottom: 8,
            fontSize: 15,
          }}
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder="Enter your email"
          onChange={(e) => forgotPasswordHandler("email", e.target.value)}
        />
      </Modal>
    </Spin>
  );
};
export default Login;
