import { GraphQLClient } from 'graphql-request';
import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';
import { GRAPHQL_URL } from '../../constants/url';

export const login = async (username, password) => {
    try {
        const query = QueryCreators.createLoginQuery(username, password);
        const response = await GraphQL.request(query);
        return response.login;
    } catch (err) {
        console.log(err);
    }
};

export const addUser = async (name, username, userType, accessRole, accesslevel) => {
    try {
        const query = QueryCreators.createAddUserQuery(name, username, userType, accessRole, accesslevel);
        const response = await GraphQL.request(query);
        return response.addUser;
    } catch (err) {
        console.log(err);
    }
}

export const forgotPassword = async (email) => {
    try {
        const query = QueryCreators.forgotPasswordQuery(email);
        const response = await GraphQL.request(query);
        return response.resetPassword;
    } catch (err) {
        console.log(err);
    }
};

export const updatePassword = async (key, password) => {
    try {
        const query = QueryCreators.updatePasswordQuery(key, password);
        const response = await GraphQL.request(query);
        return response.updatePassword;
    } catch (err) {
        console.log(err);
    }
};

export const updateUser = async (userId, fieldsToUpdate) => {
    try {
        const query = QueryCreators.createUpdateUserQuery(userId, fieldsToUpdate);
        const response = await GraphQL.request(query);
        return response.updateUserInfo;
    } catch (err) {
        console.log(err);
    }
}

export const getCurrentUser = async () => {
    try {
        const query = QueryCreators.createGetCurrentUserQuery();
        const response = await GraphQL.request(query);
        return response.getCurrentUser;
    } catch (err) {
        console.log(err);
    }
}

export const getUsersList = async (channelId, userType, pageNo, pageSize, searchText, sortType) => {
    try {
        const query = QueryCreators.createGetUsersListQuery(channelId, userType, pageNo, pageSize, searchText, sortType);
        const response = await GraphQL.request(query);
        return response.getUsersList;
    } catch (err) {
        console.log(err);
    }
}

export const getUserById = async (channelId, id) => {
    try {
        const query = QueryCreators.createGetUserByIdQuery(channelId, id);
        const response = await GraphQL.request(query);
        return response.getUserById;
    } catch (err) {
        console.log(err);
    }
}

export const resetToken = async refreshToken => {
    try {
        const query = QueryCreators.createResetTokenQuery(refreshToken);
        const response = await GraphQL.request(query);
        return response.createAccessToken;
    } catch (err) {
        console.log(err);
    }
}

export const validateAccessToken = async token => {
    try {
        const query = QueryCreators.createValidateAccessTokenQuery(token);
        const graphQLInstance = new GraphQLClient(GRAPHQL_URL);
        const response = await graphQLInstance.request(query);
        return response.validateAccessToken;
    } catch (err) {
        console.log(err);
    }
}