import React, {useState, useEffect, useMemo} from 'react';
import dayjs from "dayjs";
import { useDispatch, useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
    Checkbox,
    Col,
    Row,
    Select,
    InputNumber,
    Divider,
    DatePicker,
    Typography,
    Button,
    Modal,
    Space, Spin
} from 'antd';

import './PriceConfigure.form.scss';
import {
    priceRules,
    TAB_NAME,
    priceModifierArray,
    manualPriceTypes,
    marginMinLimit,
    marginMaxLimit,
    fixedMinLimit,
    subRdMinLimit,
    subRdMaxLimit,
    subRdDefault,
    LEVEL_IDS,
    guardrailOptions
} from '../../constants/common';
import { compareLog } from '../../utilities';
import {
    getEligibleSKUsForMaster,
    updatePricelevelConfigData,
    updatePricelevelSKUConfigData
} from '../../graphql/queryExecuters';
import {Notification, validateSkuSearch, convertToUserTimeZone} from '../../utilities';
import { calculateMargin, dateSkipper, getMapPriceByConfiguration, getMsrpPriceByConfiguration } from '../../utilities/helper';
import * as DisplayStrings from '../../constants/displayStrings';
import { BrandsActions, ClassActions, SkuActions, SubclassActions } from '../../redux/slices';


const PriceConfigure = ({ parentName, levelProps, handleOk, handleCancel }) => {
    const { Text } = Typography;
    const { RangePicker } = DatePicker;
    const { Option } = Select;

    const dispatch = useDispatch();

    //Redux States
    const { classList } = useSelector(state => state.classData);
    const { subclassList } = useSelector(state => state.subclassData);
    const { brandsList } = useSelector(state => state.brandsData);
    const { skuList } = useSelector(state => state.skuData);
    const channelId = useSelector((state) => state.channelData.channelId);

    const [loading, setLoading] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [priceConfigData, setPriceConfigData] = useState(levelProps?.priceConfigData);
    const [applyAll, setApplyAll] = useState(false);

    // Helpers to check if fields are edited
    const [isMapEdited, setIsMapEdited] = useState(false);
    const [isMsrpEdited, setIsMsrpEdited] = useState(false);
    
    //SKUs for master sku dropdown (In Family Rule)
    const [filteredSKUs, setFilteredSKUs] = useState([]);

    //Helper for Date Pickers
    const [mapCalendarDate, setMapCalendarDate] = useState(null);
    const [msrpCalendarDate, setMsrpCalendarDate] = useState(null);
    useEffect(() => {
        if(priceConfigData?.masterSku){
            setFilteredSKUs([priceConfigData.masterSku]);
        }
        //Set default values here
        if(!priceConfigData?.priceRule){
            setPriceConfigData({
                ...priceConfigData,
                priceRule: 'do_not_match_competitor'
            });
        }
        if(!priceConfigData?.defaultGuardMin){
            setPriceConfigData({
                ...priceConfigData,
                defaultGuardMin : 'MAP'
            });
        }
        if(!priceConfigData?.defaultGuardMax){
            setPriceConfigData({
                ...priceConfigData,
                defaultGuardMax : 'MSRP'
            });
        }
        if(priceConfigData?.tempSubRdPrice !== 0 && !priceConfigData?.tempSubRdPrice){
            setPriceConfigData({
                ...priceConfigData,
                tempSubRdPrice : subRdDefault
            });
        }
        if(priceConfigData?.defaultGuardMin === 'Margin'){
            setPriceConfigData({
                ...priceConfigData,
                defaultGuardMinPriceSuffix : '%'
            });
        }
    }, []) //eslint-disable-line

    const onEnablePriceChangeHandler = (field, value) => {
        setPriceConfigData(prevState => {
            const state = {...prevState};
            state[field] = value;
            return state;
        });
    };

    const idDidChange = useMemo(() => {
        /*
        If relevent level ID in props is different from level ID in state, return true
        */
        switch (parentName) {
            case TAB_NAME.SKU:
                return levelProps?.priceConfigData?.skuId !== priceConfigData?.skuId;
            case TAB_NAME.BRAND:
                return levelProps?.priceConfigData?.brandId !== priceConfigData?.brandId;
            case TAB_NAME.SUB_CLASS:
                return levelProps?.priceConfigData?.subClassId !== priceConfigData?.subClassId;
            case TAB_NAME.CLASS:
                return levelProps?.priceConfigData?.classId !== priceConfigData?.classId;
            default:
                return false;
        }
    }, [levelProps?.priceConfigData, priceConfigData, parentName]);

    useEffect(() => {
        // If lowest level ID has changed, reset state
        idDidChange && setPriceConfigData(levelProps?.priceConfigData);
    }, [levelProps?.priceConfigData]); // eslint-disable-line

    const handlePriceRuleChange = (value) => {
        if(value !== 'family_rule' && value !== 'manual_price' && value!=='manual_price_with_market_tracking'){
            setPriceConfigData({
                ...priceConfigData,
                priceRule: value,
                masterSku: "",
                familyPriceModifier: "",
                familyPrice: null,
                familyPriceSuffix: "",
                manualPrice: null,
                manualPriceSuffix: "",
                manualPriceReason: ""
            });
        }
        else{
        setPriceConfigData({
            ...priceConfigData,
            priceRule: value
        });
    }
        
        //Resetting filtered SKUs if price rule changed
        if (value === 'family_rule') {
            setFilteredSKUs([]);
        }        
    };

    const onChangeValueHandler = (key, value) => {
        setPriceConfigData((prevState) => {
            const state = { ...prevState };
            state[key] = value;
            if (key === 'greenBarStatus') {
                state.greenBarPdp = null;
                state.greenBarDisplayType = null;
                if (value === 'see_price_in_cart') {
                    state.greenBarDisplayType = 'see_price_in_cart';
                }
            }
            return state;
        });
    };

    const validateData = () => {
        setSubmitClicked(true);

        //Validations for Price Rule
        if (!priceConfigData.priceRule || priceConfigData.priceRule === null || priceConfigData.priceRule === '' ) {
            Notification('error', 'Please enter the Price Rule');
            return false;
        }

        //Validations for manual Price rule
        if (!priceConfigData.priceRule || ((priceConfigData?.priceRule === 'manual_price' || priceConfigData?.priceRule === 'manual_price_with_market_tracking') &&
            (!priceConfigData?.manualPrice ||
                !priceConfigData?.manualPriceReason))) {
            Notification('error', 'Please enter the Price Rule required fields');
            return false;
        }

        //Validations for Family Rule
        if (priceConfigData?.priceRule === 'family_rule') {
            if (priceConfigData?.masterSku === priceConfigData?.skuId) {
               Notification('error', 'A SKU cannot be its own Master SKU');
               return false;
            }
            if (priceConfigData?.masterSku === '' || priceConfigData?.masterSku === null ||
                    priceConfigData?.priceModifier === '' || priceConfigData?.priceModifier === null ||
                    priceConfigData?.price === '' || priceConfigData?.price === null ||
                    priceConfigData?.familyPriceSuffix === '' || priceConfigData?.familyPriceSuffix === null)
            {
                Notification('error', 'Please enter the required highlighted fields');
                return false;
            }
        }

        //Validations for guardrail values
        if (!priceConfigData.defaultGuardMax || !priceConfigData.defaultGuardMin) {
            Notification('error', DisplayStrings.required_error);
            return false;
        }

        if (parentName === 'SKU') {
            if ((priceConfigData.defaultGuardMax === 'Margin' || priceConfigData.defaultGuardMax === 'Fixed') && 
                priceConfigData.defaultGuardMaxPrice === null) {
                Notification('error' , 'Default Guardrail Max Price should be valid' , );
                return false;
            }
            
            if ((priceConfigData.defaultGuardMin === 'Margin' || priceConfigData.defaultGuardMin === 'Fixed') && 
                priceConfigData.defaultGuardMinPrice === null) {
                Notification('error' , 'Default Guardrail Min Price should be valid' , );
                return false;
            }

        } else {
            if (priceConfigData.defaultGuardMax === 'Margin' && priceConfigData.defaultGuardMaxPrice === null) {
                Notification('error' , 'Default Guardrail Max Price should be valid' , );
                return false;
            }

            if (priceConfigData.defaultGuardMin === 'Margin' && priceConfigData.defaultGuardMinPrice === null) {
                Notification('error' , 'Default Guardrail Min Price should be valid' , );
                return false;
            }
        }

        if (priceConfigData.defaultGuardMax === 'Margin' && (priceConfigData.defaultGuardMaxPrice < marginMinLimit)) {
            Notification('error' , `Default Guardrail Max Margin should be greater than or equal to ${marginMinLimit}%` , );
            return false;
        }
        if (priceConfigData.defaultGuardMax === 'Margin' && (priceConfigData.defaultGuardMaxPrice > marginMaxLimit)) {
            Notification('error' , `Default Guardrail Max Margin should be less than or equal to ${marginMaxLimit}%` , );
            return false;
        }

        if (priceConfigData.defaultGuardMin === 'Margin' && (priceConfigData.defaultGuardMinPrice < marginMinLimit)) {
            Notification('error' , `Default Guardrail Max Margin should be greater than or equal to ${marginMinLimit}%` , );
            return false;
        }

        if (priceConfigData.defaultGuardMin === 'Margin' && (priceConfigData.defaultGuardMinPrice > marginMaxLimit)) {
            Notification('error' , `Default Guardrail Max Margin should be less than or equal to ${marginMaxLimit}%` , );
            return false;
        }

        //Validaton for min and max price
        if(parentName === TAB_NAME.SKU){
            let minPrice = priceConfigData.defaultGuardMinPrice;
            //Set min price
            if(priceConfigData.defaultGuardMin === 'Margin'){
                if(priceConfigData.costPrice){
                    minPrice = priceConfigData.costPrice / (1 - (0.01 * priceConfigData.defaultGuardMinPrice));
                } else {
                    Notification('error' , DisplayStrings.unit_cost_error);
                    return false;
                }
            }
            if(priceConfigData.defaultGuardMin === 'MAP'){
                minPrice = getMapPriceByConfiguration(priceConfigData);
                if(!minPrice) {
                    Notification('error' , DisplayStrings.map_error);
                    return false;
                }
            }
            if(priceConfigData.defaultGuardMin === 'MSRP'){
                minPrice = getMsrpPriceByConfiguration(priceConfigData);
                if(!minPrice) {
                    Notification('error' , DisplayStrings.msrp_error);
                    return false;
                }
            }
            //Set max price
            let maxPrice = priceConfigData.defaultGuardMaxPrice;
            if(priceConfigData.defaultGuardMax === 'Margin'){
                if(priceConfigData.costPrice){
                    maxPrice = priceConfigData.costPrice / (1 - (0.01 * priceConfigData.defaultGuardMaxPrice));
                } else {
                    Notification('error' , DisplayStrings.unit_cost_error);
                    return false;
                }
            }
            if(priceConfigData.defaultGuardMax === 'MAP'){
                maxPrice = getMapPriceByConfiguration(priceConfigData);
                if(!maxPrice) {
                    Notification('error' , DisplayStrings.map_error);
                    return false;
                }
            }
            if(priceConfigData.defaultGuardMax === 'MSRP'){
                maxPrice = getMsrpPriceByConfiguration(priceConfigData);
                if(!maxPrice) {
                    Notification('error' , DisplayStrings.msrp_error);
                    return false;
                }
            }
       
            if(minPrice > maxPrice){
                Notification('error' , 'Default Guardrail Min price is greater than max price');
                return false;
            }
        } else {
            if(priceConfigData.defaultGuardMax === 'Margin' && priceConfigData.defaultGuardMin === 'Margin'){
                let minPrice = priceConfigData.defaultGuardMinPrice;
                let maxPrice = priceConfigData.defaultGuardMaxPrice;
                if(minPrice > maxPrice){
                    Notification('error' , 'Default Guardrail Min price is greater than max price');
                    return false;
                }
            }
        }
    
        //Validation for guardrail prices
        if (parentName === 'SKU') {
            if (priceConfigData.tempMapPrice && priceConfigData.tempMapPrice !== priceConfigData.mapPrice) {
                if(!(priceConfigData.mapStartDate && priceConfigData.mapEndDate)) {
                    Notification('error', 'Please Fill The Interval For MAP');
                    return false;
                }
            }
            if (priceConfigData.tempMsrpPrice && priceConfigData.tempMsrpPrice !== priceConfigData.listPrice) {
                if(!(priceConfigData.msrpStartDate && priceConfigData.msrpEndDate)) {
                    Notification('error', 'Please Fill The Interval For MSRP');
                    return false;
                }
            }
            
            //Checking Sub RD Price
            if (priceConfigData?.tempSubRdPrice || priceConfigData?.tempSubRdPrice === 0) {
                if(priceConfigData.tempSubRdPrice < subRdMinLimit){
                    Notification('error', DisplayStrings.rd_error);
                    return false;
                }
                if(priceConfigData.tempSubRdPrice > subRdMaxLimit){
                    Notification('error', DisplayStrings.rd_error_max);
                    return false;
                }
                if(priceConfigData.tempSubRdPrice !== subRdDefault && !(priceConfigData.subRdStartDate)) {
                    Notification('error', DisplayStrings.rd_error_no_date);
                    return false;
                }
            } else {
                Notification('error', DisplayStrings.rd_error_no_data);
                return false;
            }
        }
        
        // if (priceConfigData?.greenBarStatus !== 'off' &&
        //     (!priceConfigData?.greenBarPdp || !priceConfigData?.greenBarDisplayType)) {
        //     Notification('error', 'Please enter the required fields: Green bar settings');
        //     return false;
        // }
        return true;
    };

    const updateConfig = async (priceConfigData) => {
        setLoading(true);

        //check if signals are updated
        let hasNoSignal = true;
        let oldpriceConfigdata =levelProps?.priceConfigData;

                          
        if(priceConfigData?.markdownPriceEnabled !== oldpriceConfigdata?.markdownPriceEnabled ||
            priceConfigData?.markdownPriceEnabled !== oldpriceConfigdata?.markdownPriceEnabled ||
            priceConfigData?.priceRule !== oldpriceConfigdata?.priceRule ||
            priceConfigData?.masterSku !== oldpriceConfigdata?.masterSku ||
            priceConfigData?.familyPriceModifier !== oldpriceConfigdata?.familyPriceModifier ||
            priceConfigData?.familyPrice !== oldpriceConfigdata?.familyPrice ||
            priceConfigData?.familyPriceSuffix !== oldpriceConfigdata?.familyPriceSuffix ||
            priceConfigData?.manualPrice !== oldpriceConfigdata?.manualPrice ||
            priceConfigData?.manualPriceSuffix !== oldpriceConfigdata?.manualPriceSuffix ||
            priceConfigData?.manualPriceReason  !== oldpriceConfigdata?.manualPriceReason){
             hasNoSignal = false;
         }

        if (parentName === 'SKU') {
            let priceData = `{
                markdownPrice: ${priceConfigData.markdownPriceEnabled ? priceConfigData.markdownPriceEnabled : false},
                basePrice: ${priceConfigData.basePriceEnabled ? priceConfigData.basePriceEnabled: false},
                defaultGuardMin: "${priceConfigData.defaultGuardMin ? priceConfigData.defaultGuardMin : ''}",
                defaultGuardMinPrice: ${
                    priceConfigData.defaultGuardMin === 'MAP' || 
                    priceConfigData.defaultGuardMin === 'MSRP' ? null : 
                    priceConfigData.defaultGuardMinPrice
                },
                defaultGuardMinPriceSuffix: ${priceConfigData.defaultGuardMin === 'MAP' || priceConfigData.defaultGuardMin === 'MSRP' ? null : (priceConfigData.defaultGuardMinPriceSuffix ? `"${priceConfigData.defaultGuardMinPriceSuffix}"` : `"$"`)},
                defaultGuardMax: "${priceConfigData.defaultGuardMax ? priceConfigData.defaultGuardMax : ''}",
                defaultGuardMaxPrice: ${
                    priceConfigData.defaultGuardMax === 'MAP' ||
                    priceConfigData.defaultGuardMax === 'MSRP' ? null : 
                    priceConfigData.defaultGuardMaxPrice
                },
                defaultGuardMaxPriceSuffix: ${ priceConfigData.defaultGuardMax === 'MAP' || priceConfigData.defaultGuardMax === 'MSRP' ? null : (priceConfigData.defaultGuardMaxPriceSuffix ? `"${priceConfigData.defaultGuardMaxPriceSuffix}"`: `"$"`)},
                priceRule: "${priceConfigData.priceRule ? priceConfigData.priceRule : ''}",
                isMaster: ${priceConfigData.isMaster ? priceConfigData.isMaster : false},
                masterSku: "${priceConfigData.masterSku ? priceConfigData.masterSku : ''}",
                familyPriceModifier: "${priceConfigData.familyPriceModifier ? priceConfigData.familyPriceModifier : ''}",
                familyPrice: ${priceConfigData.familyPrice},
                familyPriceSuffix: "${priceConfigData.familyPriceSuffix ? priceConfigData.familyPriceSuffix : ''}",
                manualPrice: ${priceConfigData.manualPrice},
                manualPriceSuffix: "${priceConfigData.manualPriceSuffix ? priceConfigData.manualPriceSuffix : '$'}",
                manualPriceReason: "${priceConfigData.manualPriceReason ? priceConfigData.manualPriceReason : ''}",
                mapPrice: ${priceConfigData.tempMapPrice},
                mapStartDate: "${ priceConfigData.mapStartDate ? convertToUserTimeZone(priceConfigData.mapStartDate).startOf('day').format('YYYY/MM/DD HH:mm:ss') : ''}",
                mapEndDate: "${priceConfigData.mapEndDate ? convertToUserTimeZone(priceConfigData.mapEndDate).endOf('day').format('YYYY/MM/DD HH:mm:ss') : ''}",
                msrpPrice: ${priceConfigData.tempMsrpPrice},
                msrpStartDate: "${priceConfigData.msrpStartDate ? convertToUserTimeZone(priceConfigData.msrpStartDate).startOf('day').format('YYYY/MM/DD HH:mm:ss') : ''}",
                msrpEndDate: "${priceConfigData.msrpEndDate ? convertToUserTimeZone(priceConfigData.msrpEndDate).endOf('day').format('YYYY/MM/DD HH:mm:ss') : ''}",
                subRdPrice: ${priceConfigData.tempSubRdPrice},
                subRdStartDate: "${priceConfigData.subRdStartDate ? convertToUserTimeZone(priceConfigData.subRdStartDate).startOf('day').format('YYYY/MM/DD HH:mm:ss'): ''}",
                subRdEndDate: "${priceConfigData.subRdEndDate ? convertToUserTimeZone(priceConfigData.subRdEndDate).endOf('day').format('YYYY/MM/DD HH:mm:ss') : ''}",
                greenBarStatus: "off",
                greenBarPdp: "",
                greenBarDisplayType: ""
            }`;

            try {
                const response = await updatePricelevelSKUConfigData(
                    channelId,
                    levelProps.configId,
                    levelProps.configdesc,
                    priceData,
                    hasNoSignal,
                    compareLog('default', oldpriceConfigdata, priceConfigData, 'sku', levelProps.configId, levelProps.configdesc)

                );
                if (response.message === 'Updation Failed') {
                    Notification('error', 'Update Failed. Please try again later.');
                    handleOk(priceData);
                }
                if (response.message === 'Update Successful') {
                    Notification('success', 'Price Configuration updated successfully');
                    handleOk(priceConfigData);
                    updateLevelDataList();

                }
            } catch (e) {
                console.log(e);
                Notification(
                    'error',
                    'Something went wrong while updating Price config Data. Please try again later.'
                );
            }
        } else {
            let priceData = `{
                    markdownPrice: ${(priceConfigData?.markdownPriceEnabled || priceConfigData?.markdownPrice) ? (priceConfigData?.markdownPriceEnabled || priceConfigData?.markdownPrice) : false},
                    basePrice: ${(priceConfigData?.basePriceEnabled || priceConfigData?.basePrice) ? (priceConfigData?.basePriceEnabled || priceConfigData?.basePrice) : false},
                    priceRule: "${priceConfigData.priceRule ? priceConfigData.priceRule : ''}",
                    defaultGuardMin: "${priceConfigData.defaultGuardMin ? priceConfigData.defaultGuardMin : ''}",
                    defaultGuardMinPrice: ${priceConfigData.defaultGuardMinPrice},
                    defaultGuardMinPriceSuffix: "${priceConfigData.defaultGuardMinPriceSuffix ? priceConfigData.defaultGuardMinPriceSuffix : '$'}",
                    defaultGuardMax: "${priceConfigData.defaultGuardMax ? priceConfigData.defaultGuardMax : ''}",
                    defaultGuardMaxPrice: ${priceConfigData.defaultGuardMaxPrice},
                    defaultGuardMaxPriceSuffix: "${priceConfigData.defaultGuardMaxPriceSuffix ? priceConfigData.defaultGuardMaxPriceSuffix: '$'}"
                }`;

            try {
                const response = await updatePricelevelConfigData(
                    channelId,
                    levelProps.configId,
                    levelProps.configdesc,
                    levelProps.configLevel,
                    priceData,
                    applyAll,
                    hasNoSignal,
                    compareLog('default', oldpriceConfigdata, priceConfigData, levelProps.configLevel, levelProps.configId, 
                    levelProps.configdesc, applyAll)
                );
                if (response === 'update failed') {
                    Notification(
                        'error',
                        'Something went wrong while updating Price config Data. Please try again later.'
                    );
                }
                if (response === 'update successfully') {
                    Notification('success', 'Price Configuration updated successfully');
                    handleOk(priceConfigData);
                    updateLevelDataList();
                }
            } catch (e) {
                console.log(e);
                Notification(
                    'error',
                    'Something went wrong while updating Price config Data. Please try again later.'
                );
            }
            // }
        }
        setLoading(false);
        setConfirm(false);
    };

    const onSearchMasterSKUHandler = async (searchText) => {
        if (validateSkuSearch(searchText)) {
            setLoading(true)
            const skuList = await getEligibleSKUsForMaster(searchText);
            const filteredSkuList = skuList.filter(sku => sku !== priceConfigData.skuId)
            setFilteredSKUs(filteredSkuList);
            setLoading(false)
        }
    };

    //Helper for setting state
    const onOpenChangeHandler = (field, open) => {
        if (open) {
            if (field === 'map') setMapCalendarDate([null, null]);
            else if (field === 'msrp') setMsrpCalendarDate([null, null]);
        } else {
            if (field === 'map') setMapCalendarDate(null);
            else if (field === 'msrp') setMsrpCalendarDate(null);
        }
    }

    //Updating skulist after price config updation
    const updateLevelDataList = () => {
        try {
            const updatedLevelList =[
                ...parentName === TAB_NAME.CLASS ? classList :
                parentName === TAB_NAME.SUB_CLASS ? subclassList :
                parentName === TAB_NAME.BRAND ? brandsList :
                parentName === TAB_NAME.SKU ? skuList : []]

            const levelIndex = updatedLevelList.findIndex(levelData => levelData[LEVEL_IDS[parentName]] === levelProps.configId);
            if (levelIndex >= 0) {
                const levelData = {...updatedLevelList[levelIndex], ...priceConfigData}
                updatedLevelList.splice(levelIndex, 1, levelData);
                if (parentName === TAB_NAME.CLASS) {
                    dispatch(ClassActions.updateClassList({ classList: updatedLevelList }));
                } else if (parentName === TAB_NAME.SUB_CLASS) {
                    dispatch(SubclassActions.updateSubclassList({ subclassList: updatedLevelList }));
                } else if (parentName === TAB_NAME.BRAND) {
                    dispatch(BrandsActions.updateBrandList({  brandList: updatedLevelList }));
                } else if (parentName === TAB_NAME.SKU) {
                    dispatch(SkuActions.updateSkuList({ skuList: updatedLevelList }));
                }
            }
        } catch (err) {
            console.log('Error - Price Configure - Error while updating level', err);
        }
    }

    return (
        <div className="priceConfigure">
                <Row className="row-1">
                    <Col span={12}>
                        <h3 className="eads-model-heading">EADS Model</h3>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Checkbox
                            className="markdownCheckbox"
                            onChange={(e) => {
                                onEnablePriceChangeHandler(
                                    parentName === TAB_NAME.SKU ? 'markdownPriceEnabled' : 'markdownPrice',
                                    e.target.checked
                                );
                            }}
                            checked={parentName === TAB_NAME.SKU ? priceConfigData?.markdownPriceEnabled : 
                                (priceConfigData?.markdownPriceEnabled || priceConfigData?.markdownPrice)}>
                            Markdown Price
                        </Checkbox>
                    </Col>
                    <Col span={12}>
                        <Checkbox
                            checked={parentName === TAB_NAME.SKU ? priceConfigData?.basePriceEnabled : 
                                (priceConfigData?.basePriceEnabled || priceConfigData?.basePrice)}
                            className="basePriceCheckbox"
                            onChange={(e) => {
                                onEnablePriceChangeHandler(
                                    parentName === TAB_NAME.SKU ? 'basePriceEnabled' : 'basePrice',
                                    e.target.checked
                                );
                            }}>
                            Base Price
                        </Checkbox>
                    </Col>
                </Row>
                <Divider />
                <Row style={{justifyContent: 'space-between'}}>
                    <Col span={7}>
                        <h3 className="price-rule-heading">Price Rule</h3>
                        <label>Price Rule</label>
                        <br />
                        <Select
                            className="priceRuleOptions"
                            status={
                                (submitClicked && !priceConfigData?.priceRule) ? 'error' : ''
                            }
                            placeholder="Select"
                            defaultValue='do_not_match_competitor'
                            onChange={(value) => {
                                handlePriceRuleChange(value);
                            }}
                            style={{
                                width: '100%'
                            }}
                            options={priceRules.map((rule) => ({
                                key: rule.key,
                                label: rule.label,
                                value: rule.key,
                                disabled: (priceConfigData?.isMaster && rule.key === 'family_rule') ||
                                        ( parentName !== TAB_NAME.SKU && 
                                            (rule.key === 'manual_price' || 
                                             rule.key === 'manual_price_with_market_tracking' || 
                                             rule.key === 'family_rule' ))
                            }))}
                            value={priceConfigData?.priceRule}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                0
                            }
                        />
                    </Col>
                    {parentName === TAB_NAME.SKU &&
                        priceConfigData?.priceRule === 'family_rule' && (
                            <Col span={4}>
                                <br />
                                <label>Master SKU</label>
                                <Select
                                    showSearch
                                    showArrow={false}
                                    style={{ width: '100%' }}
                                    value={priceConfigData?.masterSku}
                                    onChange={(value) =>
                                        setPriceConfigData({
                                            ...priceConfigData,
                                            masterSku: value
                                        })
                                    }
                                    notFoundContent={<Spin spinning={loading}/>}
                                    onSearch={onSearchMasterSKUHandler}
                                    status={(submitClicked && (
                                        !priceConfigData?.masterSku ||
                                        priceConfigData?.masterSku === priceConfigData?.skuId
                                    )) ? 'error' : ''}
                                >
                                    {filteredSKUs.map((sku) => (
                                        <Select.Option key={sku} value={sku}>
                                            {sku}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Col>
                        )}
                    {parentName === TAB_NAME.SKU &&
                        priceConfigData?.priceRule === 'family_rule' && (
                            <Col span={4}>
                                <br />
                                <label>Price Modifier</label>
                                <Select
                                    value={priceConfigData?.familyPriceModifier}
                                    onChange={(value) =>
                                        setPriceConfigData({
                                            ...priceConfigData,
                                            familyPriceModifier: value
                                        })
                                    }
                                    style={{
                                        width: '100%'
                                    }}
                                    options={priceModifierArray}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    status={
                                        (submitClicked && !priceConfigData?.familyPriceModifier) ? 'error' : ''
                                    }
                                />
                            </Col>
                        )}
                    {parentName === TAB_NAME.SKU &&
                        priceConfigData?.priceRule === 'family_rule' && (
                            <Col span={4}>
                                <br />
                                <label>Price</label>
                                <InputNumber
                                    min={0}
                                    style={{ width: '100%' }}
                                    value={priceConfigData?.familyPrice}
                                    onChange={(value) =>
                                        setPriceConfigData({
                                            ...priceConfigData,
                                            familyPrice: value
                                        })
                                    }
                                    status={
                                        (submitClicked && !priceConfigData?.familyPrice) ? 'error' : ''
                                    }
                                />
                            </Col>
                        )}
                    {parentName === TAB_NAME.SKU &&
                        priceConfigData?.priceRule === 'family_rule' && (
                            <Col span={2}>
                                <br />
                                <label>Unit</label>
                                <Select
                                    style={{
                                        width: '100%'
                                    }}
                                    options={[
                                        {
                                            key: '$',
                                            label: '$',
                                            value: '$'
                                        },
                                        {
                                            key: '%',
                                            label: '%',
                                            value: '%'
                                        }
                                    ]}
                                    onChange={(value) =>
                                        setPriceConfigData({
                                            ...priceConfigData,
                                            familyPriceSuffix: value
                                        })
                                    }
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    defaultValue={priceConfigData?.familyPriceSuffix}
                                    status={(submitClicked && !priceConfigData?.familyPriceSuffix) ? 'error': ''}
                                />
                            </Col>
                        )}
                    {parentName === TAB_NAME.SKU &&
                        (priceConfigData?.priceRule === 'manual_price' ||
                            priceConfigData?.priceRule === 'manual_price_with_market_tracking') && (
                            <Col span={8}>
                                <br />
                                <label>Manual Price</label>
                                
                                <InputNumber
                                    prefix="$"
                                    min={0}
                                    style={{ width: '100%' }}
                                    value={priceConfigData?.manualPrice}
                                    onChange={(value) =>
                                        setPriceConfigData({
                                            ...priceConfigData,
                                            manualPrice: value
                                        })
                                    }
                                    status={
                                        submitClicked && !priceConfigData?.manualPrice ? 'error' : ''
                                    }
                                />
                                
                            </Col>
                        )}
                    {parentName === TAB_NAME.SKU &&
                        (priceConfigData?.priceRule === 'manual_price' ||
                            priceConfigData?.priceRule === 'manual_price_with_market_tracking') && (
                            <Col span={8}>
                                <br />
                                <label>Type</label>
                                <Select
                                    style={{
                                        width: '100%'
                                    }}
                                    value={priceConfigData?.manualPriceReason}
                                    onChange={(value) =>
                                        setPriceConfigData({
                                            ...priceConfigData,
                                            manualPriceReason: value
                                        })
                                    }
                                    options={manualPriceTypes}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    status={submitClicked && !priceConfigData?.manualPriceReason ? 'error': ''}
                                />
                            </Col>
                        )}
                </Row>
                {parentName === TAB_NAME.SKU && (
                    <>
                        <Divider className="custom-divider" />
                        <br />
                        <Row>
                        <Text className="price-override-info-banner">
                            <InfoCircleOutlined style={{ margin: 8}}  />{DisplayStrings.price_info}
                        </Text>
                        </Row>
                        <Row className="row-2">
                            <Col span={12}>
                                <Row className="row-MAPhOLIDAY">
                                    <Col span={9}>
                                        <label>MAP</label>
                                        <InputNumber
                                            min={0}
                                            prefix="$"
                                            style={{ width: '90%' }}
                                            value={priceConfigData?.tempMapPrice || (!isMapEdited ? getMapPriceByConfiguration(priceConfigData) : null )}
                                            onChange={(value) => {
                                                setIsMapEdited(true);
                                                setPriceConfigData({
                                                    ...priceConfigData,
                                                    tempMapPrice: value
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col span={15}>
                                        <label>Schedule MAP</label>
                                        <RangePicker
                                            onCalendarChange={(e) => setMapCalendarDate(e)}
                                            onOpenChange={e=> onOpenChangeHandler('map', e)}
                                            placement="topLeft"
                                            onChange={(value, dateString) => {
                                                setPriceConfigData({
                                                    ...priceConfigData,
                                                    mapStartDate: dateString[0],
                                                    mapEndDate: dateString[1]
                                                })
                                                }
                                            }
                                            value={mapCalendarDate || [
                                                priceConfigData.mapStartDate ? convertToUserTimeZone(priceConfigData.mapStartDate) : null,
                                                priceConfigData.mapEndDate ? convertToUserTimeZone(priceConfigData.mapEndDate) : null,
                                            ]}
                                            disabledDate={current => dateSkipper(current, 14, mapCalendarDate)}
                                            changeOnBlur
                                        />
                                    </Col>
                                </Row>
                                {priceConfigData.mapStartDate && priceConfigData.mapEndDate && (<Row>
                                <button
                                    className="cancel-schedule-button"
                                    onClick={() => {
                                        setPriceConfigData({
                                            ...priceConfigData,
                                            tempMapPrice: null,
                                            mapStartDate: null,
                                            mapEndDate: null
                                        })
                                    }}
                                >
                                    Cancel Schedule
                                </button>
                                </Row>)}
                            </Col>

                            {/* vertical divider */}
                            <Col span={1}>
                                {' '}
                                <Divider
                                    type="vertical"
                                    style={{
                                        height: '95%'
                                    }}
                                />
                            </Col>

                            <Col span={11}>
                                <Row className="row-msrp">
                                    <Col span={9}>
                                        <label>MSRP</label>
                                        <InputNumber
                                            min={0}
                                            prefix="$"
                                            style={{ width: '90%' }}
                                            value={priceConfigData?.tempMsrpPrice || (!isMsrpEdited ? getMsrpPriceByConfiguration(priceConfigData) : null)}
                                            onChange={(value) =>{
                                                setIsMsrpEdited(true);
                                                setPriceConfigData({
                                                    ...priceConfigData,
                                                    tempMsrpPrice: value
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col span={15}>
                                        <label>Schedule MSRP</label>
                                        <RangePicker
                                            style={{ width: '100%' }}
                                            placement="topLeft"
                                            onCalendarChange={(e) => setMsrpCalendarDate(e)}
                                            onOpenChange={(e) => onOpenChangeHandler('msrp', e)}
                                            onChange={(value, dateString) =>
                                                setPriceConfigData({
                                                    ...priceConfigData,
                                                    msrpStartDate: dateString[0],
                                                    msrpEndDate: dateString[1]
                                                })
                                            }
                                            value={msrpCalendarDate || [
                                                priceConfigData.msrpStartDate ? convertToUserTimeZone(priceConfigData.msrpStartDate) : null,
                                                priceConfigData.msrpEndDate ? convertToUserTimeZone(priceConfigData.msrpEndDate) : null,
                                            ]}
                                            disabledDate={current => dateSkipper(current, 14, msrpCalendarDate)}
                                        />
                                    </Col>
                                </Row>
                                {priceConfigData.msrpStartDate && priceConfigData.msrpEndDate && (<Row>
                                <button
                                    className="cancel-schedule-button"
                                    onClick={() => {
                                        setPriceConfigData({
                                            ...priceConfigData,
                                            tempMsrpPrice: null,
                                            msrpStartDate: null,
                                            msrpEndDate: null
                                        })
                                    }}
                                >
                                    Cancel Schedule
                                </button>
                                </Row>)}
                            </Col>
                        </Row>
                        <br/>
                        
                        
                        <Row className="row-map">
                            <Col span={12}>
                                    <Row className="row-MAPhOLIDAY">
                                    <Col span={9}>
                                        <label>Subsequent RD Price</label><br/>
                                        <InputNumber
                                            prefix="%"
                                            min={0}
                                            max={50}
                                            style={{ width: '90%' }}
                                            value={priceConfigData?.tempSubRdPrice}
                                            onChange={(value) => {
                                                setPriceConfigData({
                                                    ...priceConfigData,
                                                    tempSubRdPrice: value
                                                })
                                            }}
                                        />
                                        {
                                        priceConfigData.lastRepricePrice ?
                                        (parseFloat(priceConfigData.tempSubRdPrice) <= subRdMaxLimit && 
                                        parseFloat(priceConfigData.tempSubRdPrice) >= subRdMinLimit) ?
                                        <div>Projected RD price: <b>${(priceConfigData.lastRepricePrice - ((priceConfigData.tempSubRdPrice/100) * priceConfigData.lastRepricePrice)).toFixed(2)}</b></div> :
                                        <div style={{color: 'red'}}>{'Enter valid percentage'} </div>:
                                        <>{typeof priceConfigData.tempSubRdPrice === 'number' ? <div className='error-mssge' style={{color: 'red'}}>{'No recommended price'}</div> : <></>}</>
                                        }
                                    </Col>
                                    <Col span={15} >
                                        <label>Schedule Subsequent RD Price</label>
                                        <br/>
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            placement="topLeft"
                                            className='rd-date-picker'
                                            onChange={(value, dateString) =>
                                                setPriceConfigData({
                                                    ...priceConfigData,
                                                    subRdStartDate:dateString,
                                                })
                                            }
                                            value={priceConfigData.subRdStartDate ? convertToUserTimeZone(priceConfigData.subRdStartDate) : null}
                                            disabledDate={dateSkipper}
                                        />
                                        {priceConfigData.subRdStartDate && (<Row>
                                <button
                                    className="cancel-schedule-button-2"
                                    onClick={() => {
                                        setPriceConfigData({
                                            ...priceConfigData,
                                            tempSubRdPrice: null,
                                            subRdStartDate: null,
                                        })
                                    }}
                                >
                                    Cancel Schedule
                                </button>
                                </Row>)}
                                    </Col>
                                </Row>
                                
                            </Col>
                        </Row>
                        <br/>
                    </>
                )}
                <Divider className="custom-divider" />
                <Row className="row-guardRail">
                    <h3 className="default-guardrail">Default Guardrail</h3>
                </Row>
                <Row className="row-3">
                    <div style={{width: 'calc(25% - 16px)', marginRight: '16px'}}>
                        <label>Default Min</label>
                        <Select
                            defaultValue='MAP'
                            status={
                                (submitClicked && priceConfigData?.defaultGuardMin === null)
                                    ? 'error'
                                    : ''
                            }
                            value={priceConfigData?.defaultGuardMin}
                            onChange={(value) => {
                                setPriceConfigData({
                                    ...priceConfigData,
                                    defaultGuardMin: value,
                                    defaultGuardMinPriceSuffix: value === 'Margin' ? '%' : '$',
                                    defaultGuardMinPrice: 
                                    value === 'MAP'? getMapPriceByConfiguration(priceConfigData) : 
                                    value === 'MSRP' ? getMsrpPriceByConfiguration(priceConfigData) :
                                    value !== 'MAP' || value !== 'MSRP' ? null :
                                    priceConfigData.defaultGuardMinPrice
                                })
                            }}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                0
                            }
                        >
                            {guardrailOptions.map(option => 
                                <Option 
                                    disabled={levelProps.configLevel !== 'sku' && option.value === 'Fixed'} 
                                    key={option.value} 
                                    value={option.value}
                                >
                                    {option.label}
                                </Option>)}
                        </Select>
                    </div>
                    <div style={{width: 'calc(25% - 16px)', marginRight: '16px'}}>
                        <label>Value</label>
                        <InputNumber
                            max={priceConfigData?.defaultGuardMin === 'Margin' ? marginMaxLimit : null}
                            min={priceConfigData?.defaultGuardMin === 'Margin' ? marginMinLimit :
                                (parentName === TAB_NAME.SKU && priceConfigData?.defaultGuardMin === 'Fixed') ?
                                fixedMinLimit : null
                            }
                            step={0.1}
                            prefix={priceConfigData?.defaultGuardMin === 'Margin'? '%': '$'}
                            style={{ width: '100%' }}
                            disabled={
                                (parentName !== TAB_NAME.SKU && priceConfigData?.defaultGuardMin !== 'Margin') ||
                                (parentName === TAB_NAME.SKU && (priceConfigData?.defaultGuardMin === 'MSRP' || priceConfigData?.defaultGuardMin === 'MAP'))
                            }
                            value={
                                priceConfigData?.defaultGuardMin === 'MSRP' ?  getMsrpPriceByConfiguration(priceConfigData) :
                                priceConfigData?.defaultGuardMin === 'MAP' ? getMapPriceByConfiguration(priceConfigData) :
                                (priceConfigData?.defaultGuardMinPrice !==null) ?  
                                priceConfigData?.defaultGuardMinPrice : ''
                            }
                            onChange={(value) =>
                                setPriceConfigData({
                                    ...priceConfigData,
                                    defaultGuardMinPrice: value
                                
                                })
                            }
                        />
                        {(parentName === TAB_NAME.SKU && priceConfigData?.defaultGuardMin === 'Fixed') ? 
                            priceConfigData?.defaultGuardMinPrice === null ?
                                <div style={{color: 'red'}}>{DisplayStrings.fixed_price_warning}</div> : 
                            null :
                        null}
                        {(priceConfigData?.defaultGuardMin === 'Margin') ?
                            ((parentName === TAB_NAME.SKU) ? 
                                (priceConfigData.costPrice ? 
                                    ((parseFloat(priceConfigData.defaultGuardMinPrice) <= marginMaxLimit 
                                    && parseFloat(priceConfigData.defaultGuardMinPrice) >= marginMinLimit) ?
                                        <div>Projected min price: <b>${calculateMargin(priceConfigData.costPrice, priceConfigData.defaultGuardMinPrice)}</b></div> : 
                                    <div style={{color: 'red'}}>{DisplayStrings.margin_warning}</div>) :
                                <div style={{color: 'red'}}>{DisplayStrings.unit_cost_error}</div>) : 
                            (priceConfigData.defaultGuardMinPrice === null)?
                                <div style={{color: 'red'}}>{DisplayStrings.margin_warning}</div> : null) : 
                            null
                        }
                    </div>
                    <div style={{width: 'calc(25% - 16px)', marginRight: '16px'}}>
                        <label>Default Max</label>
                        <Select
                            defaultValue='MSRP'
                            status={
                                (submitClicked && priceConfigData.defaultGuardMax === null)
                                    ? 'error'
                                    : ''
                            }
                            value={priceConfigData?.defaultGuardMax}
                            onChange={(value) => {
                                setPriceConfigData({
                                    ...priceConfigData,
                                    defaultGuardMax: value,
                                    defaultGuardMaxPriceSuffix: value === 'Margin' ? '%' : '$',
                                    defaultGuardMaxPrice: value === 'MAP'? getMapPriceByConfiguration(priceConfigData) : 
                                    value === 'MSRP' ? getMsrpPriceByConfiguration(priceConfigData) : 
                                    value !== 'MAP' || value !== 'MSRP' ? null :
                                    priceConfigData.defaultGuardMaxPrice
                                })
                            }}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                0
                            }
                        >
                            {guardrailOptions.map(option => 
                                <Option 
                                    disabled={levelProps.configLevel !== 'sku' && option.value === 'Fixed'} 
                                    key={option.value} 
                                    value={option.value}
                                >
                                    {option.label}
                                </Option>)}
                        </Select>
                    </div>
                    <div style={{width: '25%'}}>
                        <label>Value</label>
                        <InputNumber
                            max={priceConfigData?.defaultGuardMax === 'Margin' ? marginMaxLimit : null}
                            min={
                                priceConfigData?.defaultGuardMax === 'Margin' ? marginMinLimit : 
                                (parentName === TAB_NAME.SKU && priceConfigData?.defaultGuardMax === 'Fixed') ?
                                fixedMinLimit : null
                            }
                            step={0.1}
                            prefix={priceConfigData?.defaultGuardMax === 'Margin'? '%': '$'}
                            style={{ width: '100%'}}
                            disabled={
                                (parentName !== TAB_NAME.SKU && priceConfigData?.defaultGuardMax !== 'Margin') ||
                                (parentName === TAB_NAME.SKU &&
                                    (priceConfigData?.defaultGuardMax === 'MSRP' || priceConfigData?.defaultGuardMax === 'MAP'))
                            }
                            value={
                                priceConfigData?.defaultGuardMax === 'MSRP' ?  getMsrpPriceByConfiguration(priceConfigData) :
                                priceConfigData?.defaultGuardMax === 'MAP' ? getMapPriceByConfiguration(priceConfigData) :
                                (priceConfigData?.defaultGuardMaxPrice !==null) ?  
                                priceConfigData?.defaultGuardMaxPrice : ''
                            }
                            onChange={(value) =>
                                setPriceConfigData({
                                    ...priceConfigData,
                                    defaultGuardMaxPrice: value
                                })
                            }
                        />
                        {(parentName === TAB_NAME.SKU && priceConfigData?.defaultGuardMax === 'Fixed') ? 
                            priceConfigData?.defaultGuardMaxPrice === null ?
                                <div style={{color: 'red'}}>{DisplayStrings.fixed_price_warning}</div> : 
                            null :
                        null}
                        {(priceConfigData?.defaultGuardMax === 'Margin') ?
                            (parentName === TAB_NAME.SKU) ? 
                                priceConfigData.costPrice ? 
                                    (parseFloat(priceConfigData.defaultGuardMaxPrice) >= marginMinLimit 
                                    && parseFloat(priceConfigData.defaultGuardMaxPrice) <= marginMaxLimit) ?
                                        <div>Projected max price: <b>${calculateMargin(priceConfigData.costPrice, priceConfigData.defaultGuardMaxPrice)}</b></div> : 
                                    <div style={{color: 'red'}}>{DisplayStrings.margin_warning} {priceConfigData.defaultGuardMaxPrice}</div> :
                                <div style={{color: 'red'}}>{DisplayStrings.unit_cost_error}</div> : 
                            (priceConfigData.defaultGuardMinPrice === null)?
                                <div style={{color: 'red'}}>{DisplayStrings.margin_warning}</div> : null : 
                            null
                        }
                    </div>
                </Row>
                <br />
               
                {parentName !== TAB_NAME.SKU && <div className='scrapers-apply-config-checkbox'>
                <Text>
                    <Checkbox 
                        onChange={(e) => setApplyAll(e.target.checked)}
                        checked={applyAll} >
                    {' '}Apply configuration changes to individual configured SKUs</Checkbox>
                </Text>
                </div>}
                <div className="config-model-footer">
                    <Space>
                        <Button
                            className="discardBtn"
                            key="discard"
                            onClick={() => {
                                setSubmitClicked(false);
                                handleCancel();
                                setPriceConfigData(levelProps?.priceConfigData);
                                setApplyAll(false);
                            }}>
                            Discard Change
                        </Button>
                        <Button
                            className="updateConfigBtn"
                            key="submit"
                            type="primary"
                            onClick={() => {
                                const valid = validateData();
                                if (valid) {
                                    setSubmitClicked(false);
                                    setConfirm(true);
                                    handleCancel();
                                }
                            }}>
                            Update Price Configuration
                        </Button>
                    </Space>
                </div>
                <Modal
                    open={confirm}
                    title="Apply Changes"
                    onCancel={() => setConfirm(false)}
                    className="override-modal"
                    width={500}
                    footer={[
                        <Button
                            className="closeBtn"
                            key="close"
                            onClick={() => {
                                setConfirm(false);
                                setPriceConfigData(levelProps?.priceConfigData);
                                setApplyAll(false);
                            }}
                        >
                            Discard Change
                        </Button>,
                        <Button
                            style={{ marginLeft: 24 }}
                            loading={loading}
                            className="tiggerRescrapeBtn"
                            key="triggerRescrape"
                            type="primary"
                            onClick={() => {
                                updateConfig(priceConfigData);
                            }}>
                            Apply Configuration
                        </Button>
                    ]}>
                        <div className="apply-changes-content">
                            These changes will override the <br />
                            existing configuration, Do you wish to proceed with the selected
                            parameters?
                        </div>
                </Modal>
        </div>
    );
};

export default PriceConfigure;