import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Notification } from "../../utilities";
import { Table, Input, Pagination, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { skuTableColumns } from "../../constants/tableColumns";
import {
  getAllSkuData,
  getSkuAtBrandData,
  getSkuAtClassData,
  getSkuAtSubclassData,
  getAllAssignSkuData,
  getAssignSkuAtBrandData,
  getAssignSkuAtClassData,
  getAssignSkuAtSubclassData,
  getAllChildSkuData,
} from "../../graphql/queryExecuters";
import { SkuActions } from "../../redux/slices/sku.slice";

import "./Tables.scss";

const SKUTable = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const urlParamsObj = new URLSearchParams(location.search);
  const urlParams = urlParamsObj.toString();
  const [filterParams, setFilterParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [sortType, setSortType] = useState("ASC");
  const [rowCount, setRowCount] = useState(props.count.skuCount || props.count);
  const [tableData, setTableData] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [pageindex, setPageindex] = useState(filterParams.get("skuIndex") || 1);
  const [searchText, setSearchText] = useState(
    filterParams.get("skuSearch") || ""
  );
  const [filters, setFilter] = useState({
    publish: filterParams.get("publish") || "published",
    pricing_rule: filterParams.get("pricing_rule") || "",
  });
  const [userId, setUserId] = useState("");

  const { userType, id } = useSelector((state) => state.auth);
  const {
    level,
    index,
    search,
    sku_pricing_rule,
    sku_publish,
    sort,
    skuList,
    count,
  } = useSelector((state) => state.skuData);

  const channelId = useSelector((state) => state.channelData.channelId);

  const getIndexParam = () => parseInt(filterParams.get("skuIndex")) || 1;

  useEffect(() => {
    checkSearchTextChange();
  }, [filterParams]); //eslint-disable-line

  useEffect(() => {
    if (props?.level && userType) {
      getAllSkuDataTrigger();
    }
  }, [
    pageindex,
    filters,
    sortType,
    props.reload,
    props.level,
    userType,
    channelId,
  ]); //eslint-disable-line

  const onRowClick = (row) => {
    navigate(`/channels/${channelId}/sku_data/sku/${row.skuId}`, {
      state: { searchParams: urlParams, row },
    });
  };

  //getting all sku Data
  const getAllSkuDataTrigger = async (searchToApply = null) => {
    try {
      setLoading(true);
      const searchValue = searchToApply !== null ? searchToApply : searchText;
      const publish = filterParams.get("publish") || "published";
      const pricing_rule = filterParams.get("pricing_rule") || "";
        let skuData = [];
        const filter = `{
                    data : {
                        publish:  "${publish === "all" ? "" : publish}",
                        pricing_rule: "${pricing_rule}",
                    }
                }`;
        if (props.level === "all") {
          if (userType === "MERCHANT" && channelId) {
            skuData = await getAllAssignSkuData(
              channelId,
              id,
              getIndexParam() - 1,
              10,
              searchValue,
              filter,
              sortType
            );
          } else {
            skuData = await getAllSkuData(
              channelId,
              getIndexParam() - 1,
              10,
              searchValue,
              filter,
              sortType
            );
          }
          const value = skuData?.dataCount;
          onSearchInput(value);
        } else if (props.level === "class") {
          if (userType === "MERCHANT") {
            skuData = await getAssignSkuAtClassData(
              id, //merchant id
              props.id, // classId
              getIndexParam() - 1,
              10,
              searchValue,
              filter,
              sortType
            );
          } else {
            skuData = await getSkuAtClassData(
              channelId,
              props.id, // classId
              getIndexParam() - 1,
              10,
              searchValue,
              filter,
              sortType
            );
          }
          const value = skuData.dataCount;
          onSearchInput(value);
        } else if (props.level === "subclass") {
          if (userType === "MERCHANT") {
            skuData = await getAssignSkuAtSubclassData(
              id, //merchant id
              props.id, // subclassId
              getIndexParam() - 1,
              10,
              searchValue,
              filter,
              sortType
            );
          } else {
            skuData = await getSkuAtSubclassData(
              channelId,
              props.id, // subclassId
              getIndexParam() - 1,
              10,
              searchValue,
              filter,
              sortType
            );
          }
          const value = skuData.dataCount;
          onSearchInput(value);
        } else if (props.level === "brand") {
          if (userType === "MERCHANT") {
            skuData = await getAssignSkuAtBrandData(
              id, // merchant id
              props.id, // brandId
              props.desc,
              getIndexParam() - 1,
              10,
              searchValue,
              filter,
              sortType
            );
          } else {
            skuData = await getSkuAtBrandData(
              channelId,
              props.id, // brandId
              props.desc,
              getIndexParam() - 1,
              10,
              searchValue,
              filter,
              sortType
            );
          }
          const value = skuData.dataCount;
          onSearchInput(value);
        } else if (props.level === "merchant") {
          skuData = await getAllAssignSkuData(
            props.channelId,
            props?.merchantId,
            getIndexParam() - 1,
            10,
            searchValue,
            filter,
            sortType
          );
          const value = skuData.dataCount;
          onSearchInput(value);
        } else if (props.level === "sku") {
          skuData = await getAllChildSkuData(
            props?.id,
            getIndexParam() - 1,
            10,
            searchValue,
            filter,
            sortType
          );
          const value = skuData.dataCount;
          onSearchInput(value);
        }
        dispatch(
          SkuActions.setSkuData({
            level: props.level,
            index: pageindex,
            search: searchValue,
            sku_publish: publish,
            sku_pricing_rule: pricing_rule,
            sort: sortType,
            skuData: skuData.data,
            count: skuData.dataCount,
          })
        );
        setTableData(skuData.data);
        setRowCount(skuData.dataCount);
        setUserId(props.merchantId);
      setReloadData(props.reload);
      props.setReload && props.setReload("sku", false);
      setLoading(false);
    } catch (err) {
      console.log(err);
      Notification(
        "error",
        "Something went wrong while getting sku Data. Please try again later."
      );
      setLoading(false);
    }
  };

  const checkSearchTextChange = () => {
    const searchParamText = filterParams.get("skuSearch") || "";
    if (searchText !== searchParamText) {
      setSearchText(searchParamText);
      getAllSkuDataTrigger(searchParamText);
    }
  };

  const onSearchInput = (value) => {
    if (value < (getIndexParam() - 1) * 10) {
      filterParams.set("skuIndex", 1);
      filterParams.set("skuSearch", searchText);
      setFilterParams(filterParams);
    }
  };

  const handlePageChange = (event) => {
    filterParams.set("skuIndex", event);
    filterParams.set("publish", filterParams.get("publish") || "published");
    setFilterParams(filterParams);
    setPageindex(event);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let sort = sorter.order === "ascend" ? "ASC" : "DESC";
    setSortType(sort);
  };

  //Filter Changes
  const handleFilter = (event) => {
    if (event !== "published" && event !== "not_published" && event !== "all") {
      filterParams.set("pricing_rule", event === "all_price" ? "" : event);
      filterParams.set("publish", "published");
      setFilter({
        publish: filters.publish,
        pricing_rule: event === "all_price" ? "" : event,
      });
    } else {
      filterParams.set("publish", event);
      setFilter({
        publish: event,
        pricing_rule: filters.pricing_rule,
      });
    }
    setFilterParams(filterParams);
  };

  //Search when user press ENTER
  const onPressEnterKeyHandler = (event) => {
    try {
      if (event.key === "Enter") {
        filterParams.set("skuIndex", 1);
        filterParams.set("skuSearch", event.target.value);
        setFilterParams(filterParams);
        setPageindex(1);
        getAllSkuDataTrigger();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="table-container">
      <div className="sku-filter-row">
        <Input
          className="data-search responsive-Search-Bar"
          id="sku-search"
          prefix={<SearchOutlined />}
          onKeyDown={onPressEnterKeyHandler}
          onChange={handleSearchChange}
          value={searchText}
          placeholder={searchText ? searchText : "Search for SKU"}
          suffix={
            <span className="press-enter-style">Press enter to search</span>
          }
          disabled={loading}
        />
        {props.level !== "merchant" && (
          <Select
            className="table-dropdown"
            onChange={handleFilter}
            value={filterParams.get("publish") || "published"}
            size="large"
            options={[
              { value: "all", label: "All Skus" },
              { value: "published", label: "Published" },
              { value: "not_published", label: "Not Published" },
            ]}
          />
        )}
        {props.level !== "merchant" && (
          <Select
            className="table-dropdown"
            onChange={handleFilter}
            value={filterParams.get("pricing_rule") || "all_price"}
            size="large"
            options={[
              { value: "all_price", label: "All Price Rules" },
              { value: "match_lowest", label: "Match Lowest" },
              {
                value: "do_not_match_competitor",
                label: "Do Not Match Competitor",
              },
              {
                value: "match_second_lowest_competitor",
                label: "Match Second Lowest Competitor",
              },
              { value: "manual_price", label: "Manual Price" },
              {
                value: "manual_price_with_market_tracking",
                label: "Manual Price W/ Market Tracking",
              },
              { value: "family_rule", label: "Family Rule" },
            ]}
          />
        )}
      </div>
      <Table
        rowKey="skuId"
        id="sku-table"
        dataSource={tableData}
        columns={skuTableColumns}
        loading={loading}
        pagination={false}
        scroll={{ x: 2000 }}
        size="middle"
        onRow={(row, rowIndex) => {
          return {
            onClick: (event) => {
              props.level !== "merchant" && onRowClick(row);
            },
          };
        }}
        className="table-striped-rows clickable-table"
        rowSelection={props.level === "merchant" ? props?.rowSelection : false}
        onChange={handleTableChange}
      />
      {rowCount > 10 && !loading ? (
        <Pagination
          className="data-pagination"
          id="sku-bulk-filter"
          current={getIndexParam()}
          total={rowCount}
          onChange={handlePageChange}
          showSizeChanger={false}
          size="small"
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SKUTable;
