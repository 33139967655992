import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Notification } from '../../utilities';
import { Table, Pagination, Select, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { ScraperDataTableColumns } from '../../constants/tableColumns';
import { ScrapersActions } from '../../redux/slices/scrapers.slice';
import { CompetitorsActions } from '../../redux/slices/competitors.slice';
import {
    getScraperSku,
    getAllScrapers,
    getAllCompetitors,
} from '../../graphql/queryExecuters';
import "./Tables.scss"
import { stockTypes } from '../../constants/common';

const ScraperDataTable = (props) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [pageindex, setPageindex] = useState(1);
    const [sortType, setSortType] = useState('DESC');
    const [searchText, setSearchText] = useState('');
    const [scraper, setScraper] = useState('');
    const [competitor, setCompetitor] = useState('');
    const [stockType, setStockType] = useState('');
    const [period, setPeriod] = useState('today');

    const [tableData, setTableData] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    const [compData, setCompData] = useState([{ value: 'all', label: 'All Competitors' }]);
    const [scraperData, setScraperData] = useState([{ value: 'all', label: 'All Scrapers' }]);

    const { competitors } = useSelector((state)=> state.competitors);
    const { activeScrapers } = useSelector((state) => state.scrapers);

    useEffect(() => {
        getAllSkuScraperDataTrigger();
    }, [pageindex, scraper, competitor, stockType, period, sortType]); //eslint-disable-line

    useEffect(() => {
        getCompetitorData();
    }, [competitors, activeScrapers]); //eslint-disable-line

    const getCompetitorData = async () => {
        try {            
            if (competitors?.length === 0) {
                const competitorsData = await getAllCompetitors();
                if (competitorsData) {
                    dispatch(CompetitorsActions.setCompetitors({ competitors: competitorsData.data }));
                }
            }
            if (activeScrapers.length === 0) {
                const scrapersData = await getAllScrapers();
                const activeScraper = scrapersData.data.filter((scrap) => scrap.status);
                if (activeScraper) {
                    dispatch(ScrapersActions.setActiveScrapers({ activeScrapers: activeScraper }));
                }
            }
            setScraperData(
                [{ value: 'all', label: 'All Scrapers' }, 
                ...activeScrapers.map((item) => ({ value: item.id, label: item.name}))]
            );
            setCompData(
                [{ value: 'all', label: 'All Competitors' }, 
                ...competitors.map((item) => ({ value: item.compId, label: item.name}))]
            );
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting data. Please try again later.'
            );
        }
    };

    //getting all sku Data
    const getAllSkuScraperDataTrigger = async () => {
        try {
            setLoading(true);
            if(props && props.level === 'scraper' && props.name === 'BungeeTech'){
                let skuData = await getScraperSku(pageindex - 1, 10, searchText, competitor, stockType, period, sortType);
                setTableData(skuData.data);
                setRowCount(skuData.dataCount)
            }
            if(props && props.level === 'competitor'){
                let skuData = await getScraperSku(pageindex - 1, 10, searchText, String(props.name).toLowerCase(), stockType, period, sortType);
                setTableData(skuData.data);
                setRowCount(skuData.dataCount)
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting sku Data. Please try again later.'
            );
            setLoading(false);
        }
    };

    const handlePageChange = (event) => {
        setPageindex(event);
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        let sort = sorter.order === 'ascend' ? 'ASC' : sorter.order === 'descend' ? 'DESC' : '';
        setSortType(sort);
    };

    //Search when user press ENTER
    const onPressEnterKeyHandler = (event) => {
        try {
            if (event.key === 'Enter') getAllSkuScraperDataTrigger();
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="table-container">
            <div className="sku-filter-row">
                <Input
                    className="table-Search"
                    id="scraper-data-search"
                    prefix={<SearchOutlined />}
                    onKeyDown={onPressEnterKeyHandler}
                    onChange={handleSearchChange}
                    placeholder="Search for SKU"
                    suffix={<span className='press-enter-style'>Press enter to search</span>}
                />
                {props.level === 'scraper' && <Select
                    className="table-dropdown"
                    id="scraper-comp-list"
                    onChange={(value) => {
                            value === 'all' ? setCompetitor(''): setCompetitor(value);
                        }}
                        defaultValue={'all'}
                        size="large"
                        options={compData}
                    />
                }
                {props.level !== 'scraper' && <Select
                    className="table-dropdown"
                    id="scraper-list"
                    onChange={(value) => {
                        value === 'all' ? setScraper(''): setScraper(value);
                    }}
                    defaultValue={'all'}
                    size="large"
                    options={scraperData}
                    />
                }
                <Select
                    className="table-dropdown"
                    id='scraper-stock-dropdown'
                    onChange={(value) => {
                        value === 'all' ? setStockType(''): setStockType(value);
                    }}
                    defaultValue={'all'}
                    size="large"
                    options={[
                        { value: 'all', label: 'All Stock Types' },
                        ...stockTypes.map(type => ({ value: type, label: type }))
                    ]}
                    placeholder="Stock Types"
                />
                <Select
                    className="date-dropdown"
                    id='scraper-day-dropdown'
                    onChange={(value) => {
                        setPeriod(value);
                    }}
                    defaultValue={'today'}
                    size="large"
                    options={[
                        { value: 'today', label: 'Today' },
                        { value: 'yesterday', label: 'Yesterday' },
                        { value: 'last_seven_days', label: 'Last Seven Days' },
                        { value: 'last_ten_days', label: 'Last 10 Days' },
                        { value: 'last_twenty_days', label: 'Last 20 Days' },
                        { value: 'last_thirty_days', label: 'Last 30 Days' }
                    ]}
                    placeholder="Interval"
                />
            </div>
            <Table
                rowKey="id"
                id="scraperData-table"
                dataSource={tableData}
                columns={ScraperDataTableColumns}
                loading={loading}
                pagination={false}
                scroll={{ x: 2000 }}
                size="middle"
                className="table-striped-rows"
                onChange={handleTableChange}
            />
            {rowCount > 10 && <Pagination
                className="data-pagination"
                defaultCurrent={pageindex}
                total={rowCount}
                onChange={handlePageChange}
                showSizeChanger={false}
                size="small"
            />}
        </div>
    );
};

export default ScraperDataTable;
