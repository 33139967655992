import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Modal, Input, Switch, Tooltip, Select, Button, Divider, Spin } from 'antd';
import { DeleteOutlined, EditOutlined, ShopOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';

import { updateChannelDetails, deleteChannel, getAllMerchantSkusToAssignChannel, assignSelectedSkuToChannel } from '../../graphql/queryExecuters';
import { Notification } from '../../utilities';
import './ChannelActions.scss';

const ChannelActions = ({ channelData, getChannelsListInitiator }) => {

  const { id, userType } = useSelector((state) => state.auth);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search).toString();

  const navigate = useNavigate();

  const [showUpdateChannelModal, setShowUpdateChannelModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showManageSkuModal, setShowManageSkuModal] = useState(false);
  const [channel, setChannel] = useState({ channelId: '', channelName: '', channelOwner: '', defaultStatus: false });
  const [showError, setShowError] = useState(false);
  const [selectedChannelId, setSelectedChannelId] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [skuList, setSkuList] = useState([]);
  const [selectedSku, setSelectedSku] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);


  useEffect(() => {
    const isChanged = () => {
      return (
        channel.channelName !== channelData.channelName ||
        channel.channelOwner !== channelData.channelOwner ||
        channel.defaultStatus !== channelData.defaultStatus
      );
    };
    setUnsavedChanges(isChanged());
  }, [channel, channelData]);

  const onChannelEditHandler = async () => {
    try {
      const updatedFields = {
        channelId: channel.channelId
      };

      Object.keys(channel).forEach(key => {
        if (channel[key] !== channelData[key] && key !== 'channelId') {
          updatedFields[key] = channel[key];
        }
      });

      const res = await updateChannelDetails(updatedFields);

      if (res) {
        Notification('success', res.message);
        getChannelsListInitiator();
        setChannel({ ...channel });
      } else {
        Notification('error', 'An error occurred while updating channel details.');
      }

      setShowUpdateChannelModal(false);
    } catch (err) {
      console.log(err);
      Notification('error', 'An error occurred while updating channel details.');
    }
  };

  const handleDeleteChannel = async () => {
    try {
      const response = await deleteChannel(selectedChannelId);
      if (response) {
        Notification('success', response.message);
        getChannelsListInitiator();
      } else {
        Notification('error', 'An error occurred while deleting the channel.');
      }
      setShowDeleteModal(false);
    } catch (err) {
      console.log(err);
      Notification('error', 'Something went wrong while deleting the channel.');
    }
  };

  const gellAllAssignSkuList = async () => {
    try {
      setLoadingList(true);
      const response = await getAllMerchantSkusToAssignChannel(channelData.channelId, id);
      setSkuList(response.skuData);
      setSelectedItems(response.channelAssignedMerchantSkus);
      setLoadingList(false)
    } catch (err){
      console.log(err);
      setLoadingList(false)
    }
  }

  const onCancelAddUserHandler = () => {
    setShowUpdateChannelModal(false);
  };

  const onChangeValueHandler = (field, value) => {
    setChannel(prevState => ({ ...prevState, [field]: value }));
  };

  const openChannelEditModal = () => {
    setChannel({ channelId: channelData.channelId, channelName: channelData.channelName, channelOwner: channelData.channelOwner, defaultStatus: channelData.defaultStatus });
    setShowUpdateChannelModal(true);
  };

  const openChannelDeleteModal = (channelId) => {
    setSelectedChannelId(channelId);
    setShowDeleteModal(true);
  }

  const openManageSkuModal = () => {
    setShowManageSkuModal(true);
    gellAllAssignSkuList();
  }

  const handleAddSku = () => {
    if (selectedSku) {
      if (selectedItems.some(item => item.skuId === selectedSku.skuId)) {
        Notification('warning', 'This SKU is already added');
      } else {
        setSelectedItems([...selectedItems, selectedSku]);
        setSelectedSku(null);
      }
    } else {
      Notification('warning', 'Please select an item to add');
    }
  };

  const handleRemove = (item) => {
    setSelectedItems(selectedItems.filter((selected) => selected.skuId !== item.skuId));
  };

  const addSelectedSkuToChannel = async () => {
    try{
      setLoadingButton(true);
      const skuIds = selectedItems.map(item => `"${item.skuId}"`);
      const response = await assignSelectedSkuToChannel(skuIds, channelData.channelId, id)
      if (response.message == "success") {
        setLoadingButton(false)
        Notification('success', 'SKUs asigned Successfully');
        setShowManageSkuModal(false);
      } else {
        setLoadingButton(false);
        Notification('error', 'An error occurred while assigning SKUs to channel.');
      }
    } catch (err) {
      setLoadingButton(false);
      console.log(err);
      Notification('error', 'Something went wrong while assigning SKUs to channel.');
    }
  }

  const handleAssignSkusClick = () => {
    navigate(`/settings/channels/${channelData.channelId}`, { state: { searchParams: urlParams, row: channelData} })
  };

  return (
    <>
      <Modal
        open={showUpdateChannelModal}
        title="Edit Channel"
        okText="Save Changes"
        cancelText="Close"
        onCancel={onCancelAddUserHandler}
        onOk={onChannelEditHandler}
        okButtonProps={{ disabled: !unsavedChanges }} // Disable Save Changes button if no changes made
      >
        <div style={{ marginTop: '24px' }}>Channel Name</div>
        <Input
          className='input-global'
          id='edit-channel-name'
          value={channel.channelName}
          onChange={(e) => onChangeValueHandler('channelName', e.target.value)}
          status={(showError && !channel.channelName.trim()) ? 'error' : ''}
        />
        <div style={{ marginTop: '16px' }}>Channel Admin</div>
        <Input
          disabled
          className='input-global'
          id='edit-channel-owner'
          value={channel.channelOwner}
          onChange={(e) => onChangeValueHandler('channelOwner', e.target.value)}
          status={(showError && !channel.channelOwner.trim()) ? 'error' : ''}
        />
        {channelData.defaultStatus === 'false' && (
          <>
            <div style={{ marginTop: '16px' }}>Set Default Channel</div>
            <Switch checked={channel.defaultStatus === 'true'} onChange={(checked) => onChangeValueHandler('defaultStatus', checked ? 'true' : 'false')} />
          </>
        )}
      </Modal>

      <Modal
        open={showDeleteModal}
        title="Delete Channel"
        okText="Confirm"
        cancelText="Cancel"
        onCancel={() => setShowDeleteModal(false)}
        onOk={handleDeleteChannel}
      >
        <p>Are you sure you want to delete this channel?</p>
      </Modal>

      <Modal
        open={showManageSkuModal}
        title="Assign SKU's"
        okText="Add Selected"
        cancelText="Close"
        onCancel={() => setShowManageSkuModal(false)}
        onOk={addSelectedSkuToChannel}
        confirmLoading={loadingButton}
      >
          <div className="add-merchant-items-modal">
            <div className="search-container">
              <Select
                className="selectLevel"
                id="merchant-select-hierarchy"
                showSearch
                style={{
                  width: '70%',
                  color: 'white'
                }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.label.toLowerCase().includes(input.toLowerCase())
                }
                notFoundContent={loadingList ? <Spin size="small" /> : null}
                options={skuList.map((sku) => ({
                  value: sku.skuId,
                  label: sku.skuDesc
                }))}
                value={selectedSku?.skuId}
                onChange={(value, option) => setSelectedSku({ skuId: value, skuDesc: option.label })}
              />
              <Button type='primary' icon={<PlusOutlined />} id='merchant-add-btn' onClick={handleAddSku}>
                Add
              </Button>
            </div>
            <div className="selected-items">
              <div className="title">Selected</div>
              <Divider style={{ marginBottom: 8 }} />
              {/* <div className="selected-items-scrollable">
                {selectedItems.map((item) => (
                  <li key={item.skuId}>
                    <p
                      style={{
                        color: '#001952',
                        font: '10px',
                        margin: 0
                      }}>
                      {item.skuDesc}{' '}
                      <CloseOutlined
                        style={{ float: 'right', marginRight: 16 }}
                        onClick={() => handleRemove(item)}
                      />
                    </p>
                    <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                  </li>
                ))}
              </div> */}

              <div className="selected-items-scrollable">
                {selectedItems.length === 0 ? (
                  <Spin size="large" />
                ) : (
                  selectedItems.map((item) => (
                    <li key={item.skuId}>
                      <p
                        style={{
                          color: '#001952',
                          font: '10px',
                          margin: 0
                        }}>
                        {item.skuDesc}{' '}
                        <CloseOutlined
                          style={{ float: 'right', marginRight: 16 }}
                          onClick={() => handleRemove(item)}
                        />
                      </p>
                      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                    </li>
                  ))
                )}
              </div>

            </div>
          </div>
      </Modal>

      <div className='channel-actions'>
      {userType !== 'MERCHANT' && (
            <div className='channel-actions-icon' onClick={openChannelEditModal}>
              <Tooltip placement="top" title="Edit">
                <EditOutlined />
              </Tooltip>
            </div>
        )}
        {userType !== 'MERCHANT' && (
            <div className='channel-actions-icon' onClick={() => openChannelDeleteModal(channelData.channelId)}>
              <Tooltip placement="top" title="Delete">
                <DeleteOutlined />
              </Tooltip>
            </div>
        )}
        {userType === 'MERCHANT' && (
            // <div className='channel-actions-icon' onClick={openManageSkuModal}>
            <div className='channel-actions-icon'>
              <Tooltip placement="top" title="Manage SKUs">
                {/* <ShopOutlined /> */}
                <Button className='assign-sku-button' onClick={handleAssignSkusClick}>Assign Sku's</Button>
              </Tooltip>
            </div>
        )} 
      </div>
    </>
  );
};

export default ChannelActions;
