import React, { useEffect, useRef, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import { RoutesList, PublicRoutes } from './constants/routes';

const AppRouter = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const channelId = useSelector((state) => state.channelData.channelId);

    // Using useRef to store previous channelId
    const prevChannelIdRef = useRef(null);

    useEffect(() => {
        if (channelId !== prevChannelIdRef.current) {
            if (channelId !== null) {
                // Only navigate if the path doesn't already contain the channelId
                const regex = new RegExp(`/channels/${channelId}/`);
                if (!regex.test(location.pathname)) {
                    const newPath = location.pathname.includes('/sku_data')
                        ? `/channels/${channelId}/sku_data`
                        : location.pathname.replace(/\/channels\/\d+\//, `/channels/${channelId}/`);

                    navigate(newPath, { replace: true });
                }
                prevChannelIdRef.current = channelId;
            } else {
                navigate('/login', { replace: true });
            }

        }
    }, [channelId, location.pathname, navigate]);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route path="/" element={<Navigate to="/login" replace />} />
                <Route element={<Layout hideHeaderPaths={PublicRoutes.map((route) => route.path)} />}>
                    {RoutesList.map((route, index) => (
                        <Route key={index} path={route.path} element={route.component} exact={route.exact} />
                    ))}
                </Route>
                {/* Conditional route rendering based on channelId */}
                {channelId && (
                    <Route path="*" element={<Navigate to={`/channels/${channelId}/sku_data`} replace />} />
                )}
                <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
        </Suspense>
    );
};

export default AppRouter;

