import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const getCompConfigData = async (channelId, configid, configdesc, configlevel) => {
    try {
        const query = QueryCreators.createGetScraperConfigQuery(channelId, configid, configdesc, configlevel);
        const response = await GraphQL.request(query);
        return response.getScraperConfig;
    } catch (err) {
        console.log(err);
    }
};

export const getCompConfigAtSkuData = async (channelId, configid , brandId , brandDesc) => {
    try {
        const query = QueryCreators.createGetScraperAtSkuConfigQuery(channelId, configid, brandId, brandDesc);
        const response = await GraphQL.request(query);
        return response.getScraperConfigAtSku;
    } catch (err) {
        console.log(err);
    }
};

export const updatelevelConfigData = async (configid, configdesc, configlevel, level, activeScraper, activeComp, applyAll , changeLog) => {
    try {
        const query = QueryCreators.createUpdateLevelConfigQuery(configid, configdesc, configlevel, level, activeScraper, activeComp, applyAll , changeLog);
        const response = await GraphQL.request(query);
        return response.updateScraperConfig;
    } catch (err) {
        console.log(err);
    }
};

export const updatelevelConfigAtSkuData = async (configid, configdesc, activeScraper, activeComp, changeLog) => {
    try {
        const query = QueryCreators.createUpdateLevelConfigAtSkuQuery(configid, configdesc, activeScraper, activeComp, changeLog);
        const response = await GraphQL.request(query);
        return response.updateScraperConfigAtSku;
    } catch (err) {
        console.log(err);
    }
};

export const getPricelevelConfigData = async (channelId, configid, configdesc, configlevel) => {
    try {
        const query = QueryCreators.createGetPriceLevelConfigQuery(channelId, configid, configdesc, configlevel);
        const response = await GraphQL.request(query);
        return response.getPriceConfiguration;
    } catch (err) {
        console.log(err);
    }
};

export const updatePricelevelConfigData = async (channelId, configid, configdesc, configlevel, priceData, applyAll, hasNoSignal , changeLog) => {
    try {
        const query = QueryCreators.createUpdatePriceLevelConfigQuery(channelId, configid, configdesc, configlevel, priceData, applyAll, hasNoSignal , changeLog);
        const response = await GraphQL.request(query);
        return response.updatePriceConfiguration;
    } catch (err) {
        console.log(err);
    }
};

export const getPricelevelSKUConfigData = async (configid) => {
    try {
        const query = QueryCreators.createGetPriceLevelSKUConfigQuery(configid);
        const response = await GraphQL.request(query);
        return response.getPriceConfigurationAtSku;
    } catch (err) {
        console.log(err);
    }
};


export const updatePricelevelSKUConfigData = async (channelId, configid, configdesc, priceData, hasNoSignal , changeLog) => {
    try {
        const query = QueryCreators.createUpdatePriceLevelSKUConfigQuery(channelId, configid, configdesc, priceData, hasNoSignal , changeLog);
        const response = await GraphQL.request(query);
        return response.updatePriceConfigurationAtSku;
    } catch (err) {
        console.log(err);
    }
};

export const getTempGuardrailData = async (channelId, configid, configdesc, configlevel) => {
    try {
        const query = QueryCreators.createGetTempGuardrailQuery(channelId, configid, configdesc, configlevel);
        const response = await GraphQL.request(query);
        return response.getGuardrailConfiguration;
    } catch (err) {
        console.log(err);
    }
};


export const updateTempGuardrailData = async (channelId, configid, configdesc, configlevel, guardData, applyAll , changeLog) => {
    try {
        const query = QueryCreators.createUpdateTempGuardrailQuery(channelId, configid, configdesc, configlevel, guardData, applyAll , changeLog);
        const response = await GraphQL.request(query);
        return response.updateGuardrailConfiguration;
    } catch (err) {
        console.log(err);
    }
};

export const getSkuSubscriptionData = async (channelId, skuId) => {
    try {
        const query = QueryCreators.createGetSKUSubscriptionDetailsQuery(channelId, skuId);
        const response = await GraphQL.request(query);
        return response.getSkuLevelSubscriptions;
    } catch (err) {
        console.log(err);
    }
};

export const getUserSubscriptionData = async (channelId, userId, searchText, index, sortType) => {
    try {
        const query = QueryCreators.createGetUserSubscriptionDetailsQuery(channelId, userId, searchText, index, sortType);
        const response = await GraphQL.request(query);
        return response.getUserLevelSubscriptions;
    } catch (err) {
        console.log(err);
    }
};


export const updateSubAlertData = async (channelId, skuId, subscriptionData, changeLog) => {
    try {
        const query = QueryCreators.createUpdateSubscriptionAlertQuery(channelId, skuId, subscriptionData, changeLog);
        const response = await GraphQL.request(query);
        return response.updateSubscription;
    } catch (err) {
        console.log(err);
    }
};

export const unSubAlerts = async (skuId) => {
    try {
        const query = QueryCreators.deleteSubscriptionAlertQuery(skuId);
        const response = await GraphQL.request(query);
        return response.deleteSubscription;
    } catch (err) {
        console.log(err);
    }
};

export const getBulkSkuPriceConfigData = async (skuIds) => {
    try {
        const query = QueryCreators.createGetBulkSkuPriceConfigQuery(skuIds);
        const response = await GraphQL.request(query);
        return response.getPriceConfigurationForSkus;
    } catch (err) {
        console.log(err);
    }
};

export const updateBulkSkuData = async (configurationValues) => {
    try {
        const query = QueryCreators.createUpdateBulkSku(configurationValues);
        const response = await GraphQL.request(query);
        return response.bulkUpdateSku;
    } catch (err) {
        console.log(err);
    }
};

export const deleteGuardrailData  = async (channelId, configId , configLevel , configdesc) => {
    try{
        const query = QueryCreators.deleteGuardrailConfiguration(channelId, configId , configLevel , configdesc );
        const response = await GraphQL.request(query);
        return response.deleteGuardrailConfiguration;
    }catch(err){
        console.log(err);
    }
}

export const getMatchIssuesByUser = async (channelId, userId, searchText, index, sortType) => {
    try {
        const query = QueryCreators.createGetMatchIssuesByUserQuery(channelId, userId, searchText, index, sortType);
        const response = await GraphQL.request(query);
        return response.getUnmatchTicketsByUser;
    } catch (err) {
        console.log(err);
    }
};