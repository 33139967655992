import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const getlevelLogsCount = async (channelId, configid, configlevel) => {
    try {
        const query = QueryCreators.createGetLevelLogsCountQuery(channelId, configid, configlevel);
        const response = await GraphQL.request(query);
        return response.getLevelLogsCount;
    } catch (err) {
        console.log(err);
    }
};

export const getSKUScraperLogsCount = async (configid) => {
    try {
        const query = QueryCreators.createGetSKUScraperLogsCountQuery(configid);
        const response = await GraphQL.request(query);
        return response.getSkuScraperCount;
    } catch (err) {
        console.log(err);
    }
};

export const getChildSKUCount = async (channelId, configid) => {
    try {
        const query = QueryCreators.createGetChildSKUCountQuery(channelId, configid);
        const response = await GraphQL.request(query);
        return response.getChildSkuCount;
    } catch (err) {
        console.log(err);
    }
};

export const getlevelLogsData = async (channelId, configid, configlevel,index, size) => {
    try {
        const query = QueryCreators.createGetLevelLogsQuery(channelId, configid, configlevel,index, size);
        const response = await GraphQL.request(query);
        return response.getLogsByLevel;
    } catch (err) {
        console.log(err);
    }
};

export const getEngineLogsData = async (channelId, configid, configlevel,index, size) => {
    try {
        const query = QueryCreators.createGetEngineLogsQuery(channelId, configid, configlevel,index, size);
        const response = await GraphQL.request(query);
        return response.getLogsByReprice;
    } catch (err) {
        console.log(err);
    }
};

export const getUserLogsCount = async (channelId, userid) => {
    try {
        const query = QueryCreators.createGetUserLogsCountQuery(channelId, userid);
        const response = await GraphQL.request(query);
        return response.getLogsByUser;
    } catch (err) {
        console.log(err);
    }
};

export const getUserLogs = async (channelId, userId, eventType, configType, index, size, searchText) => {
    try {
        const query = QueryCreators.createGetUserLogsQuery(channelId, userId, size, index, eventType, configType, searchText);
        const response = await GraphQL.request(query);
        return response.getLogsByUser;
    } catch (err) {
        console.log(err);
    }
};

export const getLogFiltersByUser = async (channelId, userId) => {
    try {
        const query = QueryCreators.createGetLogFiltersQuery(channelId, userId);
        const response = await GraphQL.request(query);
        return response.getLogFiltersByUser;
    } catch (err) {
        console.log(err);
    }
};