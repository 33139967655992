import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Badge, Tabs, Select } from "antd";
import "./PetcoData.scss";

import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import BrandsTable from "../../components/Tables/Brands.table";
import SKUTable from "../../components/Tables/SKU.table";
import ClassTable from "../../components/Tables/Class.table";
import SubclassTable from "../../components/Tables/Subclass.table";

import { Notification } from "../../utilities";
import { swapKeyValue } from "../../utilities/helper";
import {
  getAllAssignedCountData,
  getAllCountData,
} from "../../graphql/queryExecuters";
import { AllCountActions } from "../../redux/slices/allCount.slice";
import { userTypes } from "../../constants/common";
import { count_list_error } from "../../constants/displayStrings";

const PetcoData = (props) => {
  const location = useLocation();
  //const { state } = location;
  const navigationType = useNavigationType();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allCountList } = useSelector((state) => state.allCount);
  const { userType, id } = useSelector((state) => state.auth);
  const channelId = useSelector((state) => state.channelData.channelId);

  const [tabindex, setTabindex] = useState(props.tabindex);

  const [selectedChannelId, setSelectedChannelId] = useState(null); // Initially set to null

  const pathsToIndices = channelId ? {
    [`/channels/${channelId}/sku_data/class`]: 1,
    [`/channels/${channelId}/sku_data/subclass`]: 2,
    [`/channels/${channelId}/sku_data/brand`]: 3,
    [`/channels/${channelId}/sku_data/sku`]: 4,
} : {};

  const [newProps, setNewProps] = useState({
    level: "all",
    count: allCountList,
    merchantId: "",
    channelId: selectedChannelId, // Pass the selected channel id to props
  });

  const indicesToPaths = swapKeyValue(pathsToIndices);

  useEffect(() => {
    const newTabIndex = pathsToIndices[location.pathname] || "1";
    if (tabindex !== newTabIndex) {
      onTabChange(String(newTabIndex));
    }
  }, [location]); //eslint-disable-line

  useEffect(() => {
    if (channelId) {
      getAllCountTrigger(channelId);
    }
  }, [tabindex, userType, channelId]); //eslint-disable-line
  useEffect(() => {
    const newTabIndex = String(pathsToIndices[location.pathname] || "1");
    if (newTabIndex !== tabindex) {
      onTabChange(tabindex);
    }

    if (navigationType === "POP" && location?.search === "?") {
      navigate(location?.pathname);
    }

    if (location?.search === "?") {
      navigate(location?.pathname);
    }
  }, [tabindex]); //eslint-disable-line

  useEffect(() => {
    // Update newProps when channelDetails become available
    if (channelId) {
      setSelectedChannelId(channelId);
    }
  }, [channelId]);

  const getAllCountTrigger = async (channelId) => {
    try {
      let allCountData = null;
      if (userType) {
        if (userType === userTypes.MERCHANT) {
          allCountData = await getAllAssignedCountData(channelId, id);
        } else {
          allCountData = await getAllCountData(channelId);
        }
        if (allCountData) {
          dispatch(
            AllCountActions.setAllCountData({ allCountData: allCountData })
          );
        }
      }
    } catch (err) {
      console.log(err);
      Notification("error", count_list_error);
    }
  };

  const onTabChange = (key) => {
    setTabindex(key);
    const pathToRoute = indicesToPaths[key] || (channelId ? `/channels/${channelId}/sku_data/sku` : `/channels/${channelId}/sku_data`);    ;
    if (location.pathname !== pathToRoute) {
      navigate({ pathname: pathToRoute, search: location.search });
    }
  };

  const handleChannelSelect = (channelId) => {
    setSelectedChannelId(channelId);
    getAllCountTrigger(channelId);
  };

  useEffect(() => {
    setNewProps((prevProps) => ({
      ...prevProps,
      channelId: selectedChannelId,
    }));
  }, [selectedChannelId]);

  const tabs = [
    {
      label: (
        <span>
          <span style={{ color: tabindex === "1" ? "#001952" : "#96999F" }}>
            Class{" "}
          </span>
          <Badge
            showZero
            className="class-count-badge"
            count={allCountList?.classCount}
            overflowCount={99999}
            style={{
              marginLeft: 8,
              backgroundColor: tabindex === "1" ? "#001952" : "#EBF1FF",
              color: tabindex === "1" ? "#FFFFFF" : "#001952",
            }}
          />
        </span>
      ),
      key: "1",
      id: "class-tab",
      children: <ClassTable {...newProps} />,
    },
    {
      label: (
        <span>
          <span style={{ color: tabindex === "2" ? "#001952" : "#96999F" }}>
            Subclass{" "}
          </span>
          <Badge
            showZero
            className="class-count-badge"
            count={allCountList?.subclassCount}
            overflowCount={99999}
            style={{
              marginLeft: 8,
              backgroundColor: tabindex === "2" ? "#001952" : "#EBF1FF",
              color: tabindex === "2" ? "#FFFFFF" : "#001952",
            }}
          />
        </span>
      ),
      key: "2",
      id: "subclass-tab",
      children: <SubclassTable {...newProps} />,
    },
    {
      label: (
        <span>
          <span style={{ color: tabindex === "3" ? "#001952" : "#96999F" }}>
            Brand{" "}
          </span>
          <Badge
            showZero
            className="brands-count-badge"
            count={allCountList?.brandCount}
            overflowCount={99999}
            style={{
              marginLeft: 8,
              backgroundColor: tabindex === "3" ? "#001952" : "#EBF1FF",
              color: tabindex === "3" ? "#FFFFFF" : "#001952",
            }}
          />
        </span>
      ),
      key: "3",
      id: "brand-tab",
      children: <BrandsTable {...newProps} />,
    },
    {
      label: (
        <span>
          <span style={{ color: tabindex === "4" ? "#001952" : "#96999F" }}>
            SKU{" "}
          </span>
          <Badge
            showZero
            className="sku-count-badge"
            count={allCountList?.skuCount}
            overflowCount={500000}
            style={{
              marginLeft: 8,
              backgroundColor: tabindex === "4" ? "#001952" : "#EBF1FF",
              color: tabindex === "4" ? "#FFFFFF" : "#001952",
            }}
          />
        </span>
      ),
      key: "4",
      id: "sku-tab",
      children: <SKUTable {...newProps} />,
    },
  ];

  return (
    <>
        <ContentWrapper>
          <div className="tabs-container"> 
            <Tabs
              animated
              activeKey={tabindex}
              defaultActiveKey={tabindex}
              items={tabs}
              onChange={onTabChange}
            />
          </div>
         
        </ContentWrapper>
    </>
  );
};

export default PetcoData;
