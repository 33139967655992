import { Badge, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import ChannelManagement from '../ChannelManagement/ChannelManagement';
import TeamsAndMerchants from '../TeamsAndMerchants/TeamsAndMerchants';
import ApprovalPolicies from '../ApprovalPolicies/ApprovalPolicies';

const Settings = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { userType } = useSelector((state) => state.auth);

    //declaring index change state on tab change for changinf badge color change
    const [tabindex, setTabindex] = useState(props.tabindex);

   // Define a single object for path-tab key mapping
   const pathTabMap = {
    '1': '/settings/channel_management',
    '2': '/settings/teams_and_merchants',
    '3': '/settings/approval_policies',
    };   

    const onTabChange = (key) => {
        setTabindex(key);
        navigate(pathTabMap[key]);
    };

    useEffect(() => {
        // Update the tab index if the path changes
        const currentTabKey = pathTabMap[location.pathname];
        if (currentTabKey && currentTabKey !== tabindex) {
            setTabindex(currentTabKey);
        }
    }, [location.pathname]);
    const tabs = [
      {
        label: (
          <span>
            <span style={{ color: tabindex === '1' ? '#001952' : '#96999F' }}>Channel Management{' '}</span>
            <Badge
              showZero
              //className="pricingTeam-count-badge"
              //count={allUserCountList?.teamCount || 0}
              overflowCount={99999}
              style={{
                backgroundColor: tabindex === '1' ? '#001952' : '#EBF1FF',
                color: tabindex === '1' ? '#FFFFFF' : '#001952'
              }}
            />
          </span>
        ),
        key: '1',
        children: <ChannelManagement />
      },
      ...(userType !== 'MERCHANT' ? [{
        label: (
          <span>
            <span style={{ color: tabindex === '2' ? '#001952' : '#96999F' }}>Teams & Merchants{' '}</span>
            <Badge
              showZero
               //className="merchant-count-badge"
               // count={allUserCountList?.merchantCount || 0}
               overflowCount={99999}
              style={{
                backgroundColor: tabindex === '2' ? '#001952' : '#EBF1FF',
                color: tabindex === '2' ? '#FFFFFF' : '#001952'
              }}
            />
          </span>
        ),
        key: '2',
        children: <TeamsAndMerchants />
      }] : []),
      ...(userType !== 'MERCHANT' ? [{
        label: (
          <span>
            <span style={{ color: tabindex === '3' ? '#001952' : '#96999F' }}>Approval Policies{' '}</span>
            <Badge
              showZero
              //className="merchant-count-badge"
              // count={allUserCountList?.merchantCount || 0}
              overflowCount={99999}
              style={{
                backgroundColor: tabindex === '3' ? '#001952' : '#EBF1FF',
                color: tabindex === '3' ? '#FFFFFF' : '#001952'
              }}
            />
          </span>
        ),
        key: '3',
        children: <ApprovalPolicies />
      }] : [])
    ];
  return (
    <>
        <ContentWrapper>
            <div>
            <Tabs 
                animated items={tabs} 
                defaultActiveKey={tabindex} 
                onChange={onTabChange}
            />
            </div>
        </ContentWrapper>
        </>
  )
}

export default Settings