import { Tabs, Badge } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import ApprovalTable from '../../components/Tables/Approval.table';
import AlertsTable from '../../components/Tables/Alerts.table';
import ScheduledAlertsTable from '../../components/Tables/ScheduledAlerts.table';
import * as QueryExecuters from '../../graphql/queryExecuters';
import { Notification, swapKeyValue } from '../../utilities';
import { getAllUsersBaseData } from '../../redux/actionCreators/auth.actions';
import './Alerts.scss';
import * as DisplayStrings from '../../constants/displayStrings';
import MatchIssuesTable from '../../components/Tables/MatchIssues.table';
import UnPricedSKUTable from '../../components/Tables/UnpricedSKU.table';

const Alerts = (props) => {
    const level = 'all';

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { state } = useLocation();

    const [tabindex, setTabindex] = useState(props.tabindex);

    const [alertCount, setAlertCount] = useState({
        subscription: 0,
        priceApproval: 0,
        matchIssues: 0,
        triggerAlert: 0,
        unpricedSkus: 0
    });

    const [userData, setUserData] = useState([{ value: 'all', label: 'All Users' }]);

    const { id } = useSelector((state) => state.auth);
    const channelId = useSelector((state) => state.channelData.channelId);
    const { allUsersList } = useSelector(state => state.users);
    const { alertsCount } = useSelector((state) => state.alertsCount);

    const pathsToIndices = channelId ? {
        [`/channels/${channelId}/alerts`]: '1',
        [`/channels/${channelId}/alerts/approvals`] : '1',
        [`/channels/${channelId}/alerts/subscriptions`] : '2',
        [`/channels/${channelId}/alerts/triggered_alerts`] : '3',
        [`/channels/${channelId}/alerts/match_issues`] : '4',
        [`/channels/${channelId}/alerts/no_price_sku`] : '5'
    } : {}

    const indicesToPaths = swapKeyValue(pathsToIndices);

    useEffect(() => {
        getCountDetailsTrigger();
    }, [id, state?.reload, alertsCount, channelId]); //eslint-disable-line

    useEffect(() => {
        getAllUserDataTrigger();
    }, [allUsersList]); //eslint-disable-line


    useEffect(() => {
        setTabindex(pathsToIndices[location.pathname] || 1);
    }, [location.pathname])

    const onTabChange = (key) => {
        setTabindex(key);
        const pathToRoute = indicesToPaths[key] || "/"
        if (location.pathname !== pathToRoute) {
          navigate({pathname: pathToRoute , search : ""})
        }
    };

    const getCountDetailsTrigger = async (id) => {
        try {
            const alertCount = await QueryExecuters.getUserAlertsCount(channelId);

            if (alertCount) {
                setAlertCount({
                    subscription: alertCount?.subscriptionCount ? alertCount.subscriptionCount : 0,
                    priceApproval: alertCount?.approvalCount ? alertCount.approvalCount : 0,
                    triggerAlert: alertCount?.alertCount ? alertCount.alertCount : 0,
                    matchIssues: alertCount?.matchIssuesCount ? alertCount.matchIssuesCount : 0,
                    unpricedSkus: alertCount?.unpricedSkusCount ? alertCount.unpricedSkusCount : 0
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getAllUserDataTrigger = async () => {
        try {
            if (allUsersList.length > 0) {
                setUserData([
                    { value: 'all', label: 'All Users' },
                    ...allUsersList.map((user) => ({ value: user.id, label: user.name }))
                ]);
            } else {
                dispatch(getAllUsersBaseData());
            }
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting user Data. Please try again later.'
            );
        }
    };

    const handleChannelSelect = (channelId) => {
        //getCountDetailsTrigger();
      };

    const propsData = useMemo(() => ({
        level: level,
        userData: userData,
        channelId: channelId
    }), [userData, channelId])

    const tabs = [
        {
            label: (
                <label>
                    Accept/Reject{' '}
                    <Badge
                        showZero
                        className="sbu-count-badge"
                        count={alertCount.priceApproval}
                        style={{
                            backgroundColor: tabindex === '1' ? '#001952' : '#EBF1FF',
                            color: tabindex === '1' ? '#FFFFFF' : '#001952'
                        }}
                        overflowCount={9999999}
                    />
                </label>
            ),
            key: '1',
            children: <ApprovalTable {...propsData} totalRecords={alertCount.priceApproval}/>
        },
        {
            label: (
                <label>
                    Subscription{' '}
                    <Badge
                        showZero
                        className="sbu-count-badge"
                        count={alertCount.subscription}
                        style={{
                            backgroundColor: tabindex === '2' ? '#001952' : '#EBF1FF',
                            color: tabindex === '2' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </label>
            ),
            key: '2',
            children: <ScheduledAlertsTable {...propsData} totalRecords={alertCount.subscription}/>
        },
        {
            label: (
                <label>
                    Triggered Alert{' '}
                    <Badge
                        showZero
                        className="sbu-count-badge"
                        count={alertCount.triggerAlert}
                        style={{
                            backgroundColor: tabindex === '3' ? '#001952' : '#EBF1FF',
                            color: tabindex === '3' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </label>
            ),
            key: '3',
            children: <AlertsTable {...propsData}  totalRecords={alertCount.triggerAlert}/>
        },
        {
            label: (
                <label>
                    {`${DisplayStrings.match_issues} `}
                    <Badge
                        showZero
                        className="sbu-count-badge"
                        count={alertCount.matchIssues}
                        style={{
                            backgroundColor: tabindex === '4' ? '#001952' : '#EBF1FF',
                            color: tabindex === '4' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </label>
            ),
            key: '4',
            children: <MatchIssuesTable {...propsData}  totalRecords={alertCount.matchIssues} />,
        },
        {
            label: (
                <label>
                    {`${DisplayStrings.no_price_skus} `}
                    <Badge
                        showZero
                        className="sbu-count-badge"
                        count={alertCount.unpricedSkus}
                        style={{
                            backgroundColor: tabindex === '4' ? '#001952' : '#EBF1FF',
                            color: tabindex === '4' ? '#FFFFFF' : '#001952'
                        }}
                        overflowCount={9999999}
                    />
                </label>
            ),
            key: '5',
            children: <UnPricedSKUTable totalRecords={alertCount.unpricedSkus}/>
        }
    ];

    return (
        <ContentWrapper>
            <div>
                <div className="name-container">Alerts/Tickets</div>
            </div>

            <Tabs items={tabs} activeKey={tabindex} onChange={onTabChange} destroyInactiveTabPane/>
        </ContentWrapper>
    );
};

export default Alerts;
