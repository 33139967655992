import React, { useState } from 'react';

import { Button, Col, Modal, Row, Input, Spin } from 'antd';
import { Notification, validateURL } from '../../utilities';
import './MatchIssuesModal.scss';
import { createUnmatchTicket } from '../../graphql/queryExecuters';
import { competitor_product_link, description, match_issues, petco_product_link } from '../../constants/displayStrings';
import { useSelector } from "react-redux";

const MatchIssuesModal = ({ skuId }) => {
    const { TextArea } = Input;

    const nullState = {
        productUrl: '',
        competitorUrl: '',
        description: ''
    }

    const channelId = useSelector((state) => state.channelData.channelId);

    const [matchIssueModal, setMatchIssueModal] = useState(false);

    const [submitClicked, setSubmitClicked] = useState(false);

    //Form Data Handler
    const [inputData, setInputData] = useState(nullState);

    //Helper for loading
    const [ loading, setLoading ] = useState(false);

    const handleMatchIssueCancel = () => {
        setSubmitClicked(false);
        setMatchIssueModal(false);
    };

    const handleMatchIssueSubmit = async () => {
        try {
            setSubmitClicked(true);
            if(!inputData?.productUrl || !inputData?.competitorUrl){
                Notification('error', 'Please enter the required fields.');
                return;
            }
    
            if (!validateURL(inputData?.productUrl)){
                Notification('error', 'Please enter a proper product link URL');
                return;
            }
    
            if (!validateURL(inputData?.competitorUrl)){
                Notification('error', 'Please enter a proper competitor link URL');
                return;
            }

            setLoading(true);
            const ticketDetails = {
                competitorUrl: inputData.competitorUrl,
                petcoUrl: inputData.productUrl,
                description: inputData.description
            }
    
            const response = await createUnmatchTicket(channelId, skuId, ticketDetails);
            setLoading(false);

            if(response.message === 'Ticket Created Successfully') {
                Notification('success', 'Ticket Created Successfully');
            } else {
                Notification('error', 'Something Went Wrong While Creating Ticket. Please Try Again Later.');
            }
            setInputData(nullState)
            setMatchIssueModal(false);
        } catch (err) {
            setLoading(false);
            console.log('Error - ',  err);
            Notification('error', 'Something Went Wrong While Creating Ticket, Pease Try Again Later.');
        }
    };

    const handleMatchIssueModal = () => {
        setMatchIssueModal(true);
    };

    //Fields value change handler
    const onChangeValueHandler = (field, value) => {
        try {
            setInputData(prevState => {
                const state = {...prevState}
                state[field] = value;
                return state;
            })
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <>
            <Button
                id='match-issues'
                className="sbu-details-configureSBU-match-issues-btn"
                onClick={handleMatchIssueModal}>
                {match_issues}
            </Button>
            <Modal
                open={matchIssueModal}
                title="Create Ticket for Match Issues"
                onCancel={handleMatchIssueCancel}
                width={750}
                footer={[
                    <Button id='matchIssues-close' className="closeBtn" key="close" onClick={handleMatchIssueCancel}>
                        Close
                    </Button>,
                    <Button id='create-ticket' className="matchIssueBtn" key="matchIssue" type="primary" onClick={handleMatchIssueSubmit}>
                        Create Ticket
                    </Button>
                ]}>
                <Spin spinning={loading}>
                    <Row>
                        <Col span={12}>
                            <label>
                                {petco_product_link}
                            </label>
                            <Input
                                id='petco-product-link'
                                value={inputData.productUrl}
                                onChange={e => { onChangeValueHandler('productUrl', e.target.value)}}
                                style={{
                                    height: 46,
                                    width: "95%",
                                    borderRadius: "1px",
                                    borderColor: submitClicked && inputData?.productUrl === '' ? 'red' : '#d9d9d9'
                                }}
    
                                prefix="www."></Input>
                        </Col>
                        <Col span={12}>
                            <label>
                                {competitor_product_link}
                            </label>
                            <Input
                                id='comp-product-link'
                                value={inputData.competitorUrl}
                                onChange={e => { onChangeValueHandler('competitorUrl', e.target.value)}}
                                style={{
                                    height: 46,
                                    width: "100%",
                                    borderRadius: "1px",
                                    borderColor: submitClicked && inputData?.competitorUrl === '' ? 'red' : '#d9d9d9'
                                }}
                                prefix="www."></Input>
                        </Col>
                    </Row>
                    <br />
                    <label>{description}</label>
                    <div className='textarea-style'>
                        <TextArea style={{
                            borderRadius: "1px",
                            width: '100%',
                            minHeight: 185,
                        }} id='match-issues-desc' rows={8.5} placeholder="Enter Description(Optional)" value={inputData.description} onChange={e => { onChangeValueHandler('description', e.target.value)}}/>
                    </div>
                    <br />
                    <br />
                </Spin>
            </Modal>
        </>
    );
};

export default MatchIssuesModal;
