import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { ChannelDetailsActions } from "../../redux/slices/channelDetails.slice";
import { getAllChannelData } from '../../graphql/queryExecuters';
import { Notification } from '../../utilities';
import "./ChannelDropdown.scss";

const ChannelDropdown = () => {
  const dispatch = useDispatch();
  const [channelDetails, setChannelDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getChannelsListInitiator();
  }, []); //eslint-disable-line

  const handleChannelSelect = (channelId) => {
    dispatch(ChannelDetailsActions.updateChannelId(channelId));
  };

  const getChannelsListInitiator = async () => {
    setLoading(true);
    try {
      const response = await getAllChannelData(10, 0, '');
      if (response && Array.isArray(response.data)) {
        dispatch(
          ChannelDetailsActions.setChannelDetails(response.data)
        )
        setChannelDetails(response.data);
      } else {
        Notification('error', 'Failed to fetch channel data');
      }
    } catch (err) {
      console.error("Error fetching channels:", err);
      Notification('error', 'An error occurred while fetching channel data');
    }
    setLoading(false);
  };

   // Find the default channel name based on the channelId from Redux
   const defaultChannel = channelDetails.find(channel => channel.defaultStatus === "true");
  // Sort the channels by channel_id in ascending order
  const sortedChannels = channelDetails ? [...channelDetails].sort((a, b) => a.channelId - b.channelId) : [];

  return (
    <div className="channel-container">
      {loading ? (
        <div></div>
      ) : (
        <Select
          className="channel-dropdown"
          showSearch
          placeholder="Select a Channel"
          optionFilterProp="children"
          defaultValue={defaultChannel?.channelName.toUpperCase()}
          onSelect={handleChannelSelect}
        >
          {sortedChannels.map((channel) =>
            channel.channelName ? (
              <Select.Option
                key={channel.channelId}
                value={channel.channelId}
              >
                {channel.channelName
                  .split("_")
                  .map((word) => word.toUpperCase())
                  .join(" ")} [{channel.channelId}]
              </Select.Option>
            ) : null
          )}
        </Select>
      )}
    </div>
  );
};

export default ChannelDropdown;
