import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, Pagination, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { userConfigurationLogsColumns } from '../../constants/tableColumns';
import './Tables.scss';
import './UserLogs.table.scss';
import { Notification } from '../../utilities';
import { getLogFiltersByUser, getUserLogs } from '../../graphql/queryExecuters';
import { Levels } from '../../constants/common';
import {capitalizeFirstLetter, formatLogData} from '../../utilities/helper'

const UserLogsTable = (propsData) => {
    const [loading, setLoading] = useState(false);
    const [pageindex, setPageindex] = useState(1);
    const [rowCount, setRowCount] = useState(propsData.userLogsCount);
    const [tableData, setTableData] = useState([]);
    const [searchText , setSearchText] = useState('');
    const [userLogQueryParams, setUserLogQueryParams] = useState({
        userId: propsData.userId,
        size: 10,
        eventType: '',
        configType: ''
    });

    const [logFilters, setLogFilters] = useState([]);
    const channelId = useSelector((state) => state.channelData.channelId);

    //Getting User Log filters
    useEffect(() => {
        if(channelId){
            getUserLogFiltersHandler();
        }
    }, [channelId]) //eslint-disable-line

    useEffect(() => {
        if(channelId){
        getUserLogsDataTrigger();
    }
    }, [pageindex, userLogQueryParams, channelId]); //eslint-disable-line

    const getUserLogsDataTrigger = async () => {
        try {
            setLoading(true);
            let logsData = [];

            logsData = await getUserLogs(
                channelId,
                userLogQueryParams.userId,
                userLogQueryParams.eventType,
                userLogQueryParams.configType,
                pageindex - 1,
                userLogQueryParams.size,
                searchText
            );
            
            logsData.data.forEach(el => el.eventLevel = el.eventLevel.toUpperCase());
            
            formatLogData(logsData.data, propsData.level)
            setTableData(logsData.data)
            setRowCount(logsData.dataCount);

            setLoading(false);
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting User Logs. Please try again later.'
            );
            setLoading(false);
        }
    };

    const getUserLogFiltersHandler = async () => {
        try {
            let logFiltersData = await getLogFiltersByUser(channelId, userLogQueryParams.userId);
            logFiltersData = [
                {
                    key: 'ALL',
                    value: 'ALL',
                    label: 'Show All Change Types'
                },
                ...logFiltersData.map(filterOption => ({label: capitalizeFirstLetter(filterOption.replaceAll('_', ' ')), key: filterOption, value: filterOption}))
            ];
            setLogFilters(logFiltersData);
        } catch (err) {
            console.log('Error - Something went wrong while getting log filters')
        }
    }

    const handlePageChange = (event) => {
        setPageindex(event);
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    //Search only when user hits ENTER
    const onPressEnterKeyHandler = (event) => {
        try {
            if (event.key === 'Enter') {
                getUserLogsDataTrigger();
            }
        } catch (err) {
            console.log(err);
        }
    }
    
    const onUserDataChange = async (field, value) => {
        try {
            setUserLogQueryParams((prevState) => {
                const state = { ...prevState };
                if (field === 'Heirarchy') {
                    if (value === '' || value === 'ALL') {
                        state.eventType = '';
                    } else {
                        state.eventType = value;
                    }
                } else if (field === 'ChangeType') {
                    if (value === '' || value === 'ALL') {
                        state.configType = '';
                    } else {
                        state.configType = value;
                    }
                } else {
                    state[field] = value;
                }
                return state;
            });
        } catch (err) {
            Notification('error', 'Something went wrong while getting data');
        }
    };
    
    return (
        <div className="userLogs-data-table-wrapper-container">
            <div className="controls">
                <div className="others">
                    <div className="control search">
                        <Input
                            // onChange={(e) => {
                            //     onDataChange('searchText', e.target.value);
                            // }}
                            onKeyDown={onPressEnterKeyHandler}
                            onChange={handleSearchChange}
                            style={{
                                height: '45px'
                            }}
                            size="large"
                            id="userlogs-search"
                            prefix={<SearchOutlined />}
                            placeholder="Search Log Details"
                            suffix={<span className='press-enter-style'>Press enter to search</span>}
                            disabled={loading}
                        />
                    </div>
                    <div className="control">
                        <Select
                            // defaultValue={'all'}
                            style={{ width: '190px' }}
                            id='userLogs-hierarchy'
                            onChange={(e) => {
                                onUserDataChange('Heirarchy', e);
                                }}
                            size="large"
                            disabled={loading}
                            options={Levels}
                            placeholder="Show All Hierarchy"
                        />
                    </div>
                    <div className="control">
                        <Select
                            style={{ width: '270px' }}
                            id='userLogs-changetypes'
                            // onChange={(e) => {
                            //     onDataChange('stockType', e);
                            // }}
                            // defaultValue={'all'}
                            onChange={(e) => {
                                onUserDataChange('ChangeType', e);
                            }}
                            size="large"
                            disabled={loading}
                            options={logFilters}
                            placeholder="Show all Change Types"
                        />
                    </div>
                </div>
            </div>
            <div className="table-container">
                <Table
                    rowKey="id"
                    id="userLogs-table"
                    dataSource={tableData}
                    columns={userConfigurationLogsColumns}
                    loading={loading}
                    pagination={false}
                    size="middle"
                    className="table-striped-rows clickable-table"
                    scroll={{ x: 2000 }}
                />
                {rowCount > 10 ? <Pagination
                    className="data-pagination"
                    id='userLogs-filter'
                    defaultCurrent={pageindex}
                    total={rowCount}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    size="small"
            />: <></>}
            </div>
        </div>
    );
};

export default UserLogsTable;
