import { merchantAccessTypes } from "../../constants/common";
import { getCurrentUser, getAllUsers } from "../../graphql/queryExecuters";
import { getStorage } from "../../utilities";
import * as Actions from "../slices";

//Action creator for checking user's autorization
export const checkAuthorization = () => {
  return async (dispatch) => {
    const storage = getStorage();
    try {
      const token = storage.getItem("token");
      if (token) {
        const res = await getCurrentUser();
        if (res) {
          if (res.__typename === "UserDetails") {
            //const channelDetails = res.channelDetails ? JSON.parse(res.channelDetails) : [];
            //const defaultChannel = channelDetails.find(channel => channel.default);
            const state = {
              id: res.id,
              name: res.name,
              email: res.email,
              userType: res.userType,
              accessRole: res.accessRole,
              createdBy: res.createdBy,
              editAccess: res.editAccess
                ? res.editAccess
                : merchantAccessTypes[0].value,
              accessToken: storage.getItem("token"),
              // channelDetails: channelDetails,
              // channelId: defaultChannel ? defaultChannel.channel_id : null, // Set channelId from the default channel
            };
            dispatch(Actions.AuthActions.setAuth({ ...state }));
          } else {
            storage.clear();
            window.location.href = "/login";
          }
        }
      }
    } catch (err) {
      console.log("Error - Check Authorization Action Error - ", err);
      storage.clear();
      window.location.href = "/login";
    }
  };
};

//Action creator for get all users base details
export const getAllUsersBaseData = () => {
  return async (dispatch) => {
    try {
      const usersData = await getAllUsers();
      if (usersData) {
        dispatch(Actions.UsersActions.setAllUsers({ allUsersList: usersData }));
      }
    } catch (err) {
      console.log("Error - Unable to get all users data - ", err);
    }
  };
};

//Action creator for resetting all redux slices
export const logout = (storage) => {
  return async (dispatch) => {
    try {
      //Resetting storage
      storage.clear();

      //Resetting all slices
      dispatch(Actions.ActiveIdActions.reset());
      dispatch(Actions.AlertsActions.reset());
      dispatch(Actions.AlertsCountActions.reset());
      dispatch(Actions.AllCountActions.reset());
      dispatch(Actions.ApprovalsActions.reset());
      dispatch(Actions.AuthActions.reset());
      dispatch(Actions.BrandsActions.reset());
      dispatch(Actions.ClassActions.reset());
      dispatch(Actions.CompetitorsActions.reset());
      dispatch(Actions.LogsActions.reset());
      dispatch(Actions.PetcoDataActions.reset());
      dispatch(Actions.ScrapersActions.reset());
      dispatch(Actions.SelectedSkuActions.reset());
      dispatch(Actions.SkuActions.reset());
      dispatch(Actions.SubclassActions.reset());
      dispatch(Actions.SubscriptionsActions.reset());
      dispatch(Actions.UsersActions.reset());
      dispatch(Actions.merchantsActions.reset());
      dispatch(Actions.petcoUsersActions.reset());
      dispatch(Actions.ChannelDetailsActions.reset());
    } catch (err) {
      console.log("Error - Unable to reset the slices - ", err);
    }
  };
};
