import { createSlice } from "@reduxjs/toolkit";

// Helper function to get channelId from localStorage
const getStoredChannelId = () => {
    const storedChannelId = localStorage.getItem('selectedChannelId');
    return storedChannelId ? parseInt(storedChannelId, 10) : null;
}

const initialState = {
    channelDetails: [], // Array to hold multiple channel objects
    channelId: getStoredChannelId() || null, // Object to hold the details of a selected channel
};

const channelDetailsSlice = createSlice({
  name: "channel",
  initialState,
  reducers: {
    //Setting Auth state
    setChannelDetails(state, action) {
        state.channelDetails = action.payload;
        const defaultChannel = action.payload?.find(channel => channel.defaultStatus);
        state.channelId = defaultChannel ? parseInt(defaultChannel.channelId) : null;
    },

    //Resetting state
    reset(state) {
      state = { ...initialState };
      return state;
    },

    // New reducer to update the channelId
    updateChannelId(state, action) {
        state.channelId = parseInt(action.payload);
        // Store the selected channelId in localStorage
        localStorage.setItem('selectedChannelId', parseInt(action.payload));
    },
  },
});

export const ChannelDetailsActions = channelDetailsSlice.actions;
export const ChannelDetailsReducer = channelDetailsSlice.reducer;
export default channelDetailsSlice;

