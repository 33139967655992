import GraphQL from "../../configs/graphQL.config";

import * as QueryCreators from "../queryCreators";

export const getAllClassData = async (
  channelId,
  index,
  size,
  search,
  sortType
) => {
  try {
    const query = QueryCreators.createGetAllClassDataQuery(
      channelId,
      index,
      size,
      search,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllClasses;
  } catch (err) {
    console.log(err);
  }
};

export const getClassDetails = async (classId, classDesc) => {
  try {
    const query = QueryCreators.createGetClassDetailsQuery(classId, classDesc);
    const response = await GraphQL.request(query);
    return response.getLevelDetails;
  } catch (err) {
    console.log(err);
  }
};

export const getAllAssignClassData = async (
  channelId,
  merchantId,
  index,
  size,
  search,
  sortType
) => {
  try {
    const query = QueryCreators.createGetAllAssignClassDataQuery(
      channelId,
      merchantId,
      index,
      size,
      search,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllAssignClasses;
  } catch (err) {
    console.log(err);
  }
};

export const getChannelById = async (channelId) => {
  try {
    const query = QueryCreators.createGetChannelByIdQuery(channelId);
    const response = await GraphQL.request(query);
    return response.getChannelDetailsById;
  } catch (err) {
    console.log(err);
  }
};

export const getAllMerchantAssignedClasses = async (channelId, merchantId, searchedtext) => {
  try {
    const query = QueryCreators.getAllMerchantAssignedClassesQuery(channelId, merchantId, searchedtext);
    const response = await GraphQL.request(query);
    return response.getMerchantClassesToAssignChannel;
  } catch (err) {
    console.log(err);
  }
};