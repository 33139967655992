import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const getApprovalPolicy = async () => {
    try {
        const query = QueryCreators.getApprovalPolicyQuery();
        const response = await GraphQL.request(query);
        return response.getApprovalPolicy;
    } catch (err) {
        console.log(err);
    }
};

export const updateApprovalPolicies = async (acceptedPriceIncrease, acceptedPriceDecrease, acceptedPriceNegativeMargin) => {
    try {
        const query = QueryCreators.updateApprovalPolicyQuery(acceptedPriceIncrease, acceptedPriceDecrease, acceptedPriceNegativeMargin);
        const response = await GraphQL.request(query);
        return response.updateOrCreateApprovalPolicy;
    } catch (err) {
        console.log(err);
    }
};