import { Table } from 'antd';

import { competitorsDetailedTableColumns } from '../../constants/tableColumns';
import './Tables.scss';

const CompetitorDetailsTable = ({ data, onClickRow }) => {
    return (
        <div className="table-container">
            <Table
                onRow={(row, index) => {
                    return {
                        onClick: () => {
                            onClickRow(row, index);
                        }
                    };
                }}
                rowKey="name"
                id="compDetail-table"
                dataSource={data}
                columns={competitorsDetailedTableColumns}
                className="table-striped-rows clickable-table"
                pagination={data?.length > 10 ? {position: ['bottomCenter']} : false}
                scroll={{ x: 2000 }}
                size="middle"
            />
        </div>
    );
};

export default CompetitorDetailsTable;
