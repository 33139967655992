import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Input, Pagination } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { Notification } from '../../utilities';
import { unPricedSkuTableColumns } from '../../constants/tableColumns';
import { getUnpricedSKUList } from '../../graphql/queryExecuters';

import "./Tables.scss"
import { userTypes } from '../../constants/common';

const UnPricedSKUTable = (props) => {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const { userType } = useSelector(state => state.auth);
    const channelId = useSelector((state) => state.channelData.channelId);

    const [loading, setLoading] = useState(false);
    const [sortType, setSortType] = useState('ASC');
    const [rowCount, setRowCount] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [pageindex, setPageindex] = useState(searchParams.get('index') ? Number(searchParams.get('index')) : 1);
    const [searchText, setSearchText] = useState(searchParams.get('search') ? searchParams.get('search') : '');

    useEffect(() => {
        getAllSkuDataTrigger();
    }, [sortType, props?.reload]); //eslint-disable-line

    useEffect(() => {
        checkPageIndexChange();
    }, [searchParams]); //eslint-disable-line

    const onRowClick = (row) => {
        if (userType !== userTypes.MERCHANT) {
            navigate(`/channels/${channelId}/sku_data/sku/${row.skuId}`, { state: { row } });
        }
    };

    //getting all sku Data
    const getAllSkuDataTrigger = async (pageNumber = null) => {
        try {
            setLoading(true);

            const page = pageNumber || pageindex

            const skuList = await getUnpricedSKUList(channelId, page - 1, 10, searchText, sortType);
            if (skuList) {
                setTableData(skuList.data);
                setRowCount(skuList.dataCount);
                if ((page - 1) * 10 > skuList.dataCount) {
                    setPageindex(1);
                    searchParams.set('index', 1);
                    setSearchParams(searchParams);
                }
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting sku Data. Please try again later.'
            );
            setLoading(false);
        }
    };

    const checkPageIndexChange = () => {
        const index = searchParams.get('index') || 1;
        if (index) {
            const indexToBeSwitch = Number(index);
            if (indexToBeSwitch !== pageindex) {
                setPageindex(indexToBeSwitch);
                getAllSkuDataTrigger(indexToBeSwitch);
            }
        }
    }

    const handlePageChange = (event) => {
        setPageindex(event);
        searchParams.set('index', event);
        setSearchParams(searchParams);
        getAllSkuDataTrigger(event);
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        let sort = sorter.order === 'ascend' ? 'ASC' : 'DESC';
        setSortType(sort);
    };

    //Search when user press ENTER
    const onPressEnterKeyHandler = (event) => {
        try {
            if (event.key === 'Enter') {
                getAllSkuDataTrigger();
                searchParams.set('search', searchText);
                setSearchParams(searchParams);
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="table-container">
            <div className="sku-filter-row">
                <Input
                    className="data-search responsive-Search-Bar"
                    id="unpriced-sku-search"
                    prefix={<SearchOutlined />}
                    onKeyDown={onPressEnterKeyHandler}
                    onChange={handleSearchChange}
                    value={searchText}
                    placeholder={searchText ? searchText : "Search for SKU"}
                    suffix={<span className='press-enter-style'>Press enter to search</span>}
                    disabled={loading}
                />
            </div>
            <Table
                rowKey="skuId"
                id="unPricedSku-table"
                dataSource={tableData}
                columns={unPricedSkuTableColumns}
                loading={loading}
                pagination={false}
                scroll={{ x: 1000 }}
                size="middle"
                onRow={(row) => {
                    return {
                        onClick: () => {
                            onRowClick(row);
                        }
                    };
                }}
                className={`table-striped-rows ${userType !== userTypes.MERCHANT ? 'clickable-table' : ''}`}
                onChange={handleTableChange}
            />
            {rowCount > 10 && !loading ? <Pagination
                className="data-pagination"
                current={pageindex}
                total={rowCount}
                onChange={handlePageChange}
                showSizeChanger={false}
                size="small"
            /> : <></>}
        </div>
    );
};

export default UnPricedSKUTable;
