//Containers
import PetcoUserDetails from "../containers/PetcoUserDetails/PetcoUserDetails";
// import Home from '../containers/Home/Home';
import Login from "../containers/Login/Login";
import ResetPassword from "../containers/ResetPassword/ResetPassword";
import PetcoData from "../containers/PetcoData/PetcoData";
import ScrapersAndCompetitors from "../containers/ScrapersAndCompetitors/ScrapersAndCompetitors";
import TeamsAndMerchants from "../containers/TeamsAndMerchants/TeamsAndMerchants";
import MerchantDetails from "../containers/MerchantDetails/MerchantDetails";
import ScraperDetails from "../containers/ScraperDetails/ScraperDetails";
import Alerts from "../containers/Alerts/Alerts";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";
import CompetitorDetails from "../containers/CompetitorDetails/CompetitorDetails";

import ClassDetails from "../containers/PetcoData/ClassDetails";
import SubclassDetails from "../containers/PetcoData/SubclassDetails";
import BrandDetails from "../containers/PetcoData/BrandDetails";
import SKUDetails from "../containers/SKUDetails/SKUDetails";
import BulkConfig from "../containers/BulkConfigurations/BulkConfig";
import ConfigureSkus from "../containers/ConfigureSkus/ConfigureSkus";
import SamlCallback from "../containers/SamlCallback/SamlCallback";
import Settings from "../containers/Settings/Settings";
import ChannelDetails  from "../containers/ChannelDetails/ChannelDetails";

export const PublicRoutes = [
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/reset_password",
    component: <ResetPassword />,
  },
  {
    path: "saml-auth/verify",
    component: <SamlCallback />,
  },
];

export const PrivateRoutes = [
  // {
  //     path: '/',
  //     component: <Home />
  // },
  // Uncommnet above line to enable home
  {
    path: "/channels/:id/sku_data",
    component: <PetcoData {...{ tabindex: "1" }} />,
  },
  {
    path: "/channels/:id/sku_data/class",
    component: <PetcoData {...{ tabindex: "1" }} />,
  },
  {
    path: "/channels/:id/sku_data/subclass",
    component: <PetcoData {...{ tabindex: "2" }} />,
  },
  {
    path: "/channels/:id/sku_data/brand",
    component: <PetcoData {...{ tabindex: "3" }} />,
  },
  {
    path: "/channels/:id/sku_data/sku",
    component: <PetcoData {...{ tabindex: "4" }} />,
  },
  {
    path: "/channels/:id/scrapers_and_competitors",
    component: <ScrapersAndCompetitors {...{ tabindex: "1" }} />,
  },
  {
    path: "/channels/:id/scrapers_and_competitors/scrapers",
    component: <ScrapersAndCompetitors {...{ tabindex: "1" }} />,
  },
  {
    path: "/channels/:id/scrapers_and_competitors/competitors",
    component: <ScrapersAndCompetitors {...{ tabindex: "2" }} />,
  },
  {
    path: "/settings",
    component: <Settings {...{ tabindex: "1" }} />,
  },
  {
    path: "/settings/channel_management",
    component: <Settings {...{ tabindex: "1" }} />,
  },
  {
    path: "settings/teams_and_merchants",
    component: <Settings {...{ tabindex: "2" }} />,
  },
  {
    path: "/settings/approval_policies",
    component: <Settings {...{ tabindex: "3" }} />,
  },
  {
    path: "/settings/teams_and_merchants/users",
    component: <TeamsAndMerchants {...{ tabindex: "1" }} />,
  },
  {
    path: "/settings/teams_and_merchants/merchants",
    component: <TeamsAndMerchants {...{ tabindex: "2" }} />,
  },
  {
    path: "/settings/teams_and_merchants/user_details/:id",
    component: <PetcoUserDetails />,
  },
  {
    path: "/settings/teams_and_merchants/merchant_details/:id",
    component: <MerchantDetails />,
  },
  {
    path: "/settings/channels/:id",
    component: <ChannelDetails />,
  },
  {
    path: "/channels/:id/sku_data/Class/:uid",
    component: <ClassDetails />,
  },
  {
    path: "/channels/:id/sku_data/Subclass/:uid",
    component: <SubclassDetails />,
  },
  {
    path: "/channels/:id/sku_data/Brand/:uid",
    component: <BrandDetails />,
  },
  {
    path: "/channels/:id/sku_data/sku/:uid",
    component: <SKUDetails />,
  },
  {
    path: "/channels/:id/scrapers_and_competitors/scraper_details/:scraper_name",
    component: <ScraperDetails />,
  },
  {
    path: "/channels/:id/scrapers_and_competitors/competitor_details/:competitor_id",
    component: <CompetitorDetails />,
  },
  {
    path: "/channels/:id/alerts",
    component: <Alerts {...{ tabindex: "1" }} />,
  },
  {
    path: "/channels/:id/alerts/approvals",
    component: <Alerts {...{ tabindex: "1" }} />,
  },
  {
    path: "/channels/:id/alerts/subscriptions",
    component: <Alerts {...{ tabindex: "2" }} />,
  },
  {
    path: "/channels/:id/alerts/triggered_alerts",
    component: <Alerts {...{ tabindex: "3" }} />,
  },
  {
    path: "/channels/:id/alerts/match_issues",
    component: <Alerts {...{ tabindex: "4" }} />,
  },
  {
    path: "/channels/:id/alerts/no_price_sku",
    component: <Alerts {...{ tabindex: "5" }} />,
  },
  {
    path: "/channels/:id/bulk_Configurations/allSku",
    component: <BulkConfig {...{ tabindex: "1" }} />,
  },
  {
    path: "/channels/:id/bulk_Configurations/selectedSku",
    component: <BulkConfig {...{ tabindex: "2" }} />,
  },
  {
    path: "/channels/:id/bulk_Configurations/selectedSku/configSku",
    component: <ConfigureSkus />,
  },
];

export const RoutesList = [
  ...PrivateRoutes.map((routeData) => {
    const route = { ...routeData };
    route.component = <PrivateRoute>{routeData.component}</PrivateRoute>;
    return route;
  }),
  ...PublicRoutes,
];
