import { gql } from 'graphql-request';
import { prepareString } from '../../utilities';

export const createGetScraperConfigQuery = (channelId, configid, configdesc, configlevel) => {
    configdesc = prepareString(configdesc);
    try {
        return gql`
            query {
                getScraperConfig(channelId: "${channelId}", configId: "${configid}", configDesc: ${configdesc}, configLevel: "${configlevel}"){
                    configId
                    configLevel
                    activeScraper {
                        scraper
                        competitor
                        lastScrapedDate
                        scrapeExpiryHour
                        scrapeRunStatus
                        scrapeError
                        isActive
                    }
                    activeComp {
                        competitor
                        scraper
                        adderText
                        adderValue
                        pdpMatch
                        incartMatch
                        lastScrapedDate
                        scrapeExpiryHour
                        scrapeRunStatus
                        scrapeError
                        pdpPrice
                        inCartPrice
                        exactPdpPrice
                        exactInCartPrice
                        coupon
                        unmatchedOosReliability
                    }
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetScraperAtSkuConfigQuery = (channelId, configid, brandId, brandDesc) => {
    brandDesc = prepareString(brandDesc);
    try {
        return gql`
            query {
                getScraperConfigAtSku(channelId: "${channelId}", configId: "${configid}", brandId : "${brandId}", brandDesc: ${brandDesc}){
                    configId
                    configLevel
                    activeScraper {
                        scraper
                        competitor
                        lastScrapedDate
                        scrapeExpiryHour
                        scrapeRunStatus
                        scrapeError
                        isActive
                    }
                    activeComp {
                        competitor
                        scraper
                        adderText
                        adderValue
                        pdpMatch
                        incartMatch
                        lastScrapedDate
                        scrapeExpiryHour
                        scrapeRunStatus
                        scrapeError
                        pdpPrice
                        inCartPrice
                        exactPdpPrice
                        exactInCartPrice
                        coupon
                        unmatchedOosReliability
                    }
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createUpdateLevelConfigQuery = (
    configid,
    configdesc,
    configlevel,
    level,
    activeScraper,
    activeComp,
    applyAll,
    changeLog
) => {
    configdesc = prepareString(configdesc);
    changeLog = prepareString(changeLog);
    try {
        return gql`
            mutation {
                updateScraperConfig(
                  configId: "${configid}",
                  configDesc: ${configdesc},
                  configLevel:"${configlevel}", 
                  level:"${level}", 
                  activeScraper: [
                      ${activeScraper}
                  ],
                  activeComp: [
                      ${activeComp}
                  ],
                  applyAll: ${applyAll},
                  changeLog: ${changeLog}
                )
              }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createUpdateLevelConfigAtSkuQuery = (
    configid,
    configDesc,
    activeScraper,
    activeComp,
    changeLog
) => {
    configDesc = prepareString(configDesc);
    changeLog = prepareString(changeLog);
    try {
        return gql`
            mutation {
                updateScraperConfigAtSku(
                  configId: "${configid}",
                  configDesc: ${configDesc},
                  activeScraper: [
                      ${activeScraper}
                  ],
                  activeComp: [
                      ${activeComp}
                  ],
                  changeLog: ${changeLog}
                )
              }
        `;
    } catch (err) {
        console.log(err);
    }
};


export const createGetPriceLevelConfigQuery = (channelId, configid, configdesc, configlevel) => {
    configdesc = prepareString(configdesc);
    try {
        return gql`
            query {
                getPriceConfiguration(channelId: "${channelId}", configId: "${configid}", configDesc: ${configdesc}, configLevel: "${configlevel}"){
                    markdownPrice
                    basePrice
                    defaultGuardMin
                    defaultGuardMinPrice
                    defaultGuardMinPriceSuffix
                    defaultGuardMax
                    defaultGuardMaxPrice
                    defaultGuardMaxPriceSuffix
                    priceRule
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createUpdatePriceLevelConfigQuery = (
    channelId,
    configid,
    configdesc,
    configlevel,
    priceData,
    applyAll,
    hasNoSignal, 
    changeLog
) => {
    configdesc = prepareString(configdesc);
    changeLog = prepareString(changeLog)
    try {
        return gql`
            mutation {
                updatePriceConfiguration(
                  channelId: "${channelId}"
                  configId: "${configid}", 
                  configDesc: ${configdesc},
                  configLevel: "${configlevel}", 
                  priceData: ${priceData},
                  applyAll: ${applyAll},
                  hasNoSignal: ${hasNoSignal},
                  changeLog: ${changeLog}
                )
              }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetPriceLevelSKUConfigQuery = (configid) => {
    try {
        return gql`
            query {
                getPriceConfigurationAtSku(configId: "${configid}"){
                    markdownPrice
                    basePrice
                    defaultGuardMin
                    defaultGuardMinPrice
                    defaultGuardMinPriceSuffix
                    defaultGuardMax
                    defaultGuardMaxPrice
                    defaultGuardMaxPriceSuffix
                    priceRule
                    isMaster
                    msrpPrice
                    msrpStartDate
                    msrpEndDate
                    mapPrice
                    mapStartDate
                    mapEndDate
                    subRdPrice
                    subRdStartDate
                    subRdEndDate
                    mapHoldPrice
                    mapHoldStartDate
                    mapHoldEndDate
                    masterSku
                    familyPriceModifier
                    familyPrice
                    familyPriceSuffix
                    manualPrice
                    manualPriceSuffix
                    manualPriceReason
                    greenBarStatus
                    greenBarPdp
                    greenBarDisplayType
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createUpdatePriceLevelSKUConfigQuery = (channelId, configid, configdesc, priceData, hasNoSignal , changeLog) => {
    configdesc = prepareString(configdesc);
    changeLog = prepareString(changeLog)
    try {
        return gql`
            mutation {
                updatePriceConfigurationAtSku(
                  channelId: "${channelId}",
                  configId: "${configid}", 
                  configDesc: ${configdesc},
                  priceData: ${priceData},
                  hasNoSignal: ${hasNoSignal},
                  changeLog: ${changeLog}
                ){
                    message
                }
              }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetTempGuardrailQuery = (channelId, configid, configdesc, configlevel) => {
    configdesc = prepareString(configdesc);
    try {
        return gql`
            query {
                getGuardrailConfiguration(channelId: "${channelId}"configId: "${configid}", configDesc: ${configdesc}, configLevel: "${configlevel}"){
                    tempGuardMin
                    tempGuardMinPrice
                    tempGuardMinPriceSuffix
                    tempGuardMax
                    tempGuardMaxPrice
                    tempGuardMaxPriceSuffix
                    startTempGuardDate
                    endTempGuardDate
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createUpdateTempGuardrailQuery = (
    channelId,
    configid,
    configdesc,
    configlevel,
    guardData,
    applyAll, 
    changeLog
) => {
    configdesc = prepareString(configdesc);
    changeLog = prepareString(changeLog)
    try {
        return gql`
            mutation {
                updateGuardrailConfiguration(
                  channelId: "${channelId}",
                  configId: "${configid}",
                  configDesc: ${configdesc},
                  configLevel: "${configlevel}", 
                  guardData: ${guardData},
                  applyAll: ${applyAll},
                  changeLog : ${changeLog}
                )
              }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetSKUSubscriptionDetailsQuery = (channelId, skuId) => {
    try {
        return gql`
            query {
                getSkuLevelSubscriptions(channelId: "${channelId}", skuId: "${skuId}"){
                    dataCount
                    data{
                        lessThanPrice
                        lessThanPriceSuffix
                        greaterThanPrice
                        greaterThanPriceSuffix
                        subStartDate
                        subEndDate
                    }
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetUserSubscriptionDetailsQuery = (channelId, userId, search, index, sortType = 'DESC') => {
    try {
        return gql`
            query {
                getUserLevelSubscriptions(channelId: "${channelId}", userId: "${userId}",search: ${prepareString(search)}, index: ${index}, sortType: "${sortType}"){
                    dataCount
                    data{
                        skuId
                        lessThanPrice
                        lessThanPriceSuffix
                        greaterThanPrice
                        greaterThanPriceSuffix
                        subStartDate
                        subEndDate
                        createdBy
                        createdAt
                        updatedBy
                    }
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createUpdateSubscriptionAlertQuery = (channelId, skuId, subscriptionData, changeLog) => {
    changeLog = JSON.stringify(changeLog)
    try {
        return gql`
            mutation {
                updateSubscription(
                  channelId: "${channelId}", 
                  skuId: "${skuId}",
                  subscriptionData: ${subscriptionData},
                  changeLog: ${changeLog}
                )
                {
                    message
                }
              }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const deleteSubscriptionAlertQuery = (skuId) => {
    try {
        return gql`
            mutation {
                deleteSubscription(
                  skuId: "${skuId}")
                {
                    message
                }
              }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetBulkSkuPriceConfigQuery = (skuIds) => {
    try {
        return gql`
            query {
                getPriceConfigurationForSkus(skuAndChannelData: [${skuIds}])
                {
                    skuConfig
                           {
                             skuId
                             skuDesc
                             markdownPrice
                             basePrice
                             defaultGuardMin
                             defaultGuardMinPrice
                             defaultGuardMinPriceSuffix
                             defaultGuardMax
                             defaultGuardMaxPrice
                             defaultGuardMaxPriceSuffix
                             priceRule
                             isMaster
                             msrpStartDate
                             msrpEndDate
                             mapPrice
                             mapStartDate
                             mapEndDate
                             subRdPrice
                             subRdStartDate
                             subRdEndDate
                             mapHoldPrice
                             mapHoldStartDate
                             mapHoldEndDate
                             masterSku
                             familyPriceModifier
                             familyPrice
                             familyPriceSuffix
                             manualPrice
                             manualPriceSuffix
                             manualPriceReason
                             greenBarStatus
                             greenBarPdp
                             greenBarDisplayType
                             lastRepricePrice
                             tempGuardMax
                             tempGuardMaxPrice
                             tempGuardMaxPriceSuffix
                             tempGuardMin
                             tempGuardMinPrice
                             tempGuardMinPriceSuffix
                             startTempGuardDate
                             endTempGuardDate
                             costPrice
                             listPrice
                             rdPrice
                             tempMapPrice
                             tempMsrpPrice
                             
                             
                           }
                        unmatchedConfig{
                            skuId,
                            skuDesc
                        }
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createUpdateBulkSku = (configurationValues) => {
    try {
        return gql`
            mutation {
                bulkUpdateSku(
                    configurationValues: [${configurationValues}]
                ){
                    message
                }
              }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const deleteGuardrailConfiguration = (channelId, configId , configLevel , configdesc) => {
    try{
        configdesc = prepareString(configdesc)
        return gql`
        mutation {
            deleteGuardrailConfiguration(channelId: "${channelId}", configId: "${configId}" , configLevel : "${configLevel}" , configDesc : ${configdesc} )
        }
        `;


    }catch(err) {
        console.log(err);
    }

}

export const createGetMatchIssuesByUserQuery = (channelId, userId, search, index, sortType = 'DESC') => {
    try {
        return gql`
            query {
                getUnmatchTicketsByUser(channelId: "${channelId}", userId: "${userId}", search: ${prepareString(search)}, index: ${index}, sortType: "${sortType}") {
                    data {
                        id
                        skuId
                        ticketType
                        ticketDetails
                        createdBy
                        createdAt
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};
