import React, { useState } from 'react';
import { Button, Modal, Select, Input, Space } from 'antd';
import { useSelector } from 'react-redux';

import {
    updateApprovalStatus
} from '../../graphql/queryExecuters';
import './CompTableReScrapeModal.scss';
import { Notification } from '../../utilities';
import { FormOutlined } from '@ant-design/icons';
import { userTypes } from '../../constants/common';

const ApprovalStatusModal = ({requestId, skuId, requestStatus,isDiscarded, rejectCode, callback}) => {

    const { TextArea } = Input;
    const [approvalApplyModal, setApprovalApplyModal] = useState(false);
    const [reqStatus, setRequestStatus] = useState(requestStatus);
    const [changeReason, setChangeReason] = useState(rejectCode);

    const { userType } = useSelector(state => state.auth);
    const channelId = useSelector((state) => state.channelData.channelId);


    //Helper for loading
    const [loading, setLoading] = useState(false);

    const handleCancel = () => {
        setRequestStatus(requestStatus);
        setApprovalApplyModal(false);
    };
    const handleShow = () => {
        setApprovalApplyModal(true);
    };
    const handleConfirm = async () => {
        if (changeReason.includes('|')) {
            Notification('error', `Price reason may not contain '|' values.`);
            return;
        }
        setLoading(true)
        try {
            let response = await updateApprovalStatus(channelId, requestId, skuId, reqStatus, changeReason);
            if (response === 'Price request updated successfully') {
                Notification('success', 'Price Request Updated Successfully.');
            } else {
                Notification(
                    'error',
                    'Something went wrong while updating price request Data. Please try again later.'
                );
            }
            setLoading(false);
            setApprovalApplyModal(false);
            callback();
        } catch (err) {
            setLoading(false);
            console.log(err);
            Notification(
                'error',
                'Something went wrong while updating price request Data. Please try again later.'
            );
        }
    };
    
    return (
        <>
            <div className="inlineContainer">
            <div style={{ color: 'black', paddingLeft: 20 }}>
            {requestStatus === 'Rejected' && isDiscarded === true && userType !== userTypes.MERCHANT ? <FormOutlined onClick={handleShow} title={"Update price request status"} /> : '---'}
            </div>
            </div>
            <Modal
                open={approvalApplyModal}
                title="Price Approval"
                onCancel={handleCancel}
                width={600}
                footer={[
                    <Button className="closeBtn" key="close" onClick={handleCancel}>
                        Close
                    </Button>,
                    <Button loading={loading} className="tiggerRescrapeBtn" key="triggerRescrape" type="primary" onClick={handleConfirm}>
                        Apply Changes
                    </Button>
                ]}>
                <Space
                    direction="vertical"
                    size="middle"
                    style={{
                    display: 'flex',
                    }}
                >
                    <Select
                    className="accept-reject-dropdown"
                    onChange={(value) => {
                        setRequestStatus(value);
                    }}
                    value={reqStatus}
                    size="large"
                    options={[
                        {
                            label: 'Approve',
                            value: 'Approved'
                        },
                        {
                            label: 'Reject',
                            value: 'Rejected'
                        },
                        
                    ]}
                    style={{
                        width: '50%',              
                    }} 
                    />
                    <TextArea 
                        className="accept-reject-box"
                        rows={8.5} 
                        placeholder="Enter reason (Optional)" 
                        value={changeReason} 
                        onChange={(e) => {
                            setChangeReason(e.target.value);
                        }}
                    />
                </Space>  
                             
            </Modal>
        </>
    );
};

export default ApprovalStatusModal;
