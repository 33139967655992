import GraphQL from "../../configs/graphQL.config";

import * as QueryCreators from "../queryCreators";

export const getAllCountData = async (channelId) => {
  try {
    const query = QueryCreators.createGetAllCountQuery(channelId);
    const response = await GraphQL.request(query);
    return response.getAllCounts;
  } catch (err) {
    console.log(err);
  }
};

export const getAllAssignedCountData = async (channelId, merchantId) => {
  try {
    const query = QueryCreators.createGetAllAssignedCountQuery(
      channelId,
      merchantId
    );
    const response = await GraphQL.request(query);
    return response.getAllAssignCounts;
  } catch (err) {
    console.log(err);
  }
};

export const getUsersCount = async (channelId) => {
  try {
    const query = QueryCreators.createGetUsersCountQuery(channelId);
    const response = await GraphQL.request(query);
    return response.getTeamsAndMerchantsCount;
  } catch (err) {
    console.log(err);
  }
};

export const getScrapersAndCompetitorsCount = async () => {
  try {
    const query = QueryCreators.createGetScraperAndCompetitorCountQuery();
    const response = await GraphQL.request(query);
    return response.getScrapersAndCompetitorsCount;
  } catch (err) {
    console.log(err);
  }
};

export const getUserAlertsCount = async (channelId) => {
  try {
    const query = QueryCreators.createGetUserAlertsCountQuery(channelId);
    const response = await GraphQL.request(query);
    return response.getSubscriptionAlertsCount;
  } catch (err) {
    console.log(err);
  }
};

export const getAlertsCountByUser = async (channelId, userId) => {
  try {
    const query = QueryCreators.createGetAlertsCountByUserQuery(channelId, userId);
    const response = await GraphQL.request(query);
    return response.getAlertsCountByUser;
  } catch (err) {
    console.log(err);
  }
};
