import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Image, Spin } from 'antd';

import './ConfigureSkus.scss';

const SkuViewBox = ({ loading, imageUrl, defImage, viewInfomodaldata, skuDetails }) => {

    const navigate = useNavigate();
    const channelId = useSelector((state) => state.channelData.channelId);

    return (
        <Spin spinning={loading}>
            <div className="box">
                <div className="img-box">
                    <Image
                        style={{
                            paddingTop: '10px',
                            paddingRight: '10px'
                        }}
                        width={120}
                        height={130}
                        preview={true}
                        src={imageUrl}
                        onError={(event) => {
                            event.target.src = defImage;
                            event.onerror = null;
                        }}
                    />
                </div>
                <div className="summaryInfo-item">
                    <p className="whiteSmokeText">SKU Description</p>
                    <p className="petcoTextColor">
                        <u>{viewInfomodaldata.skuDesc}</u>
                    </p>
                </div>
                <div className="summaryInfo-item">
                    <p className="whiteSmokeText">Recommended Price</p>
                    <p>
                        {skuDetails.lastRepricePrice ? `$ ${skuDetails?.lastRepricePrice?.toFixed(2)}` : '---'}
                    </p>
                </div>
                <div className="summaryInfo-item">
                    <p className="whiteSmokeText">Recommended Price Reasons</p>
                    <p>{skuDetails.lastRepriceReason ? skuDetails.lastRepriceReason : '---'}</p>
                </div>
                <div className="summaryInfo-item">
                    <p className="whiteSmokeText">Current Price</p>
                    <p>{skuDetails?.currentPrice ? `$${skuDetails.currentPrice.toFixed(2)}` : '---'}</p>
                </div>
                <div className="summaryInfo-item">
                    <p className="whiteSmokeText">Brand</p>
                    <p
                        onClick={() => {
                            let row = {
                                brandId: skuDetails.skuBrandId,
                                brandDesc: skuDetails.skuBrandDesc
                            };
                            navigate(`/channels/${channelId}/sku_data/brand/` + skuDetails.skuBrandId, {
                                state: { row }
                            });
                        }}
                        style={{ cursor: 'pointer' }}>
                        <u>{skuDetails.skuBrandDesc}</u>
                    </p>
                </div>
                <div className="summaryInfo-item">
                    <p className="whiteSmokeText">Class</p>
                    <p
                        onClick={() => {
                            let row = {
                                classId: skuDetails.classId,
                                classDesc: skuDetails.classDesc
                            };
                            navigate(`/channels/${channelId}/sku_data/class/` + skuDetails.classId, {
                                state: { row }
                            });
                        }}
                        style={{ cursor: 'pointer' }}>
                        <u>{skuDetails.classDesc}</u>
                    </p>
                </div>
                <div className="summaryInfo-item">
                    <p className="whiteSmokeText">Sub-Class</p>
                    <p
                        onClick={() => {
                            let row = {
                                subclassId: skuDetails.subClassId,
                                subclassDesc: skuDetails.subClassDesc
                            };
                            navigate(`/channels/${channelId}/sku_data/subclass/` + skuDetails.subClassId, {
                                state: { row }
                            });
                        }}
                        style={{ cursor: 'pointer' }}>
                        <u>{skuDetails.subClassDesc}</u>
                    </p>
                </div>
                <div className="summaryInfo-item">
                    <p className="whiteSmokeText">Lowest Price w/ Competitor</p>
                    <p>{skuDetails.lowestPrice ? `$${skuDetails.lowestPrice.toFixed(2)} ${skuDetails.lowestPriceCompetitor}` : '---'}</p>
                </div>
                <div className="summaryInfo-item">
                    <p className="whiteSmokeText">Base Price</p>
                    <p>{skuDetails.basePrice ? `$ ${skuDetails?.basePrice?.toFixed(2)}` : '---'}</p>
                </div>
                <div className="summaryInfo-item">
                    <p className="whiteSmokeText">Markdown price</p>
                    <p>{skuDetails.markdownPrice ? `$${skuDetails.markdownPrice.toFixed(2)}` : '---'}</p>
                </div>
                <div className="summaryInfo-item">
                    <p className="whiteSmokeText">MAP</p>
                    <p>{skuDetails.mapPrice ? skuDetails.mapPrice.toFixed(2) : '---'}</p>
                </div>
                <div className='summaryInfo-item'>
                    <p className="whiteSmokeText">MSRP</p>
                    <p>{skuDetails.listPrice ? `$${skuDetails.listPrice.toFixed(2)}` : '---'}</p>
                </div>
                <div className='summaryInfo-item'>
                    <p className="whiteSmokeText">RD price</p>
                    <p>{skuDetails.rdPrice ? `$${skuDetails.rdPrice.toFixed(2)}` : '---'}</p>
                </div>
                <div className="summaryInfo-item">
                    <p className="whiteSmokeText">Base cost</p>
                    <p>{skuDetails.costPrice ? `$${skuDetails.costPrice.toFixed(2)}` : '---'}</p>
                </div>
                <div className="summaryInfo-item">
                    <p className="whiteSmokeText">Sales(Last 30 days)</p>
                    <p>{skuDetails.cyCost ? `$${skuDetails.cyCost.toFixed(2)}` : '---'}</p>
                </div>
                <div className="summaryInfo-item">
                    <p className="whiteSmokeText">Units(Last 30 days)</p>
                    <p>{skuDetails.cySalesUnits ? skuDetails.cySalesUnits : '---'}</p>
                </div>
                <div className="summaryInfo-item">
                    <p className="whiteSmokeText">Page Views(Last 30 days)</p>
                    <p>{skuDetails.productView ? skuDetails.productView : '---'}</p>
                </div>
                <div className="summaryInfo-item">
                    <p className="whiteSmokeText">Publish Status</p>
                    <p>{skuDetails.skuPblshInd ? 'Published' : 'Not Published'}</p>
                </div>
                <div className="summaryInfo-item">
                    <p className="whiteSmokeText">Buyable Status</p>
                    <p>{skuDetails.skuBuyableInd ? 'Buyable' : 'Not Buyable'}</p>
                </div>
            </div>
        </Spin>
    );
}

export default SkuViewBox;