import { gql } from "graphql-request";

export const createGetAllCountQuery = (channelId) => {
  try {
    return gql`
      query {
        getAllCounts(channelId: "${channelId}") {
          classCount
          subclassCount
          brandCount
          skuCount
        }
      }
    `;
  } catch (err) {
    console.log(err);
  }
};

export const createGetAllAssignedCountQuery = (channelId, merchantId) => {
  try {
    return gql`
            query {
                getAllAssignCounts(channelId: "${channelId}", merchantId: "${merchantId}") {
                    classCount
                    subclassCount
                    brandCount
                    skuCount
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};

export const createGetUsersCountQuery = (channelId) => {
  try {
    return gql`
      query {
        getTeamsAndMerchantsCount (channelId: "${channelId}"){
          teamCount
          merchantCount
        }
      }
    `;
  } catch (err) {
    console.log(err);
  }
};

export const createGetScraperAndCompetitorCountQuery = (merchantId) => {
  try {
    return gql`
      query {
        getScrapersAndCompetitorsCount {
          scraperCount
          competitorCount
        }
      }
    `;
  } catch (err) {
    console.log(err);
  }
};

export const createGetUserAlertsCountQuery = (channelId) => {
  try {
    return gql`
      query {
        getSubscriptionAlertsCount(channelId: "${channelId}",) {
          subscriptionCount
          approvalCount
          alertCount
          matchIssuesCount
          unpricedSkusCount
        }
      }
    `;
  } catch (err) {
    console.log(err);
  }
};

export const createGetAlertsCountByUserQuery = (channelId, userId) => {
  try {
    return gql`
            query {
                getAlertsCountByUser(channelId: "${channelId}", userId: "${userId}") {
                    subscriptionCount
                    logCount
                    alertCount
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};
