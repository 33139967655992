import GraphQL from "../../configs/graphQL.config";

import * as QueryCreators from "../queryCreators";

export const getAllSubclassData = async (
  index,
  size,
  search,
  sortType,
  channelId
) => {
  try {
    const query = QueryCreators.createGetAllSubclassDataQuery(
      index,
      size,
      search,
      sortType,
      channelId
    );
    const response = await GraphQL.request(query);
    return response.getAllSubclasses;
  } catch (err) {
    console.log(err);
  }
};

export const getSubclassDetails = async (subclassId, subclassDesc) => {
  try {
    const query = QueryCreators.createGetSubclassDetailsQuery(
      subclassId,
      subclassDesc
    );
    const response = await GraphQL.request(query);
    return response.getLevelDetails;
  } catch (err) {
    console.log(err);
  }
};

export const getSubclassAtClassData = async (
  id,
  index,
  size,
  search,
  sortType,
  channelId
) => {
  try {
    const query = QueryCreators.createGetSubclassAtClassDataQuery(
      id,
      index,
      size,
      search,
      sortType,
      channelId
    );
    const response = await GraphQL.request(query);
    return response.getAllSubclassesAtClass;
  } catch (err) {
    console.log(err);
  }
};

export const getAllAssignSubClassData = async (
  channelId,
  merchantId,
  index,
  size,
  search,
  sortType
) => {
  try {
    const query = QueryCreators.createGetAllAssignSubClassDataQuery(
      channelId,
      merchantId,
      index,
      size,
      search,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllAssignSubclasses;
  } catch (err) {
    console.log(err);
  }
};

export const getAssignSubclassAtClassData = async (
  merchantId,
  id,
  index,
  size,
  search,
  sortType
) => {
  try {
    const query = QueryCreators.createGetAssignSubclassAtClassDataQuery(
      merchantId,
      id,
      index,
      size,
      search,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllAssignSubclassesAtClass;
  } catch (err) {
    console.log(err);
  }
};

export const getAllMerchantAssignedSubclasses = async (channelId, merchantId, searchedtext) => {
  try {
    const query = QueryCreators.getAllMerchantAssignedSubClassesQuery(channelId, merchantId, searchedtext);
    const response = await GraphQL.request(query);
    return response.getMerchantSubclassesToAssignChannel;
  } catch (err) {
    console.log(err);
  }
};
