import { gql } from "graphql-request";

export const getApprovalPolicyQuery = () => {
  try {
    return gql`
    query{
        getApprovalPolicy{
                acceptedPriceIncrease
                acceptedPriceDecrease
                acceptedPriceNegativeMargin
        }
      }
    `
  } catch (err) {
    console.log(err);
  }
};

export const updateApprovalPolicyQuery = (acceptedPriceIncrease, acceptedPriceDecrease, acceptedPriceNegativeMargin) => {
    try {
      return gql`
      mutation{
        updateOrCreateApprovalPolicy(acceptedPriceIncrease:${acceptedPriceIncrease}, acceptedPriceDecrease:${acceptedPriceDecrease}, acceptedPriceNegativeMargin:${acceptedPriceNegativeMargin})
      }
      `
    } catch (err) {
      console.log(err);
    }
  };