import { gql } from 'graphql-request';

export const createUpdateAssignMerchantLevelConfigQuery = (channelId, merchantid, levelsdata) => {

    try {
        return gql`
            mutation {
                createAssignment(
                channelId: "${channelId}",  
                merchantId: "${merchantid}",  
                levels: [
                      ${levelsdata}
                  ]
                )
              }
        `;
    } catch (err) {
        console.log(err);
    }
}

export const deleteMerchantLevelConfigQuery = (channelId, merchantId, levelsdata) => {

    try {
        return gql`
            mutation {
                deleteAssignment(
                channelId: "${channelId}", 
                merchantId: "${merchantId}",
                levels: [
                      ${levelsdata}
                  ]
                )
              }
        `;
    } catch (err) {
        console.log(err);
    }
}

export const createCheckMerchantAccessQuery = (channelId, merchantId, levelType, levelId, levelDesc = '') => {
    try {
        return gql`
        query {
            checkMerchantAccess (channelId: "${channelId}", merchantId: "${merchantId}", levelType: "${levelType}", levelId: "${levelId}", levelDesc: "${levelDesc}")
        }
        `;
    } catch (err) {
        console.log(err);
    }
}

export const channelSkuAssigmentQuery = (channelId, levelsdata) => {

    try {
        return gql`
            mutation {
                createChannelSkuAssignment(
                channelId: "${channelId}",  
                levels: [
                      ${levelsdata}
                  ]
                )
              }
        `;
    } catch (err) {
        console.log(err);
    }
}

export const deleteChannelAssigmentSkuQuery = (channelId, levelsdata) => {

    try {
        return gql`
            mutation {
                deleteChannelSkuAssignment(
                channelId: "${channelId}",  
                levels: [
                      ${levelsdata}
                  ]
                )
              }
        `;
    } catch (err) {
        console.log(err);
    }
}