import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Input, Select} from 'antd';

import // Input
// Spin
'antd';
// import { SearchOutlined } from '@ant-design/icons';

import './Competitors.scss';
import CompetitorDetailsTable from '../../components/Tables/CompetitorDetails.table';
import { ScrapersActions } from '../../redux/slices/scrapers.slice';
import { CompetitorsActions } from '../../redux/slices/competitors.slice';
import { getAllCompetitors, getAllScrapers } from '../../graphql/queryExecuters';
import {addCompetitor} from '../../graphql/queryExecuters/competitors.executer'
import { Notification } from '../../utilities';
import { userTypes } from '../../constants/common';
import { getCompetitorsBaseData } from '../../redux/actionCreators/competitors.actions';
import * as DisplayStrings from '../../constants/displayStrings';

const Competitors = () => {

    const nullState = {
        name: '',
        valueAdder:'',
        valueSubtracted:'',
        perValueAdder:'',
        perValueSubtracted:'',
        scraper:''
    }
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    //Scrapers State
    const { competitors, competitorsBaseDetails } = useSelector((state) => state.competitors);

    const { userType } = useSelector((state) => state.auth);
    const channelId = useSelector((state) => state.channelData.channelId);

    const [loading, setLoading] = useState(false);
    const [showDefaultAdderModal, setShowDefaultAdderModal] = useState(false);
    const [currentDefaultAdder, setCurrentDefaultAdder] = useState('');
    const [currentDefaultAdderValue, setCurrentDefaultAdderValue] = useState(0);
    //Helper to store aligned table data for scrapers
    const [tableData, setTableData] = useState([]);

    const [formData, setFormData] = useState(nullState);

    //Helper for show required fields
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    
    //Helper to prepare proper table data
    useEffect(() => {
        const preparedData = prepareTableData();
        setTableData(preparedData);
    }, [competitors, competitorsBaseDetails]) //eslint-disable-line

    useEffect(() => {
        if (userType === userTypes.MERCHANT) {
            navigate('/');
        }
        getScraperTrigger();
    }, []); //eslint-disable-line

    //Helper to open add competitor modal handler
    const openCompetitorModalHandler = () => {
        setFormData(nullState);
        setIsSubmitClicked(false);
        setShowDefaultAdderModal(true);
    };

    //Preparing scrapers table data
    const prepareTableData = () => {
        const preparedData = competitors?.map(competitor => {
            const baseData = competitorsBaseDetails.find(baseData => competitor?.compId === baseData?.competitorId);
            if (baseData) {
                const newData = {...competitor};
                newData.skuScraped = baseData.skuScraped;
                newData.lastScrapedDate = baseData.lastScrapedDate;
                return newData;
            } else {
                return competitor;
            }
        })

        return preparedData;
    }

    const onClickDataHandler = (competitor, index) => {
        navigate(`/channels/${channelId}/scrapers_and_competitors/competitor_details/${competitor.compId}`);
    };



    const onChangeValueHandler = (key, value) => {
        setFormData((prevState) => {
            const state = { ...prevState };
            if (key === 'adder' || key === 'adderValue') {
                if (key === 'adder') {
                    setCurrentDefaultAdder(value);
                    setCurrentDefaultAdderValue(0);
                    state['valueAdder'] = 0;
                    state['valueSubtracted'] = 0;
                    state['perValueAdder'] = 0;
                    state['perValueSubtracted'] = 0;
                } else {
                    setCurrentDefaultAdderValue(value);
                }

                if (key === 'adderValue' && value >= 0) {
                    state[currentDefaultAdder] = value;
                }
            } else {
                state[key] = value;
            }
            return state;
        });
    };    

    let setCompData = {
        name : formData.name,
        valueAdder : formData.valueAdder ? formData.valueAdder : 0,
        valueSubtracted : formData.valueSubtracted ? formData.valueSubtracted : 0,
        perValueAdder : formData.perValueAdder ? formData.perValueAdder : 0,
        perValueSubtracted : formData.perValueSubtracted ? formData.perValueSubtracted : 0,
        scrapper : formData.scraper
    }

    //Handler for close add comp modal
    const onClickCloseModalHandler = () => {
        setShowDefaultAdderModal(false);
        setIsSubmitClicked(false);
    }
    //Handler for adding new competitor
    const onClickAddCompetitorHandler = async () => {
        try {
            setIsSubmitClicked(true);
            const isValidData = validateData();
            if (isValidData) {
                setLoading(true);
                const response = await addCompetitor(setCompData.name, setCompData.valueAdder, setCompData.valueSubtracted, setCompData.perValueAdder, setCompData.perValueSubtracted, setCompData.scrapper);
                if (response === 'Created successfully') {
                    Notification('success', DisplayStrings.competitor_creation_success);
                    getScraperTrigger(true);
                } else {
                    Notification('error', DisplayStrings.competitor_creation_error);
                }
                setLoading(false);
                setShowDefaultAdderModal(false);
            }
        } catch (err) {
            console.log('Error - Competitors Comp - Error while adding competitor, ', err);
            setLoading(false);
        }
    };
    
    const getScraperTrigger = async (forceGet = false) => {
        try {
            if (competitors?.length === 0 || forceGet) {
                const competitorsData = await getAllCompetitors();
                if (competitorsData) {
                    dispatch(CompetitorsActions.setCompetitors({ competitors: competitorsData.data }));
                }
                const scrapersData = await getAllScrapers();
                const activeScraper = scrapersData.data.filter((scrap) => scrap.status);
                if (activeScraper) {
                    dispatch(ScrapersActions.setActiveScrapers({ activeScrapers: activeScraper }));
                }
            }

            dispatch(getCompetitorsBaseData());

        } catch (err) {
            console.log(err);
            Notification('error', DisplayStrings.scraper_data_error);
        }
    };

    // Add Competitor Validation
    const validateData = () => {
        //Competitor name validation
        if(!formData.name || formData.name.length === 0){
            Notification('error', DisplayStrings.competitor_name_empty);
            return false;
        }

        //Competitor existance validation
        if(competitors.map(competitorData => competitorData.name.toLowerCase()).includes(formData.name.toLowerCase())){
            Notification('error', DisplayStrings.competitor_with_name_exist);
            return false;
        }

        //Scraper Validation
        if(!formData.scraper || formData.scraper.length === 0){
            Notification('error', DisplayStrings.scraper_name_empty);
            return false;
        }
        return true;
    }


    return (
        <div>
            <div className="competitors-list-container">
                {userType === userTypes.ADMIN && <div className='actions'>
                    <Button
                        id='add-competitor'
                        type="primary"
                        onClick={openCompetitorModalHandler}
                    >
                    + Add competitor
                    </Button>
                </div>}
                <CompetitorDetailsTable
                    data={tableData}
                    onClickRow={onClickDataHandler}
                />
                <Modal
                open={showDefaultAdderModal}
                title="Add Competitor"
                footer={null}
                onCancel={onClickCloseModalHandler}>
                <div className="set-default-adder-container">
                    <div style={{marginTop: '24px'}}>Name</div>
                        <Input
                            id='add-comp-name'
                            className='input-global'
                            placeholder='Enter Competitor Name'
                            value={formData.name}
                            onChange={(e) => {
                                onChangeValueHandler('name', e.target.value);
                            }}
                            status={
                                !formData.name && isSubmitClicked ? 'error' : ''
                            }
                        style={{marginBottom:'20px'}}/>
                    <div className="title">Adder</div>
                    <div className="input">
                        <Input.Group compact>
                            <Select
                                id='add-comp-adder'
                                className="adder"
                                placeholder="Select Default Adder"
                                value={currentDefaultAdder}
                                onChange={(e) => {
                                    onChangeValueHandler('adder', e);
                                }}>
                                <Select.Option key={'valueAdder'}>Add ($)</Select.Option>
                                <Select.Option key={'valueSubtracted'}>Subtract ($)</Select.Option>
                                <Select.Option key={'perValueAdder'}>Add (%)</Select.Option>
                                <Select.Option key={'perValueSubtracted'}>
                                    Subtract (%)
                                </Select.Option>
                            </Select>
                            <Input
                                id='add-comp-value'
                                placeholder='Adder Value'
                                onChange={(e) => {
                                    onChangeValueHandler
                                    ('adderValue', Number(e.target.value));
                                }}
                                type="number"
                                className="adder-value"
                                value={currentDefaultAdderValue}
                            />
                        </Input.Group>
                    </div>
                    <div style={{marginTop: '0px'}}>Scrapers</div>
                    <Input
                        id='add-comp-scrapers'
                        className='input-global'
                        placeholder='Scrapers'
                        value={formData.scraper}
                        onChange={(e) => {
                            onChangeValueHandler('scraper', e.target.value);
                        }}
                        status={
                            !formData.scraper && isSubmitClicked ? 'error' : ''
                        }
                    />
                    <div className="actions">
                        <Button
                            id='add-comp-close-btn'
                            type="default"
                            onClick={onClickCloseModalHandler}>
                            Close
                        </Button>
                        <Button id='add-comp-apply-btn' type="primary" onClick={onClickAddCompetitorHandler} loading={loading}>
                            Apply Changes
                        </Button>
                    </div>
                </div>
            </Modal>

                
            </div>
        </div>
    );
};

export default Competitors;