import { gql } from 'graphql-request';
import { prepareString } from '../../utilities';

export const createGetAllChannelDataQuery = (index, page, search) => {
    try {
        return gql`
            query {
                getAllChannels(size: ${index}, index: ${page}, search: ${prepareString(search)}){
                    data {
                        channelId
                        channelName
                        channelOwner
                        defaultStatus
                    }
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAllAssignedChannelDataQuery = (merchantId, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignChannels(merchantId: "${merchantId}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}"){
                    data {
                        channelId
                        channelDesc
                        classCount
                        subclassCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createChannelQuery = (channelName, channelOwner) => {
    try {
        return gql`
            mutation {
                createChannel(channelOwner: "${channelOwner}", channelName: "${channelName}"){
                    message
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

// not removing keeping for reference
// export const createUpdateChannelQuery = (updatedData) => {
//     try {
//         return gql`
//         mutation {
//             updateChannel(
//               channelId: "${updatedData.channelId}"
//               channelName: "${updatedData.channelName}"
//               channelOwner: "${updatedData.channelOwner}"
//               defaultStatus: ${updatedData.defaultStatus}
//             ) {
//               message
//             }
//           }
//         `;
//     } catch (err) {
//         console.log(err);
//     }
// };

export const createUpdateChannelQuery = (updatedData) => {
    try {
        let mutationFields = `channelId: "${updatedData.channelId}", `;

       // Check each field in the updatedData and add it to the mutationFields if it has been updated
       Object.keys(updatedData).forEach(key => {
        if (updatedData[key] !== null && updatedData[key] !== undefined && key !== 'channelId') {
            if (key === 'defaultStatus') {
                // If the key is defaultStatus, handle it as a boolean
                mutationFields += `${key}: ${updatedData[key]}, `;
            } else {
                mutationFields += `${key}: "${updatedData[key]}", `;
            }
        }
    });


        return gql`
            mutation {
                updateChannel(
                    ${mutationFields}
                ) {
                    message
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const deleteChannelQuery = (channelId) => {
    try {
        return gql`
            mutation {
                deleteChannel(channelId: "${channelId}"){
                    message
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const getAllMerchantSkusToAssignChannelQuery = (channelId, merchantId) => {
    try {
        return gql`
        query{
            getAllMerchantSkusToAssignChannel(channelId:"${channelId}", merchantId:"${merchantId}", size:1000){
              skuData{
                skuId
                skuDesc
              }
              channelAssignedMerchantSkus{
                skuId
                skuDesc
              }
              dataCount
            }
          }`
    } catch (err){
        console.log(err);
    }
}

export const addSelectedSkuToChannelQuery = (skuIds, channelId, merchantId) => {
    try {
        return gql`
        mutation{
            assignMerchantSkusToChannel(channelId:"${channelId}", skuIds:[${skuIds}], merchantId:"${merchantId}"){
              message
            }
          }`
    } catch (err){
        console.log(err);
    }
}