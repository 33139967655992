import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import './Scrapers.scss';
import ScrapersTable from '../../components/Tables/Scrapers.table';
import { userTypes } from '../../constants/common';
import { getScrapersBaseData } from '../../redux/actionCreators/scrapers.actions';

const Scrapers = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    //Scrapers State
    const { scrapers, scrapersBaseDetails } = useSelector((state) => state.scrapers);

    const { userType } = useSelector(state => state.auth);
    const channelId = useSelector(state => state.channelData.channelId);

    //Helper to store aloigned table data for scrapers
    const [tableData, setTableData] = useState([]);

    //Redirecting user if not a valid user
    useEffect(() => {
        if (userType === userTypes.MERCHANT) {
            navigate('/');
        }
    }, [userType]) //eslint-disable-line

    //Helper to prepare proper table data
    useEffect(() => {
        const preparedData = prepareTableData();
        setTableData(preparedData);
    }, [scrapers, scrapersBaseDetails])//eslint-disable-line

    //Checking Scraprs Base Data
    useEffect(() => {
        checkScraperBaseDetails();
    }, [])//eslint-disable-line

    //Helper to check scraper base data
    const checkScraperBaseDetails = () => {
        try {
            dispatch(getScrapersBaseData());
        } catch (err) {
            console.log('Error - Error while getting scraper base details', err);
        }
    }

    //Preparing scrapers table data
    const prepareTableData = () => {
        const preparedData = scrapers.map(scraper => {
            const baseData = scrapersBaseDetails.find(baseData => scraper?.id === baseData?.scraperId);
            if (baseData) {
                const newData = {...scraper};
                newData.skuScraped = baseData.skuScraped;
                newData.lastScrapedDate = baseData.lastScrapedDate;
                return newData;
            } else {
                return scraper;
            }
        })

        return preparedData;
    }

    const onClickDataHandler = (scraper, index) => {
        navigate(`/channels/${channelId}/scrapers_and_competitors/scraper_details/${scraper.name}`);
    };
    return (
        <div className="table-container">
            <ScrapersTable data={tableData} onClickRow={onClickDataHandler} />
        </div>
    );
};

export default Scrapers;
