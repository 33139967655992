import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const getlevelAlerts = async (skuId, index, size, sortType) => {
    try {
        const query = QueryCreators.createGetLevelAlertsQuery(skuId, index, size, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAlertsBySku;
    } catch (err) {
        console.log(err);
    }
};

export const getUserAlerts = async (channelId, userId, index, size, searchText, sortType) => {
    try {
        const query = QueryCreators.createGetUserAlertsQuery(channelId, userId, index, size, searchText, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAlertsByUser;
    } catch (err) {
        console.log(err);
    }
};

export const getAllAlerts = async (channelId, userId, index, size, searchText, sortType) => {
    try {
        const query = QueryCreators.createGetAllAlertsQuery(channelId, userId, index, size, searchText, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAlerts;
    } catch (err) {
        console.log(err);
    }
};

export const getLiveAlertsCount = async (channelId) => {
    try {
        const query = QueryCreators.createGetLiveAlertsCountQuery(channelId);
        const response = await GraphQL.request(query);
        return response.getUnreadAlerts;
    } catch (err) {
        console.log(err);
    }
}

export const updateLiveAlertsCount = async (channelId) => {
    try {
        const query = QueryCreators.createUpdateLiveAlertsCountQuery(channelId);
        const response = await GraphQL.request(query);
        return response.markUnreadAlert;
    } catch (err) {
        console.log(err);
    }
}

export const createUnmatchTicket = async (channelId, skuId, ticketDetails) => {
    try {
        const query = QueryCreators.createUnmatchTicketQuery(channelId, skuId, ticketDetails);
        const response = await GraphQL.request(query);
        return response.createUnmatchTicket;
    } catch (err) {
        console.log(err);
    }
}
