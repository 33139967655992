import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const getAllChannelData = async (index, page, search) => {
    try {
        const query = QueryCreators.createGetAllChannelDataQuery(index, page, search);
        const response = await GraphQL.request(query);
        return response.getAllChannels;
    } catch (err) {
        console.log(err);
    }
};

export const getAllAssignedChannelData = async (merchantId, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAllAssignedChannelDataQuery(merchantId, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignChannels;
    } catch (err) {
        console.log(err);
    }
};

export const createChannel = async (channelName, channelOwner) => {
    try {
        const query = QueryCreators.createChannelQuery(channelName, channelOwner);
        const response = await GraphQL.request(query);
        return response.getAllChannels;
    } catch (err) {
        console.log(err);
    }
};

export const updateChannelDetails = async (updatedData) => {
    try {
        const query = QueryCreators.createUpdateChannelQuery(updatedData);
        const response = await GraphQL.request(query);
        return response.updateChannel;
    } catch (err) {
        console.log(err);
    }
}

export const deleteChannel = async (channelId) => {
    try{
        const query = QueryCreators.deleteChannelQuery(channelId);
        const response = await GraphQL.request(query);
        return response.deleteChannel;
    } catch (err) {
        console.log(err);
    }
}

export const getAllMerchantSkusToAssignChannel = async (channelId, merchantId) => {
    try{
        const query = QueryCreators.getAllMerchantSkusToAssignChannelQuery(channelId, merchantId);
        const response = await GraphQL.request(query);
        return response.getAllMerchantSkusToAssignChannel;
    } catch(err){
        console.log(err);
    }
}

export const assignSelectedSkuToChannel = async (skuIds, channelId, merchantId) => {
    try {
        const query = QueryCreators.addSelectedSkuToChannelQuery(skuIds, channelId, merchantId);
        const response = await GraphQL.request(query);
        return response.assignMerchantSkusToChannel;
    } catch(err){
        console.log(err);
    }
}