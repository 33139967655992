import GraphQL from "../../configs/graphQL.config";

import * as QueryCreators from "../queryCreators";

export const getAllSkuData = async (
  channelId,
  index,
  size,
  search,
  filters,
  sortType
) => {
  try {
    const query = QueryCreators.createGetAllSkuDataQuery(
      channelId,
      index,
      size,
      search,
      filters,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllSkus;
  } catch (err) {
    console.log(err);
  }
};

export const getSkuDataForSearch = async (
  index,
  size,
  search,
  filters,
  sortType
) => {
  try {
    const query = QueryCreators.createGetSkuDataForSearchQuery(
      index,
      size,
      search,
      filters,
      sortType
    );
    console.log(query);
    const response = await GraphQL.request(query);
    return response.getAllSkus;
  } catch (err) {
    console.log(err);
  }
};

export const getSkuAtClassData = async (
  channelId,
  id,
  index,
  size,
  search,
  filters,
  sortType
) => {
  try {
    const query = QueryCreators.createGetSkuAtClassDataQuery(
      channelId,
      id,
      index,
      size,
      search,
      filters,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllSkusAtClass;
  } catch (err) {
    console.log(err);
  }
};

export const getSkuAtSubclassData = async (
  channelId,
  id,
  index,
  size,
  search,
  filters,
  sortType
) => {
  try {
    const query = QueryCreators.createGetSkuAtSubclassDataQuery(
      channelId,
      id,
      index,
      size,
      search,
      filters,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllSkusAtSubclass;
  } catch (err) {
    console.log(err);
  }
};

export const getSkuAtBrandData = async (
  channelId,
  id,
  desc,
  index,
  size,
  search,
  filters,
  sortType
) => {
  try {
    const query = QueryCreators.createGetSkuAtBrandDataQuery(
      channelId,
      id,
      desc,
      index,
      size,
      search,
      filters,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllSkusAtBrand;
  } catch (err) {
    console.log(err);
  }
};

export const getSKUDetails = async (channelId,id) => {
  try {
    const query = QueryCreators.createGetSkuDetailsQuery(channelId, id);
    const response = await GraphQL.request(query);
    return response.getSkuDetailsById;
  } catch (err) {
    console.log(err);
  }
};

export const getAllAssignSkuData = async (
  channelId,
  merchantId,
  index,
  size,
  search,
  filters,
  sortType
) => {
  try {
    const query = QueryCreators.createGetAllAssignSkuDataQuery(
      channelId,
      merchantId,
      index,
      size,
      search,
      filters,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllAssignSkus;
  } catch (err) {
    console.log(err);
  }
};

export const getAssignSkuAtClassData = async (
  merchantId,
  id,
  index,
  size,
  search,
  filters,
  sortType
) => {
  try {
    const query = QueryCreators.createGetAssignSkuAtClassDataQuery(
      merchantId,
      id,
      index,
      size,
      search,
      filters,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllAssignSkusAtClass;
  } catch (err) {
    console.log(err);
  }
};

export const getAssignSkuAtSubclassData = async (
  merchantId,
  id,
  index,
  size,
  search,
  filters,
  sortType
) => {
  try {
    const query = QueryCreators.createGetAssignSkuAtSubclassDataQuery(
      merchantId,
      id,
      index,
      size,
      search,
      filters,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllAssignSkusAtSubclass;
  } catch (err) {
    console.log(err);
  }
};

export const getAssignSkuAtBrandData = async (
  merchantId,
  id,
  desc,
  index,
  size,
  search,
  filters,
  sortType
) => {
  try {
    const query = QueryCreators.createGetAssignSkuAtBrandDataQuery(
      merchantId,
      id,
      desc,
      index,
      size,
      search,
      filters,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllAssignSkusAtBrand;
  } catch (err) {
    console.log(err);
  }
};

export const getSKUGraphData = async ( id, duration) => {
  try {
    const query = QueryCreators.createGetSKUGraphDataQuery(
      id,
      duration,
    );
    const response = await GraphQL.request(query);
    return response.getSkuGraphData;
  } catch (err) {
    console.log(err);
  }
};

export const bulkAddSkuData = async (skuIds) => {
  try {
    const query = QueryCreators.createBulkAddSKUDataQuery(skuIds);
    const response = await GraphQL.request(query);
    return response.getBulkSkuList;
  } catch (err) {
    console.log(err);
  }
};

export const getEligibleSKUsForMaster = async (skuIds) => {
  try {
    const query = QueryCreators.createGetEligibleSKUForMasterQuery(skuIds);
    const response = await GraphQL.request(query);
    return response.getEligibleSkuForMaster;
  } catch (err) {
    console.log(err);
  }
};



export const getAllChildSkuData = async (
  id,
  index,
  size,
  search,
  filters,
  sortType
) => {
  try {
    const query = QueryCreators.createGetAllChildSkuDataQuery(
      id,
      index,
      size,
      search,
      filters,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllChildSkus;
  } catch (err) {
    console.log(err);
  }
};

export const getUnpricedSKUList = async (channelId, index, size, search, sortType) => {
  try {
    const query = QueryCreators.createGetUnPricedSKUsList(
      channelId,
      index,
      size,
      search,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getUnpricedSkuList;
  } catch (err) {
    console.log(err);
  }
};

export const getAllSkuDataBulk = async (
  channelId,
  index,
  size,
  search,
  filters,
  sortType
) => {
  try {
    const query = QueryCreators.createGetAllSkuDataBulkQuery(
      channelId,
      index,
      size,
      search,
      filters,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllSkusBulk;
  } catch (err) {
    console.log(err);
  }
};

export const getAllAssignSkuDataBulk = async (
  channelId,
  merchantId,
  index,
  size,
  search,
  filters,
  sortType
) => {
  try {
    const query = QueryCreators.createGetAllAssignSkuDataBulkQuery(
      channelId,
      merchantId,
      index,
      size,
      search,
      filters,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllAssignSkusBulk;
  } catch (err) {
    console.log(err);
  }
};

export const getRepriceStatusBySku = async (channelId, skuId) => {
  try {
    const query = QueryCreators.createGetRepriceStatusQuery(channelId, skuId);
    const response = await GraphQL.request(query);
    return response.getRePriceStatusBySku;
  } catch (err) {
    console.log(err);
  }
};

export const getAllMerchantAssignedSkuData = async (channelId, merchantId, searchedtext) => {
  try {
    const query = QueryCreators.getAllMerchantAssignedSkuQuery(channelId, merchantId, searchedtext);
    const response = await GraphQL.request(query);
    return response.getMerchantSkusToAssignChannel;
  } catch (err) {
    console.log(err);
  }
};