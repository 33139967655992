import { useEffect, useState } from 'react';
import { Button, Input, Modal, Select, Table, Pagination } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate , useSearchParams , useLocation} from 'react-router-dom';
import './PetcoUsers.scss';
import { Notification, validateEmail } from '../../utilities';
import { addUser, getUsersList } from '../../graphql/queryExecuters';
import { useDispatch, useSelector } from 'react-redux';
import { generic_name_error, users_fetch_error, generic_email_error} from "../../constants/displayStrings";
import { userTypes } from '../../constants/common';
import * as UserActions from '../../redux/actionCreators/users.actions';
import { petcoUsersTableColumns } from '../../constants/tableColumns';

const PetcoUsers = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const urlParams = new URLSearchParams(location.search).toString();
    const [filterParams , setFilterParams] = useSearchParams();

    const [loading, setLoading] = useState(false);
    const [pageindex, setPageindex] = useState(filterParams.get('index') ? filterParams.get('index') : 1);
    const [searchText, setSearchText] = useState(filterParams.get('search') ? filterParams.get('search') : '');
    const [sortType, setSortType] = useState('ASC');
    const [rowCount, setRowCount] = useState(0);
    const [tableData, setTableData] = useState([]);

    const { userType } = useSelector(state => state.auth);
    const channelId = useSelector((state) => state.channelData.channelId);

    const getIndexParam = () => parseInt(filterParams.get('index')) || 1;

    const getFilterParams = () => {
        return {
            search: filterParams.get('search') || '',
            index: getIndexParam(),
        }
    }

    useEffect(() => {
        setSearchText(filterParams.get('search') || '');
        setPageindex(filterParams.get('index') || 1);
    }, [filterParams]);

    useEffect(() => {
        if(channelId){
            getUsersListInitiator()
        }
    }, [pageindex, sortType, channelId]); //eslint-disable-line

    //Initial values of add user part
    const [petcoUser, setPetcoUser] = useState({
        name: '',
        email: '',
        userType: 'PETCO-USER',
        accessRole: 'PRICING-TEAM'
    });

    //Helper for showing add user form
    const [showAddUserModal, setShowAddUSerModal] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isProperEmail, setIsProperEmail] = useState(true);

    const getUsersListInitiator = async (manualTrigger = false) => {
        setLoading(true);
        const res = await getUsersList(channelId, 'ADMIN', pageindex, 10, searchText, sortType);
        if(res.__typename !== 'Error') {
            setTableData(res.user);
            setRowCount(res.count);
            onSearchInput(res.count)
        } else {
            Notification('error', users_fetch_error);
        }
        setLoading(false);
    }

    //Cancel Add User Handler
    const onCancelAddUserHandler = () => {
        setShowAddUSerModal(false);
        setPetcoUser({ 
            email: '', 
            name: '', 
            userType: 'PETCO-USER', 
            accessRole: 'PRICING-TEAM' 
        });
    };

    //Inviting user to app
    const onAddUserHandler = async () => {
        try {
            const petcoUserName = petcoUser.name.trim()
            const petcoUserEmail = petcoUser.email.trim()
            setShowError(true);
            if(petcoUserName) {
                const isValidEmail = validateEmail(petcoUserEmail);
                setIsProperEmail(isValidEmail);
                if (isValidEmail) {
                    setShowAddUSerModal(false);
                    setLoading(true);
                    const res = await addUser(petcoUserName, petcoUserEmail.toLowerCase(), 'ADMIN', petcoUser.accessRole, "");
                    setLoading(false);
                    if (res.__typename === 'Error') {
                        Notification('error', res.message);
                    } else if (res.message === 'User Already Exist') {
                        Notification('warning', res.message);
                    } else {
                        //Getting updated users list
                        dispatch(UserActions.getUsersCount(channelId));
                        getUsersListInitiator(true);
                        Notification('success', res.message);
                        setShowError(false);
                        setIsProperEmail(false);
                    }
                } else {
                    Notification('warning', 'Invalid Email', generic_email_error);
                }
            } else {
                Notification('warning', 'Invalid Name', generic_name_error);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const onClickRow = (row, index) => {
        try {
            navigate(`/settings/teams_and_merchants/user_details/${row.id}`, { state: { searchParams: urlParams, row } });
        } catch (err) {
            console.log(err);
        }
    };

    const onChangeValueHandler = (key, value) => {
        setPetcoUser((prevState) => {
            const state = { ...prevState };
            state[key] = value;
            return state;
        });
    }

    const onSearchInput = (value) => {
        if (value < (getIndexParam() - 1) * 10) {
            setFilterParams({
                ...getFilterParams(),
                index: 1,
                search : searchText
            })
        }
    }

    const handlePageChange = (event) => {
        setFilterParams({
            ...getFilterParams(),
            index: event,
        })
        setPageindex(event);
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        let sort = sorter.order === 'ascend' ? 'ASC' : sorter.order === 'descend' ? 'DESC' : '';
        setSortType(sort);
    };

    //Search when user press ENTER
    const onPressEnterKeyHandler = (event) => {
        try {
            if (event.key === 'Enter') {
                setFilterParams({
                    ...getFilterParams(),
                    index: 1,
                    search: event.target.value
                })
                getUsersListInitiator()
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
            <div className="table-container">
                <div className="actions">
                    <Input
                        className="data-search responsive-Search-Bar"
                        prefix={<SearchOutlined />}
                        id='petco-users-search'
                        onKeyDown={onPressEnterKeyHandler}
                        onChange={handleSearchChange}
                        value={searchText}
                            placeholder={searchText ? searchText : "Search for Pricing Team members"}
                        suffix={<span className='press-enter-style'>Press enter to search</span>}
                    />
                    <div className="actions-container">
                        {userType === userTypes.ADMIN && <Button
                            type="primary"
                            id='petco-users-add-member'
                            onClick={() => {
                                setShowAddUSerModal(true);
                            }}>
                            + Add Pricing Team Member
                        </Button>}
                    </div>
                </div>
                <Table
                    onRow={(row, index) => {
                        return {
                            onClick: () => {
                                onClickRow(row, index);
                            }
                        };
                    }}
                    id='petco-users-table'
                    rowKey="id"
                    dataSource={tableData}
                    columns={petcoUsersTableColumns}
                    className="table-striped-rows clickable-table"
                    scroll={{ x: 2000 }}
                    pagination={false}
                    size="middle"
                    onChange={handleTableChange}
                    loading={loading}
                />
                {rowCount > 10 ? <Pagination
                        id='petco-users-filter'
                        className="data-pagination"
                        current={getIndexParam()}
                        total={rowCount}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                        size="small"
                />: <></>}
                <Modal
                    open={showAddUserModal}
                    title="Add Team Member"
                    okText="Send Invite"
                    cancelText="Close"
                    onCancel={onCancelAddUserHandler}
                    onOk={onAddUserHandler}>
                    <div style={{marginTop: '24px'}}>Name</div>
                    <Input
                        className='input-global'
                        id='add-user-name'
                        value={petcoUser.name}
                        onChange={(e) => {
                            onChangeValueHandler('name', e.target.value);
                        }}
                        status={
                            (showError && !petcoUser.name.trim()) ? 'error' : ''
                        }
                    />
                    <div style={{marginTop: '16px'}}>Email Address</div>
                    <Input
                        className='input-global'
                        id='add-user-email'
                        value={petcoUser.email}
                        onChange={(e) => {
                            onChangeValueHandler('email', e.target.value);
                        }}
                        status={
                            (showError && (!petcoUser.email || !isProperEmail)) ? 'error' : ''
                        }
                    />

                    <div style={{marginTop: '16px'}}>Access Role</div>
                    <Select
                        disabled
                        id='add-user-role'
                        style={{width:'100%', marginBottom: '32px'}}
                        value={petcoUser.accessRole}
                        onChange={(e) => {
                            onChangeValueHandler('accessRole', e);
                        }}
                    >
                        <Select.Option value="PRICING-TEAM" key="PRICING-TEAM">Pricing Team</Select.Option>
                        {/* <Select.Option value="EADS-TEAM" key="EADS-TEAM">EADS Team</Select.Option> */}
                    </Select>
                </Modal>
            </div>
    );
};

export default PetcoUsers;
