import { useEffect, useMemo, useState } from 'react';
import { Breadcrumb, Button, Divider, Dropdown, Modal, Select, Spin } from 'antd';
import { ArrowLeftOutlined, CloseOutlined, DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, Tabs, Badge } from 'antd';

// import './MerchantDetails.scss';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import ClassTable from '../../components/Tables/Class.table';
import SubclassTable from '../../components/Tables/Subclass.table';
import BrandsTable from '../../components/Tables/Brands.table';
import SKUTable from '../../components/Tables/SKU.table';
import MerchantTableWrapper from '../../components/MerchantTableWrapper/MerchantTableWrapper';
import { useSelector } from 'react-redux';

import { getAllMerchantAssignedClasses, getAllMerchantAssignedSubclasses, getAllMerchantAssignedBrandData, getAllMerchantAssignedSkuData, channelSkuAssigment, getChannelById, deleteSkuAssigment } from '../../graphql/queryExecuters';
import { useLocation, useNavigate, useParams, useNavigationType } from 'react-router-dom';
import { Notification, prepareString } from '../../utilities';
import { getAllAssignedCountData } from '../../graphql/queryExecuters';
import { merchantAccessTypes } from '../../constants/common';
import {
    assignment_deletion_error, assignment_deletion_success,
    assignment_item_missing_error, brand_data_error, class_data_error,
    count_list_error,
    data_assignment_error,
    data_assignment_success,
    sku_data_error, subclass_data_error,
} from "../../constants/displayStrings";

import { capitalizeFirstLetter } from '../../utilities';

const ChannelDetails = () => {

    const nullState = {
        name: '',
        email: '',
        accessLevel: merchantAccessTypes[0].value
    };

    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { state, pathname } = location;
    const navigationType = useNavigationType();
    const channelId = useSelector((state) => state.channelData.channelId);
    const merchantId = useSelector((state) => state.auth.id);

    const [showModalFor, setShowModalFor] = useState('');
    const [selectedLevelData, setSelectedLevelData] = useState([]);
    const [currentLevelSelected, setCurrentLevelSelected] = useState(null);
    const [showSelected, setShowSelected] = useState([]);
    const [loadingReferenceData, setLoadingReferenceData] = useState(false);
    const [allCountList, setAllCountList] = useState({
        classCount: state?.row?.classCount || 0,
        subclassCount: state?.row?.subclassCount || 0,
        brandCount: state?.row?.brandCount || 0,
        skuCount: state?.row?.skuCount || 0,
        merchantLogCount: state?.row?.merchantLogCount || 0
    });
    const [isCreating, setIsCreating] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [tabindex, setTabindex] = useState('1');
    const [currentLevel, setCurrentLevel] = useState('class');
    //searching for a sku
    const [searchedtext,setSearchedText] = useState('');

    //State for local list (For selection)
    const [classesData, setClassesData] = useState([]);
    const [subClassesData, setSubClassesData] = useState([]);
    const [brandsData, setBrandsData] = useState([]);
    const [skusData, setSkusData] = useState([]);

    //Helper for reload data after adding/updating level data
    const [reloadData, setReloadData] = useState({
        class: false,
        subclass: false,
        brand: false,
        sku: false
    })

    const [select, setSelect] = useState({
        selectedRowKeys: [],
        selectedRowData: []
    });

    useEffect(() => {
        // if(channelId){
            getAllCountTrigger();
        // }
    }, []); //eslint-disable-line

     useEffect(() => {
        getChannelDetailsTrigger();
    }, []); //eslint-disable-line

    const getAllCountTrigger = async () => {
        try {
            const allCountData = await getAllAssignedCountData(id, merchantId);
            setAllCountList(allCountData);
            for (const key in allCountData) {
                if (state?.row.hasOwnProperty(key)) {
                    state.row[key] = allCountData[key];
                }
            }
            navigate(pathname, { state: { searchParams: state.searchParams, row: state.row}, replace: true });
        } catch (err) {
            console.log(err);
            Notification('error', count_list_error);
        }
    };

    const [loading, setLoading] = useState(false);

    const [channelDetails, setChannelDetails] = useState({
        channelName: state?.row?.channelName || '',
        channelAdmin: state?.row?.channalOwner || '',
        defaultStatus: state?.row?.defaultStatus || '',
    });

    const { userType } = useSelector(state => state.auth);

   

    useEffect(() => {
        modalForFunction()
    }, [showModalFor]); //eslint-disable-line

    // //trigger searching for sku

    useEffect(() => {
        if(showModalFor?.levelType === 'class'){
            getClassDataTrigger()
        }
    }, [searchedtext, showModalFor]); //eslint-disable-line

    useEffect(() => {
        if(showModalFor?.levelType === 'subclass'){
            getSubClassDataTrigger()
        }
    }, [searchedtext, showModalFor]); //eslint-disable-line

    useEffect(() => {
        if(showModalFor?.levelType === 'brand'){
            getBrandDataTrigger()
        }
    }, [searchedtext, showModalFor]); //eslint-disable-line

    useEffect(() => {
        if(showModalFor?.levelType === 'sku'){
            getSkuDataTrigger()
        }
    }, [searchedtext, showModalFor]); //eslint-disable-line

    //modal opened for which level function
    const modalForFunction = () => {
        if (classesData?.length === 0 && showModalFor?.levelType === 'class') {
            getClassDataTrigger();
        } else if (subClassesData?.length === 0 && showModalFor?.levelType === 'subclass') {
            getSubClassDataTrigger();
        } else if (brandsData?.length === 0 && showModalFor?.levelType === 'brand') {
            getBrandDataTrigger();
        } else if (skusData?.length === 0 && showModalFor?.levelType === 'sku') {
            getSkuDataTrigger();
        }
    }

    const getChannelDetailsTrigger = async () => {
        try {
            setLoading(true);
            const res = await getChannelById(id);
            if (res.__typename === 'Error') {
                Notification('error', res.message);
            } else {
                // Check if the data array is not empty
                if (res.data && res.data.length > 0) {
                    const channelData = res.data[0]; // Assuming the first object in the array is what you need
                    setChannelDetails(prevState => {
                        const state = { ...prevState };
                        state.channelName = channelData.channelName;
                        state.channelOwner = channelData.channelOwner;
                        state.defaultStatus = channelData.defaultStatus;
                        return state;
                    });
                } else {
                    Notification('error', 'No channel data found');
                }
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }
    

    const getClassDataTrigger = async () => {
        try {
            let classData = [];
            classData = await getAllMerchantAssignedClasses(id, merchantId, searchedtext);
            setClassesData(classData.data);
        } catch (err) {
            console.log(err);
            Notification('error', class_data_error);
        }
    };

    const getSubClassDataTrigger = async () => {
        try {
            let subclassData = [];
            subclassData = await getAllMerchantAssignedSubclasses(id, merchantId, searchedtext);
            setSubClassesData(subclassData.data);
        } catch (err) {
            console.log(err);
            Notification('error', subclass_data_error);
        }
    };

    const getBrandDataTrigger = async () => {
        try {
            let brandsData = [];
            brandsData = await getAllMerchantAssignedBrandData(id, merchantId, searchedtext);
            setBrandsData(brandsData.data);
        } catch (err) {
            console.log(err);
            Notification('error', brand_data_error);
        }
    };
    const filters = {
        data: {
            class: "",
            subclass: "",
            brand: "",
            sku: "",
            publish: "",
            pricing_rule: ""
        }
    };
    const getSkuDataTrigger = async () => {
        try {
            setLoadingReferenceData(true);
            let skuData = [];
            const filter = `{
                data : {
                    class: "${filters.data.class}",
                    subclass: "${filters.data.subclass}",
                    brand: "${filters.data.brand}",
                    sku: "${filters.data.sku}",
                    publish: "${filters.data.publish}",
                    pricing_rule: "${filters.data.pricing_rule}",

                }
            }`
            skuData = await getAllMerchantAssignedSkuData(id, merchantId, searchedtext);
            setSkusData(skuData.data);
            setLoadingReferenceData(false);
        } catch (err) {
            console.log(err);
            Notification('error', sku_data_error);
            setLoadingReferenceData(false);
        }
    };
    const { selectedRowKeys } = select;
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, data) => {
            setSelect({
                ...select,
                selectedRowKeys: selectedRowKeys,
                selectedRowData: data
            });
        }
    };

    let newProps = {
        level: 'merchant',
        id: '',
        count: allCountList,
        merchantId: merchantId,
        channelId: id,
        rowSelection: rowSelection
    };

    const handleSearchChange = (value, label) => {
        const selectedvalue =
        {
            levelId: value,
            levelType: showModalFor.levelType,
            levelName: label.label,
        };
        setCurrentLevelSelected(selectedvalue);
    };

    const addSbuHandler = () => {
        if (currentLevelSelected && !selectedLevelData.some(a => a.levelId === currentLevelSelected?.levelId)) {
            const combinedData = [...selectedLevelData, { ...currentLevelSelected }]
            setSelectedLevelData(combinedData);
            setShowSelected(combinedData);
            setCurrentLevelSelected(null);
        }
    };

    const onClickAddHandler = (tableFor) => {
        setShowModalFor({ levelType: tableFor });
    };

    const onClickRemoveHandler = async () => {
        try {
            setIsDeleting(true);
            const levelIds = ['classId', 'subclassId', 'brandId', 'skuId'];
            const descriptions = ['classDesc', 'subclassDesc', 'brandDesc', 'skuDesc']
            const tabNumber = +tabindex - 1;
            let levelText = select?.selectedRowData?.map((level) => {
                return `{
                levelId: ${prepareString(level?.[levelIds[tabNumber]])},
                levelType: "${currentLevel}",
                levelName: ${prepareString(level?.[descriptions[tabNumber]])}
            }`;
            });
            const response = await deleteSkuAssigment(id, levelText.join(','));
            if (response === 'SKUS Deleted To The channel Successfully') {
                Notification('success', `${capitalizeFirstLetter(currentLevel)} ${assignment_deletion_success}`);
                const levels = Object.keys(reloadData);
                setReloadHandler(levels[tabNumber], true);
                setSelect({
                    selectedRowData: [],
                    selectedRowKeys: []
                });
                getAllCountTrigger();
                setReloadData({
                    class: true,
                    subclass: true,
                    brand: true,
                    sku: true
                });
            } else {
                Notification('error', assignment_deletion_error);
            }
            setIsDeleting(false);
        } catch (error) {
            console.log(error);
            setIsDeleting(false);
        }
    };

    const handleRemove = (e, item) => {
        let temp = showSelected;
        let data = temp.filter((o) => o !== item);
        setSelectedLevelData(data);
        setShowSelected(data);
    };

    const openModal = (e, levelType) => {
        setShowModalFor({ levelType: levelType });
    };

    const sendmerchantData = {
        merchantId: id,
        levels: showSelected
    };

    const items = [
        { label: <a onClick={(e) => openModal(e, 'class')}>Class</a>, key: '01' }, //eslint-disable-line
        { label: <a onClick={(e) => openModal(e, 'subclass')}>Subclass</a>, key: '02' }, //eslint-disable-line
        { label: <a onClick={(e) => openModal(e, 'brand')}>Brands</a>, key: '03' }, //eslint-disable-line
        { label: <a onClick={(e) => openModal(e, 'sku')}>SKU</a>, key: '04' } //eslint-disable-line
    ];

    //Helper for setting setting reload state for levels
    const setReloadHandler = (level, levelState) => {
        setReloadData(prevState => {
            const state = {...prevState}
            state[level] = levelState;
            return state;
        });
    }

    const tabs = [
        {
            label: (
                <span id='merchant-class-tab'>
                    <span style={{ color: tabindex === '1' ? '#001952' : '#96999F' }}>Class{' '}</span>
                    <Badge
                        showZero
                        className="class-count-badge"
                        count={allCountList?.classCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '1' ? '#001952' : '#EBF1FF',
                            color: tabindex === '1' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '1',
            children: (
                <MerchantTableWrapper
                    tableFor="class"
                    isDeleting={isDeleting}
                    disabled={selectedRowKeys?.length === 0}
                    onClickRemove={onClickRemoveHandler}
                    onClickAdd={onClickAddHandler}>
                    <ClassTable reload = {reloadData.class} setReload={setReloadHandler}  {...newProps} />
                </MerchantTableWrapper>
            )
        },
        {
            label: (
                <span id='merchant-subclass-tab'>
                    <span style={{ color: tabindex === '2' ? '#001952' : '#96999F' }}>Subclass{' '}</span>
                    <Badge
                        showZero
                        className="class-count-badge"
                        count={allCountList?.subclassCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '2' ? '#001952' : '#EBF1FF',
                            color: tabindex === '2' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '2',
            children: (
                <MerchantTableWrapper
                    tableFor="subclass"
                    isDeleting={isDeleting}
                    disabled={selectedRowKeys?.length === 0}
                    onClickRemove={onClickRemoveHandler}
                    onClickAdd={onClickAddHandler}>
                    <SubclassTable reload = {reloadData.subclass} setReload={setReloadHandler}  {...newProps} />
                </MerchantTableWrapper>
            )
        },
        {
            label: (
                <span id='merchant-brand-tab'>
                    <span style={{ color: tabindex === '3' ? '#001952' : '#96999F' }}>Brand{' '}</span>
                    <Badge
                        showZero
                        className="brands-count-badge"
                        count={allCountList?.brandCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '3' ? '#001952' : '#EBF1FF',
                            color: tabindex === '3' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '3',
            children: (
                <MerchantTableWrapper
                    tableFor="brand"
                    isDeleting={isDeleting}
                    disabled={selectedRowKeys?.length === 0}
                    onClickRemove={onClickRemoveHandler}
                    onClickAdd={onClickAddHandler}>
                    <BrandsTable reload = {reloadData.brand} setReload={setReloadHandler}  {...newProps} />
                </MerchantTableWrapper>
            )
        },
        {
            label: (
                <span id='merchant-sku-tab'>
                    <span style={{ color: tabindex === '4' ? '#001952' : '#96999F' }}>SKU{' '}</span>
                    <Badge
                        showZero
                        className="sku-count-badge"
                        count={allCountList?.skuCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '4' ? '#001952' : '#EBF1FF',
                            color: tabindex === '4' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '4',
            children: (
                <MerchantTableWrapper
                    tableFor="sku"
                    isDeleting={isDeleting}
                    disabled={selectedRowKeys?.length === 0}
                    onClickRemove={onClickRemoveHandler}
                    onClickAdd={onClickAddHandler}>
                    <SKUTable reload = {reloadData.sku} setReload={setReloadHandler}  {...newProps} />
                </MerchantTableWrapper>
            )
        },
    ];

    const onAddItemHandler = async () => {
        try {
            if (!isCreating && sendmerchantData.levels.length > 0) {
                setIsCreating(true);
                let levelText = sendmerchantData.levels.map((level) => {
                    return `{
                    levelId: ${prepareString(level.levelId)},
                    levelType: "${level.levelType}",
                    levelName: ${prepareString(level.levelName)}
                }`;
                });
                if(sendmerchantData.levels[0].levelType === 'class'){
                    let isAssignmentfailed = false;
                    for(const text in levelText){
                        const response = await channelSkuAssigment(id, levelText[text]);
                        if (response !== 'SKUS Added To The Channel Successfully') {
                            isAssignmentfailed = true;
                        }
                    }
                    if(isAssignmentfailed){
                        Notification('warning', data_assignment_error);
                        setIsCreating(false); 
                    } else {
                        Notification('success', `${capitalizeFirstLetter(showModalFor?.levelType)} ${data_assignment_success}`);
                        //Re-triggering get data
                        setReloadHandler(showModalFor?.levelType, true);
            
                        setShowModalFor('');
                        setShowSelected([]);
                        setSelectedLevelData([]);
                        setIsCreating(false); 
                        getAllCountTrigger();
                        setReloadData({
                            class: true,
                            subclass: true,
                            brand: true,
                            sku: true
                        });
                        setSearchedText('')
                    }
                } else {
                    const response = await channelSkuAssigment(id, levelText.join(','));
                    if (response === 'repeated sku') {
                        Notification('warning', data_assignment_error);
                        setIsCreating(false); 
                    } else {
                        Notification('success', `${capitalizeFirstLetter(showModalFor?.levelType)} ${data_assignment_success}`);
                        //Re-triggering get data
                        setReloadHandler(showModalFor?.levelType, true);
            
                        setShowModalFor('');
                        setShowSelected([]);
                        setSelectedLevelData([]);
                        setIsCreating(false); 
                        getAllCountTrigger();
                        setReloadData({
                            class: true,
                            subclass: true,
                            brand: true,
                            sku: true
                        });
                        setSearchedText('')
                    }
                }
            } else {
                Notification('warning', assignment_item_missing_error);
            }
        } catch (error) {
            console.log(error);
            setIsCreating(false);
        }
    };

    const searchList = useMemo(() => {
        if (showModalFor.levelType === 'class') {
             const data = classesData?.map((item) => {
                return {
                    key: item.id,
                    value: item.id,
                    label: item.desc
                };
            });
            return data;
        } else if (showModalFor.levelType === 'subclass') {
            const data = subClassesData?.map((item) => {
                return {
                    key: item.id,
                    value: item.id,
                    label: item.desc
                };
            });
            return data;
        } else if (showModalFor.levelType === 'brand') {
            const data = brandsData?.map((item) => {
                return {
                    key: `${item.id} ${item.desc}`,
                    value: `${item.id}_${item.desc}`,
                    label: item.desc
                };
            });
            return data;
        } else if (showModalFor.levelType === 'sku') {
            const data = skusData?.map((item) => {
                return {
                    key: item.id,
                    value: item.id,
                    label: item.desc
                };
            });
            return data;
        }
    }, [
        showModalFor, 
        classesData, 
        subClassesData, 
        brandsData, 
        skusData
    ]);

    const onTabChange = (key) => {
        setSelect({
            selectedRowKeys: [],
            selectedRowData: []
        });
        setTabindex(key);
        if (key === '1') {
            setCurrentLevel('class');
        } else if (key === '2') {
            setCurrentLevel('subclass');
        } else if (key === '3') {
            setCurrentLevel('brand');
        } else if (key === '4') {
            setCurrentLevel('sku');
        }
    };

    const onBreadClickHandler = () => {
        if (navigationType === 'PUSH') navigate(-1 ,{state:{path:location.pathname , searchParams : state.searchParams}, replace: true});
        navigate('/settings/teams_and_merchants/merchants');
        if(userType === 'MERCHANT'){
            navigate('/settings')
        }
    }

    function formatTitle(input) {
        if (input === 'sku') {
            return input.toUpperCase();
        }
        return input?.charAt(0)?.toUpperCase() + input?.slice(1);
    }

    return (
        <Spin spinning={loading}>
            <ContentWrapper>
                <div className="merchant-details-container">
                    <div className='breadcrumb-container'>
                        <Breadcrumb
                        items={[
                            {
                                title:(
                                    <div style={{ display: 'inline-flex'}}>
                                    <div style={{color: '#001952', cursor:'pointer'}} onClick={onBreadClickHandler}>
                                        <ArrowLeftOutlined/>
                                    </div>
                                    <div style={{color: '#96999F', marginLeft: 10}}>Channel Details</div>
                                </div>
                                )
                            },
                            {
                                title: channelDetails.channelName,
                                style:{ color: '#001952' }
                            }
                        ]}
                        />
                       
                    </div>
                    <div className="user-base-details">
                        <div className="name-and-actions">
                            <div className="name-container">{channelDetails.channelName}</div>
                            <div className="actions-container">
                                <Dropdown menu={{ items }} trigger={['click']}>
                                    <Button type='primary' id='merchant-assign-btn' className='assignData-btn'>
                                        + Assign Data <DownOutlined />
                                    </Button>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="detail-container">
                            <div className="detail">
                                <div className="key">Channel Admin</div>
                                <div className="value">{channelDetails.channelOwner}</div>
                            </div>
                            <div className="detail">
                                <div className="key">Default Status</div>
                                <div className="value">{channelDetails.defaultStatus}</div>
                            </div>
                        </div>
                    </div>
                    <div className="user-table-container">
                        <Tabs defaultActiveKey="1" items={tabs} onChange={onTabChange} />
                    </div>
                </div>
                <Modal
                    open={showModalFor}
                    title={`Add ${formatTitle(showModalFor?.levelType)}`}
                    okText="Add Selected"
                    okButtonProps={{
                        className: "blue-btn",
                        loading: isCreating
                    }}
                    cancelButtonProps={{
                        disabled: isCreating
                    }}
                    cancelText="Close"
                    destroyOnClose={true}
                    onCancel={() => {
                        setShowModalFor('');
                        setSelectedLevelData([]);
                        setShowSelected([]);
                        setCurrentLevelSelected(null);
                    }}
                    onOk={onAddItemHandler}>
                    <div className="add-merchant-items-modal">
                        <div className="search-container">
                            <Select
                                className="selectLevel"
                                id='merchant-select-hierarchy'
                                showSearch
                                style={{
                                    width: '70%',
                                    color: 'white'
                                }}
                                placeholder="Search to Select"
                                //optionFilterProp="children"
                                // filterOption={(input, option) =>
                                //     (option && option?.label && option?.label.toLowerCase().includes(input.toLowerCase()))
                                // }                                                              
                                options={searchList}
                                onSearch={(value) => setSearchedText(value)}
                                onChange={(value, label) => handleSearchChange(value, label)}
                                value={currentLevelSelected?.levelId || null}
                                loading={loadingReferenceData}
                                // disabled={loadingReferenceData}
                            />
                            <Button type='primary' icon={<PlusOutlined />} id='merchant-add-btn' onClick={addSbuHandler}>
                                Add
                            </Button>
                        </div>
                        <div className="selected-items">
                            <div className="title">{`Selected ${formatTitle(showModalFor?.levelType)}'s`}</div>
                            <Divider style={{ marginBottom: 8 }} />
                            <div className="selected-items-scrollable">
                                {showSelected &&
                                    showSelected.map((item) => (
                                        <li key={item.levelId}>
                                            <p
                                                style={{
                                                    color: '#001952',
                                                    font: '10px',
                                                    margin: 0
                                                }}>
                                                {item.levelName}{' '}
                                                <CloseOutlined
                                                    style={{ float: 'right', marginRight: 16 }}
                                                    onClick={(e) => handleRemove(e, item)}
                                                />
                                            </p>{' '}
                                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                                        </li>
                                    ))}
                            </div>
                        </div>
                    </div>
                </Modal>
            </ContentWrapper>
        </Spin>
    );
};

export default ChannelDetails;
