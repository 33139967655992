import { gql } from 'graphql-request';
import { prepareString } from '../../utilities';

//Get all competitors Data
export const createGetAllCompetitorsQuery = () => {
    try {
        return gql`
            query {
                getCompetitors {
                    data {
                        name
                        lastScrapedDate
                        brandScraped
                        skuScraped
                        valueAdder
                        valueSubtracted
                        perValueAdder
                        perValueSubtracted
                        scrapers
                        compId
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

//Get competitor Data by competitor_id
export const createGetCompetitorByIdQuery = (competitorId) => {
    try {
        return gql`
            query {
                getCompetitorById(data: { data: { competitor_id: "${competitorId}" } }) {
                    name
                    lastScrapedDate
                    deptScraped
                    brandScraped
                    skuScraped
                    valueAdder
                    valueSubtracted
                    perValueAdder
                    perValueSubtracted
                    scrapers
                    compId
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

//Get Competitor SKU Hiostorical Data by Page size
export const createGetCompetitorSKUQuery = (
    competitorId,
    page = 1,
    limit = 10,
    searchText = '',
    scrapers = [],
    stockType = '',
    period = 'last_thirty_days'
) => {
    try {
        return gql`
            query {
                getCompetitorSkus(page: ${page}, limit: ${limit}, search: ${prepareString(searchText)}, scrapers: ${prepareString(scrapers)}, 
                stockType: "${stockType}", competitorId: "${competitorId}", period: "${period}") {
                    totalCount
                    dataCount
                    data {
                        id
                        skuId
                        name
                        dayId
                        price
                        inCartPrice
                        partNumber
                        url
                        inventoryStatus
                        matchType
                        scraper
                        offerDate
                        availability
                        thirdParty
                        amazonFba
                        fbw
                    }
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

//Get Competitor SKU Recent Data by Page size
export const createGetRecentCompetitorSKUQuery = (
    competitorId,
    page = 1,
    limit = 10,
    searchText = '',
    scrapers = [],
    stockType = '',
    period = 'today'
) => {
    try {
        return gql`
            query {
                getRecentCompetitorSkus(page: ${page}, limit: ${limit}, search: ${prepareString(searchText)}, scrapers: ${prepareString(scrapers)}, 
                stockType: "${stockType}", competitorId: "${competitorId}", period: "${period}") {
                    totalCount
                    dataCount
                    data {
                        id
                        skuId
                        name
                        dayId
                        price
                        inCartPrice
                        partNumber
                        url
                        inventoryStatus
                        matchType
                        scraper
                        offerDate
                        availability
                        thirdParty
                        amazonFba
                        fbw
                    }
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

//Update Competitor Details
export const createUpdateCompetitorQuery = (competitorId, fieldsToUpdate) => {
    try {
        // Fields to update should be array of "{field: "FieldName", value: "valueToUpdate"}"
        return gql`
            mutation {
                updateCompetitorDetails(
                    data: {
                        data: {
                            competitor_id: "${competitorId}",
                            fields_to_update: [${fieldsToUpdate
                                .map(
                                    (updation) =>
                                        `{field: "${updation.field}", value: ${
                                            typeof updation.value === 'string'
                                                ? `"${updation.value}"`
                                                : updation.value
                                        }}`
                                )
                                .join(', ')}]
                        }
                    }
                ) {
                    data
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

//Get SKU Count by competitor
export const createGetSkuCountByCompetitorQuery = () => {
    return gql`
        query {
            getSkusCountByCompetitor {
                data
            }
        }
    `;
};

//Re-scrape query
export const createUpdateCompetitorTriggerQuery = (configId, configdesc, configLevel, competitor, scraper, expiryHour) => {
    configdesc = prepareString(configdesc)
    try {
        return gql`
        mutation {
            updateCompetitorTriggerConfig (configId: "${configId}", configDesc: ${configdesc}, configLevel: "${configLevel}", competitor: "${competitor}", scraper: "${scraper}", scrapeExpiryHour: "${expiryHour}")
        }
        `;
    } catch (err) {
        console.log(err);
    }
};

//Get base details for all competitors
export const createGetAllCompetitorsBaseDataQuery = () => {
    try {
        return gql`
            query {
                getCompetitorsBaseData {
                    competitorId
                    skuScraped
                    lastScrapedDate
                    totalRecords
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createNewCompetitorQuery = (name,valueAdder,valueSubtracted,perValueAdder,perValueSubtracted,scrapers) => {
    try{
        return gql`
        mutation{
            createCompetitor(competitor:{
                name:"${name}",
                valueAdder:${valueAdder},
                valueSubtracted:${valueSubtracted},
                perValueAdder:${perValueAdder},
                perValueSubtracted:${perValueSubtracted},
                scrapers:"${scrapers}"
            })
        }`
    } catch (err) {
        console.log(err)
    }
};