import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Notification } from '../../utilities';
import { Table, Input, Pagination, Button, Drawer, Tag, Badge, Tabs, Row, Col } from 'antd';
import { RightOutlined, SearchOutlined } from '@ant-design/icons';
import BrandsTable from '../../components/Tables/Brands.table';
import ClassTable from '../../components/Tables/Class.table';
import SubclassTable from '../../components/Tables/Subclass.table';
import { bulkAllSkuDataTableColumns } from '../../constants/tableColumns';
import { SkuActions } from '../../redux/slices/sku.slice';
import { getAllAssignSkuDataBulk, getAllSkuDataBulk } from '../../graphql/queryExecuters';

import './Tables.scss';
import { SelectedSkuActions } from '../../redux/slices/selectedSkus.slice';
import { userTypes } from '../../constants/common';
import { AllCountActions } from '../../redux/slices';
const MAX_SKU_LIMIT = 30000;

const BulkConfigure = (props) => {

    const initialFiltersValue = {
        data: {
            class: '',
            subclass: '',
            brand: '',
            sku: '',
            publish: '',
            pricing_rule: ''
        }
    };

    const dispatch = useDispatch();
    const { selectedSkuList, selectedSkuCount, selectedRowKeysList } = useSelector((state) => state.selectedSku);
    const { allCountList,publishedSkuCount } = useSelector((state) => state.allCount);
    const { skuList } = useSelector((state) => state.skuData);
    const { userType, id } = useSelector(state => state.auth);

    const [loading, setLoading] = useState(false);
    const [pageindex, setPageindex] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [sortType, setSortType] = useState('ASC');
    const [rowCount, setRowCount] = useState(props?.count?.skuCount);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);    
    const [buttonCount,setButtonCount] = useState(publishedSkuCount);
    const [pageSize,setPageSize] = useState(10);
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
    const [filters, setFilters] = useState(initialFiltersValue);
    const [filterTags, setFilterTags] = useState([]);
    const [tabindex, setTabindex] = useState('1');

    const channelId = useSelector((state) => state.channelData.channelId);

    useEffect(() => {
        setSelectedRowKeys(selectedRowKeysList)
        setSelectedRows(selectedSkuList)
        if(selectedRowKeysList.length===0){
            setPageSize(10);
        }
    },[selectedRowKeysList]); //eslint-disable-line

    const newProps = {
        level: 'bulkConfig',
        id: '',
        count: allCountList,
        channelId: channelId
    }

    const updateTagValues = (levelFilteredValues) => {
        if (levelFilteredValues.status === true) {
            setFilterTags([...filterTags, levelFilteredValues]);
        } else if (levelFilteredValues.status === false) {
            const setData = filterTags.filter(
                (item) => item.value.desc !== levelFilteredValues.value.desc
            );
            setFilterTags(setData);
        }
    };

    const isTagChecked = (desc, id, level) => {
        return (filterTags.filter(item => 
            item.value.desc === desc && 
            item.value.id === id && 
            item.value.level === level)).length > 0
    }

    const clearSelection = () => {
        // clearing selection
        setSelectedRowKeys([]);
        setSelectedRows([]);
        dispatch(SelectedSkuActions.setSelectedSkuData({ selectedSkuData: [] }));
        dispatch(SelectedSkuActions.setSelectedRowKeysData({ selectedRowKeysData: [] }));
        dispatch(SelectedSkuActions.setSelectedSkuCount({ selectedSkuCount: 0 }));
        if(skuList.length>10){
            setPageSize(10)
        }
        setButtonCount(publishedSkuCount);
        
        props.callback([]);
    };

    const onSelectChange = async (selectedKeys, selectedRowsData) => {
        
        setSelectedRowKeys(selectedKeys);
        setSelectedRows(selectedRowsData);
        //filtering the selectedDeselectedValues
        const deSelectedKeyValues = selectedRowKeys.filter((item) => {
            return !selectedKeys.includes(item)
        })
        const uniqueIds = new Set();
        const SelectedSetData = [...selectedSkuList,...selectedRowsData].filter((item)=> {
            const isDuplicate= uniqueIds.has(item.skuId);
            uniqueIds.add(item.skuId);
            if(!isDuplicate){
                return true
            }
            return false
        
        })
        const SelectedKeySetData = Array.from(new Set([...selectedRowKeysList,...selectedKeys]));
        const finalSelectedKeys = SelectedKeySetData.filter((item) => {
            return !deSelectedKeyValues.includes(item)
        })
        const finalSelectedRowData = SelectedSetData.filter((item) => {
            return !deSelectedKeyValues.includes(item.skuId)
        })
        dispatch(SelectedSkuActions.setSelectedRowKeysData({ selectedRowKeysData: finalSelectedKeys }));
        dispatch(SelectedSkuActions.setSelectedSkuData({ selectedSkuData: finalSelectedRowData }));
        dispatch(SelectedSkuActions.setSelectedSkuCount({ selectedSkuCount: finalSelectedKeys.length}));
        
        props.callback(selectedRows);
    };

    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys,
        selectedRows,
        onChange: onSelectChange 
    };

    const onRowClick = (row) => {
        if(selectedRowKeys.includes(row.skuId)){
            const newRowKeys = selectedRowKeys.filter((item) => item!==row.skuId)
            const newRowData = selectedRows.filter((item) => item.skuId!==row.skuId)
            onSelectChange(newRowKeys,newRowData);
        }
        else{
            const newRowkeys = [...selectedRowKeys,row.skuId]
        const newRowData = [...selectedRows,row]
        onSelectChange(newRowkeys,newRowData);
        }
        
    };

    const hasSelected = selectedRowKeysList.length > 0;

    useEffect(() => {
        if(channelId){
            getAllSkuDataTrigger();
        }
    }, [pageindex, filters, pageSize, sortType, userType, channelId]); //eslint-disable-line

    
    
    //getting all sku Data
    const getAllSkuDataTrigger = async () => {
        try {
            setLoading(true);
            let skuData = [];
            const filter = `{
                data : {
                    class: "${filters.data.class}",
                    subclass: "${filters.data.subclass}",
                    brand: "${filters.data.brand}",
                    sku: "${filters.data.sku}",
                    publish: "${filters.data.publish}",
                    pricing_rule: "${filters.data.pricing_rule}",

                }
            }`;
            if (props.level === 'bulk config' && channelId) {
                if (userType === userTypes.MERCHANT) {
                    skuData = await getAllAssignSkuDataBulk(channelId, id, pageSize===MAX_SKU_LIMIT? 0 : pageindex - 1, pageSize, searchText, filter, sortType);
                } else if(userType === userTypes.ADMIN || userType === userTypes['PETCO-USER']) {
                    skuData = await getAllSkuDataBulk(channelId, pageSize===MAX_SKU_LIMIT? 0 : pageindex - 1, pageSize, searchText, filter, sortType);
                }

                if(skuData.data && skuData.data.length > 10){
                    const selectedThousandSkuKeys = skuData?.data.map(item => item.skuId) 
                    const selectThousandSkuData = skuData.data;
                    setSelectedRowKeys(selectedThousandSkuKeys);
                    setSelectedRows(selectThousandSkuData);
                    dispatch(SelectedSkuActions.setSelectedRowKeysData({ selectedRowKeysData: selectedThousandSkuKeys }));
                    dispatch(SelectedSkuActions.setSelectedSkuData({ selectedSkuData: selectThousandSkuData }));
                    dispatch(SelectedSkuActions.setSelectedSkuCount({ selectedSkuCount: selectedThousandSkuKeys.length}));
                }
                setButtonCount(skuData.dataCount);
            }

            dispatch(SkuActions.setSkuData({ skuData: skuData.data }));
            dispatch(AllCountActions.setAllPublishedSkuCountData({publishedCount: skuData.dataCount}))
            setRowCount(skuData.dataCount);
            setLoading(false);
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting sku Data. Please try again later.'
            );
            setLoading(false);
        }
    };

    const handlePageChange = (event) => {
        setSelectedRowKeys(selectedRowKeysList);
        setSelectedRows(selectedSkuList);
        setPageindex(event);
    };
    
    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
        setSelectedRowKeys(selectedRowKeysList);
        setSelectedRows(selectedSkuList)
    };

    const handleSelectFirstThousandSkus = () => {
        setPageSize(MAX_SKU_LIMIT)
        
        // setLoading(false)
    }

    const onTabChange = (key) => {
        setTabindex(key);
    };

    //clearing all filters
    const clearAllFilters = () => {
        setShowAppliedFilters([]);
        setFilters(initialFiltersValue);
        //setReload(!reload);
        setFilterTags([]);
        setPageSize(10);
        dispatch(SelectedSkuActions.setSelectedSkuData({ selectedSkuData: [] }));
        dispatch(SelectedSkuActions.setSelectedRowKeysData({ selectedRowKeysData: [] }));
        dispatch(SelectedSkuActions.setSelectedSkuCount({ selectedSkuCount: 0 }));
        setButtonCount(publishedSkuCount);
    };
    const closedTag = (removedTag) => {
        const remainingFilters = filterTags.filter((item) => item.value.id!==removedTag.value.id);
        setFilterTags(remainingFilters);
    };
    const removeFilterTags = (removedTag) => {
        const remainingFilterTags = showAppliedFilters.filter(
            (item) => item.value !== removedTag.value
        );
        setFilterTags(remainingFilterTags)
        handleApplyFilter(remainingFilterTags);
    };

    //drawer open and close functions

    const showDrawer = () => {
        setOpenFilterDrawer(true);
    };

    // Recursive function that cycles through the filter tags. 
    // Removes the ones that are not applied until they are all gone
    const removeNotAppliedFilters = async (ref, filters) => {
        try {
            for (const tag of filters) { 
                if (!ref[tag.value.level]) { // checks if the level exists in ref, removes the tag if it doesn't.
                    filters.pop(tag)
                    removeNotAppliedFilters(ref, filters)
                } else if (!ref[tag.value.level][tag.value.id]) { // checks to see if the id exists in ref, removes the tage if it doesn't.
                    filters.pop(tag)
                    removeNotAppliedFilters(ref, filters)
                } 
            }
        } catch (err) {
            console.log('ERROR:', err)
            throw err
        }
    }
    const onClose = () => {
        setOpenFilterDrawer(false);
        let currFilters = Object.values(filters.data).every(el => el === ""); // checks for applied filters
        const map = {}; // Holds the existing 

        if (filterTags.length !== 0 && currFilters) { // if there are filters selected but no applied filters, reset filterTags
            setFilterTags([])
        } else {
            for (const level in filters.data) { // iterate over applied filters and throw them in a map to compare filterTags to
                if (filters.data[level]) {
                    const values = filters.data[level].split(',');
                    for (const id of values) { // iterate over the array generated and add it to the level in map
                        if (!map[level]) {
                            map[level]={}
                        }
                        map[level][id] = true;
                    }
                }
            }    
            removeNotAppliedFilters(map, filterTags) // call recursive function and pass through mao for reference and filterTags to iterate through
        }
    };

    // -----------------------------------------------------------------------
    const [showAppliedFilters, setShowAppliedFilters] = useState([]);
    const handleApplyFilter = (filterTags) => {
        setLoading(true);
        const class1 = filterTags
            .filter((item) => item.value.level === 'class')
            .map((item) => `${item.value.id}`)
            .join(',');
        const subClass1 = filterTags
            .filter((item) => item.value.level === 'subclass')
            .map((item) => `${item.value.id}`)
            .join(',');
        const brand1 = filterTags
            .filter((item) => item.value.level === 'brand')
            .map((item) => `${item.value.id}`)
            .join(',');
        // const sbu = filterTags.filter((item) => item.value.level==='sbu').map((item) => `'${item.value.id}'`)

        setFilters({
            data: {
                ...filters.data,
                class: class1,
                subclass: subClass1,
                brand: brand1
            }
        });
        setShowAppliedFilters(filterTags);
        setOpenFilterDrawer(false);
        //setReload(!reload);
        setLoading(false);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        let sort = Object.keys(sorter).length === 0 || sorter.order === 'ascend' ? 'ASC' : 'DESC';
        setSortType(sort);
    };

    //Search when user press ENTER
    const onPressEnterKeyHandler = (event) => {
        try {
            if (event.key === 'Enter') {
                setPageindex(1);
                getAllSkuDataTrigger();
            }
        } catch (err) {
            console.log(err);
        }
    }

    const tabs = [
        {
            label: (
                <span style={{ display: 'flex', width: 60 }} id='bulk-config-class-table'>
                    <span style={{ color: tabindex === '1' ? '#001952' : '#96999F' }}>Class </span>
                    <Badge
                        className="class-count-badge"
                        count={allCountList?.classCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '1' ? '#001952' : '#EBF1FF',
                            color: tabindex === '1' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '1',
            children: <ClassTable {...newProps} updateTagValues = {updateTagValues} isTagChecked = {isTagChecked}/>
        },
        {
            label: (
                <span style={{ display: 'flex', width: 90 }} id='bulk-config-subClass-table'>
                    <span style={{ color: tabindex === '2' ? '#001952' : '#96999F' }}>
                        Subclass{' '}
                    </span>
                    <Badge
                        className="class-count-badge"
                        count={allCountList?.subclassCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '2' ? '#001952' : '#EBF1FF',
                            color: tabindex === '2' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '2',
            children: <SubclassTable {...newProps} updateTagValues = {updateTagValues} isTagChecked = {isTagChecked}/>
        },
        {
            label: (
                <span style={{ display: 'flex', width: 70 }} id='bulk-config-brands-table'>
                    <span style={{ color: tabindex === '3' ? '#001952' : '#96999F' }}>Brand </span>
                    <Badge
                        className="brands-count-badge"
                        count={allCountList?.brandCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '3' ? '#001952' : '#EBF1FF',
                            color: tabindex === '3' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '3',
            children: <BrandsTable {...newProps} updateTagValues = {updateTagValues} isTagChecked = {isTagChecked}/>
        }
    ];
    
    return (
        <div className="table-container">
            <div style={{ display: 'flex' }}>
            <div className="inlineContainer">
                <Input
                    className="data-search responsive-Search-Bar"
                    id='bulk-config-search'
                    onKeyDown={onPressEnterKeyHandler}
                    prefix={<SearchOutlined />}
                    onChange={handleSearchChange}
                    placeholder="Search for SKU"
                    suffix={<span className='press-enter-style'>Press enter to search</span>}
                />
                </div>
                <div className="filter-btn">
                    <Button type="primary" onClick={showDrawer} id='bulk-config-filter-btn'>
                        Filter <RightOutlined />
                    </Button>
                </div>
                <Drawer
                    title="Filter"
                    destroyOnClose={true}
                    placement="right"
                    onClose={onClose}
                    open={openFilterDrawer}
                    width={"clamp(580px, 100%, 800px)"}
                    footer={[]}>
                    <div>
                        Applied Filters:{' '}
                        {filterTags.map((item) => {
                            return (
                                <Tag
                                    key={`${item.value.id}+${item.value.desc}`}
                                    closable={true}
                                    style={{
                                        color: '#001952',
                                        backgroundColor: '#E9EAEC',
                                        borderRadius: '10px'
                                    }}
                                    onClose={() => closedTag(item)}>
                                    {item.value.desc}
                                </Tag>
                            );
                        })}{' '}
                        {filterTags.length===0?<></> : 
                        <p style={{display: 'inline-block',  color: '#001952', cursor: 'pointer'}} onClick={clearAllFilters}>
                            <span>
                                <u>Clear all</u>
                            </span>
                        </p>}
                    </div>
                    <Tabs
                        style={{
                            height: '520px'
                        }}
                        tabPosition="left"
                        animated
                        defaultActiveKey={tabindex}
                        items={tabs}
                        onChange={onTabChange}
                    />
                    <div className="filter-drawer-btns">
                        <Row>
                            <Col xs={14} sm={14} md={16}/>
                            <Col xs={5} sm={5} md={4}>
                                <div className="close-btn">
                                    <Button key="back" id='bulk-config-close' onClick={onClose}>
                                        Close
                                    </Button>
                                </div>
                            </Col>
                            <Col xs={2} sm={3}>
                                <div className="applyFilters-btn">
                                    <Button
                                        type="primary"
                                        id='bulk-config-apply'
                                        onClick={() => handleApplyFilter(filterTags)}>
                                        Apply Filter
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Drawer>
                <div
                    style={{
                        marginBottom: 16,
                        marginLeft: 16
                    }}>
                        <Button type="primary" onClick={handleSelectFirstThousandSkus} disabled={hasSelected} loading={loading}>
                            Select All{' '}<Badge showZero count={buttonCount} overflowCount={10000000} style={{backgroundColor: '#EBF1FF', color: '#001952', marginLeft: '1rem'}}/>
                        </Button>
                </div>
                <div
                    style={{
                        marginBottom: 16,
                        marginLeft: 16
                    }}>
                    <Button type="primary" id='bulk-config-clear-selection' onClick={clearSelection} disabled={!hasSelected}>
                        Clear Selection
                    </Button>
                    <span
                        style={{
                            marginLeft: 8
                        }}>
                        {`Selected ${selectedSkuCount} items`}
                    </span>
                </div>
            </div>
            {showAppliedFilters.length !== 0 ? (
                <div>
                    Applied Filters:{' '}
                    {showAppliedFilters.map((item) => {
                        return (
                            <Tag
                                key={`${item.value.id}+${item.value.desc}`}
                                closable={true}
                                style={{
                                    color: '#001952',
                                    backgroundColor: '#E9EAEC',
                                    borderRadius: '10px'
                                }}
                                onClose={() => removeFilterTags(item)}>
                                {item.value.level.toUpperCase()}: {item.value.desc}
                            </Tag>
                        );
                    })}{' '}
                    <p style={{display: 'inline-block',  color: '#001952', cursor: 'pointer'}} onClick={clearAllFilters}>
                        <span>
                            <u>Clear all</u>
                        </span>
                    </p>
                </div>
            ) : (
                <></>
            )}
            <Table
                rowKey="skuId"
                id="bulkConfig-table"
                rowSelection={rowSelection}
                onRow={(row, rowIndex) => {
                    return {
                        onClick: (event) => {
                            onRowClick(row);
                        }
                    };
                }}
                dataSource={skuList}
                columns={bulkAllSkuDataTableColumns}
                loading={loading}
                pagination={skuList?.length > 10?  {
                    position: ['','bottomCenter'],
                    showSizeChanger: false,
                } : false}
                scroll={{ x: 2000 }}
                size="middle"
                className="table-striped-rows clickable-table"
                onChange={handleTableChange}
            />
            {skuList?.length > 10? <></> : rowCount >10? (
                <Pagination
                    className="data-pagination"
                    id='bulk-config-filter'
                    defaultPageSize={10}
                    current={pageindex}
                    total={rowCount}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    size="small"
                />
            ) : (
                <></>
            )}
        </div>
    );
};

export default BulkConfigure;