import { gql } from "graphql-request";
import { prepareString } from "../../utilities";

//Get all Brands Data
export const createGetAllBrandsDataQuery = (
  channelId,
  index,
  size,
  search,
  sortType
) => {
  try {
    return gql`
            query {
                getAllBrands(index: ${index}, channelId:"${channelId}", size: ${size}, search: ${prepareString(
      search
    )}, sortType: "${sortType}") {
                    data {
                        brandId
                        brandDesc
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};

export const createGetBrandsDataByIdQuery = (brandId, brandDesc) => {
  try {
    return gql`
            query {
                getLevelDetails(level: "brand", levelId: "${brandId}", levelDesc: "${brandDesc}") {
                    levelId
                    levelDesc
                    skuCount
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};

export const createGetBrandAtClassDataQuery = (
  channelId,
  id,
  index,
  size,
  search,
  sortType
) => {
  try {
    return gql`
            query {
                getAllBrandsAtClass(channelId:"${channelId}", classId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(
      search
    )}, sortType: "${sortType}") {
                    data {
                        brandId
                        brandDesc
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};

export const createGetBrandAtSubclassDataQuery = (
  channelId,
  id,
  index,
  size,
  search,
  sortType
) => {
  try {
    return gql`
            query {
                getAllBrandsAtSubclass(channelId:"${channelId}", subclassId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(
      search
    )}, sortType: "${sortType}") {
                    data {
                        brandId
                        brandDesc
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};

export const createGetAllAssignBrandDataQuery = (
  channelId,
  merchantId,
  index,
  size,
  search,
  sortType
) => {
  try {
    return gql`
            query {
                getAllAssignBrands(channelId:"${channelId}", merchantId: "${merchantId}", index: ${index}, size: ${size}, search: ${prepareString(
      search
    )}, sortType: "${sortType}"){
                    data {
                        brandId
                        brandDesc
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};

export const createGetAssignBrandAtClassDataQuery = (
  merchantId,
  id,
  index,
  size,
  search,
  sortType
) => {
  try {
    return gql`
            query {
                getAllAssignBrandsAtClass(merchantId: "${merchantId}", classId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(
      search
    )}, sortType: "${sortType}") {
                    data {
                        brandId
                        brandDesc
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};

export const createGetAssignBrandAtSubclassDataQuery = (
  merchantId,
  id,
  index,
  size,
  search,
  sortType
) => {
  try {
    return gql`
            query {
                getAllAssignBrandsAtSubclass(merchantId: "${merchantId}", subclassId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(
      search
    )}, sortType: "${sortType}") {
                    data {
                        brandId
                        brandDesc
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};

//Get all Brands Data
export const getAllMerchantAssignedBrandQuery = (channelId, merchantId, searchedtext) => {
  try {
    return gql`
            query {
              getMerchantBrandsToAssignChannel(channelId:"${channelId}", merchantId:"${merchantId}", search: "${searchedtext}"){
                    data {
                        id
                        desc
                    }
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};