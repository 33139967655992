import { gql } from "graphql-request";
import { prepareString } from "../../utilities";

//Get all class Data
export const createGetAllClassDataQuery = (
  channelId,
  index,
  size,
  search,
  sortType
) => {
  try {
    return gql`
            query {
                getAllClasses(index: ${index}, channelId: "${channelId}", size: ${size}, search: ${prepareString(
      search
    )}, sortType: "${sortType}") {
                    data {
                        classId
                        classDesc
                        subclassCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};

export const createGetClassDetailsQuery = (classId, classDesc) => {
  try {
    return gql`
            query {
                getLevelDetails(level: "class", levelId: "${classId}", levelDesc: "${classDesc}") {
                    levelId
                    levelDesc
                    subclassCount
                    brandCount
                    skuCount
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};

export const createGetAllAssignClassDataQuery = (
  channelId,
  merchantId,
  index,
  size,
  search,
  sortType
) => {
  try {
    return gql`
            query {
                getAllAssignClasses(channelId: "${channelId}", merchantId: "${merchantId}", index: ${index}, size: ${size}, search: ${prepareString(
      search
    )}, sortType: "${sortType}") {
                    data {
                        classId
                        classDesc
                        subclassCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};

//Get all class Data at dept
export const createGetAssignClassAtDeptDataQuery = (
  merchantId,
  id,
  index,
  size,
  search,
  sortType
) => {
  try {
    return gql`
            query {
                getAllAssignClassesAtDept(merchantId: "${merchantId}", deptId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(
      search
    )}, sortType: "${sortType}"){
                    data {
                        classId
                        classDesc
                        subclassCount
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};

export const createGetChannelByIdQuery = (channelId) => {
  try {
    return gql`
    query{
      getChannelDetailsById(channelId: "${channelId}"){
        data{
          channelId
          channelName
          channelOwner
          defaultStatus
        }
      dataCount
      }
    }
        `;
  } catch (err) {
    console.log(err);
  }
};

export const getAllMerchantAssignedClassesQuery = (channelId, merchantId, searchedtext) => {
  try {
    return gql`
            query {
              getMerchantClassesToAssignChannel(channelId: "${channelId}", merchantId: "${merchantId}", search: "${searchedtext}") {
                    data {
                        id
                        desc
                    }
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};
