import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';

import './ResetPassword.scss';
import PetcoIcon from '../../assets/icons/bone-biscuits.png';
import { updatePassword } from '../../graphql/queryExecuters';
import { Notification } from '../../utilities';
import { validatePassword } from '../../utilities/validators.utils'
import { Input } from 'antd';

import * as DisplayStrings from '../../constants/displayStrings';

const ResetPassword = () => {

    let queryString = window.location.search;
    queryString = queryString.replace("?", '');

    const urlParams = new URLSearchParams(queryString);
    const key = urlParams.get('key');
    const type = urlParams.get('type');

    const navigate = useNavigate();

    //Helper for enable login button
    const [showLoginAction, setShowLoginAction] = useState(false);

    const [loading, setLoading] = useState(false);

    const [resetForm, setResetForm]=  useState({
        password: '',
        confirmPassword: ''
    });

    const onClickHandler = async () => {
        if (showLoginAction) {
            navigate('/login');
        } else {
            if (resetForm.confirmPassword.trim() && resetForm.password.trim()) {

                // Validate the password using the regEx pattern
                if (!validatePassword(resetForm.password.trim())) {
                    Notification('error', DisplayStrings.password_error);
                    return;
                }
                if (resetForm.password.trim() !== resetForm.confirmPassword.trim()) {
                    Notification('error', DisplayStrings.confirmation_password_error);
                    return;
                }
                if (!key) {
                    Notification('error', DisplayStrings.hampered_url_error);
                    return;
                }
        
                setLoading(true);
                const res = await updatePassword(key, resetForm.password.trim());
                if (res.__typename === 'Error' || res.message==='Link Expired') {
                    Notification('error', res.message);
                } else {
                    Notification('success', DisplayStrings.password_change_success);
                    setResetForm({
                        password: '',
                        confirmPassword: ''
                    });
                    setLoading(false);
                    setShowLoginAction(true);
                }
            } else {
                Notification('warning', DisplayStrings.password_error);
            }
    
            setLoading(false);
        }
    };

    const onChangeValueHandler = (field, value) => {
        setResetForm(prevState => {
            const state = {...prevState};
            state[field] = value;
            return state;
        });
    }

    return (
        <Spin spinning={loading}>
            <div className="reset-password-page-container">
                <div className="frame-75">
                    <img alt="Petco Icon" className="group-1" src={PetcoIcon} />
                    {!showLoginAction && <>
                        <span className="login">{type === 'reset' ? 'Reset Password' : 'Create Password'}</span>
                        <Input.Password
                            className="frame-2"
                            type="password"
                            placeholder="Password"
                            onChange={(e) =>
                                onChangeValueHandler(
                                    'password',
                                    e.target.value
                                )
                            }
                        />
                         <Input.Password
                            className="frame-3"
                            type="password"
                            placeholder="Confirm Password"
                            onChange={(e) =>
                                onChangeValueHandler(
                                    'confirmPassword',
                                    e.target.value
                                )
                            }
                        />
                    </>}
                    {showLoginAction && <p className='reset-password-success-message'>{type === 'reset' ? DisplayStrings.password_reset_success : DisplayStrings.password_create_success}</p>}
                    <button className="frame-4" onClick={onClickHandler}>
                        <span className="login-1">{showLoginAction ? 'Move to Login Page' : type === 'reset' ? 'Reset Password' : 'Create Password'}</span>
                    </button>
                </div>
            </div>
        </Spin>
    );
};
export default ResetPassword;
