import React, { useEffect, useState } from 'react';
import { Table, Pagination, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Notification } from '../../utilities';
import { AlertsTableColumns } from '../../constants/tableColumns';
import { getlevelAlerts, getUserAlerts, getAllAlerts } from '../../graphql/queryExecuters';
import { getAllUsersBaseData } from '../../redux/actionCreators/auth.actions';

import './Tables.scss';

const AlertsTable = (props) => {
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(false);
    const [pageindex, setPageindex] = useState(searchParams.get('index') ? Number(searchParams.get('index')) : 1);
    const [rowCount, setRowCount] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [sortType, setSortType] = useState('DESC');
    const [searchText, setSearchText] = useState(searchParams.get('search') ? searchParams.get('search') : '');
    const [user, setUser] = useState('');
    const [userData, setUserData] = useState([{ value: 'all', label: 'All Users' }]);

    const { allUsersList } = useSelector(state => state.users);
    const channelId = useSelector((state) => state.channelData.channelId);

    useEffect(() => {
        getAlertsDataTrigger();
    }, [sortType, user, props?.reload]); //eslint-disable-line

    useEffect(() => {
        getAllUserDataTrigger();
    }, [allUsersList]); //eslint-disable-line

    useEffect(() => {
        checkPageIndexChange();
    }, [searchParams]); //eslint-disable-line

    const getAlertsDataTrigger = async (pageNumber = null) => {
        try {
            setLoading(true);
            let alertsData = [];
            const page = pageNumber || pageindex;

            if(props.level ==='sku'){
                alertsData = await getlevelAlerts(channelId, props.skuId, page - 1, 10, sortType);
            } else if(props.level === 'user'){
                alertsData = await getUserAlerts(channelId, props.userId, page - 1, 10, searchText, sortType);
            } else {
                alertsData = await getAllAlerts(channelId, user, page - 1, 10, searchText, sortType);
            }

            if (alertsData.dataCount < (page - 1) * 10) {
                setPageindex(1);
                if (props.level === 'all') {
                    searchParams.set('index', 1);
                    setSearchParams(searchParams);
                }
            }
            setTableData(alertsData.data);
            setRowCount(alertsData.dataCount);
            setLoading(false);
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting Alerts. Please try again later.'
            );
            setLoading(false);
        }
    };

    const getAllUserDataTrigger = async () => {
        try {
            if (allUsersList.length > 0) {
                setUserData([
                    { value: 'all', label: 'All Users' },
                    ...allUsersList.map((user) => ({ value: user.id, label: user.name }))
                ]);
            } else {
                dispatch(getAllUsersBaseData());
            }
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting user Data. Please try again later.'
            );
        }
    };

    const checkPageIndexChange = () => {
        const index = searchParams.get('index') || 1;
        if (index) {
            const indexToBeSwitch = Number(index);
            if (indexToBeSwitch !== pageindex) {
                setPageindex(indexToBeSwitch);
                getAlertsDataTrigger(indexToBeSwitch);
            }
        }
    }

    const handlePageChange = (event) => {
        setPageindex(event);
        if (props.level === 'all') {
            searchParams.set('index', event);
            setSearchParams(searchParams);
        }
        getAlertsDataTrigger(event);
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        let sort = sorter.order === 'ascend' ? 'ASC' : sorter.order === 'descend' ? 'DESC' : '';
        setSortType(sort);
    };

    //Search when user press ENTER
    const onPressEnterKeyHandler = (event) => {
        try {
            if (event.key === 'Enter') {
                getAlertsDataTrigger();
                if (props.level === 'all') {
                    searchParams.set('search', searchText);
                    setSearchParams(searchParams);
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="table-container">
            <div className="inlineContainer">
            {props.level !== 'sku' && <Input
                    className="data-search"
                    id="alerts-search"
                    value={searchText}
                    prefix={<SearchOutlined />}
                    onKeyDown={onPressEnterKeyHandler}
                    onChange={handleSearchChange}
                    placeholder="Search for SKU"
                    suffix={<span className='press-enter-style'>Press enter to search</span>}
                    disabled={loading}
                />}
            {props.level !== 'user' && <Select
                    className="user-filter-dropdown"
                    id='alerts-users'
                    onChange={(value) => {
                        value === 'all' ? setUser('') : setUser(value);
                    }}
                    defaultValue={'all'}
                    size="large"
                    options={userData}
                />}
            </div>
            <Table 
                rowKey="id"
                id="alerts-table"
                columns={AlertsTableColumns} 
                dataSource={tableData}
                loading={loading}
                pagination={false}
                size="middle"
                className="table-striped-rows clickable-table"
                scroll={{ x: 1000 }}
                onChange={handleTableChange}
            />
            {rowCount > 10 ? <Pagination
                    current={pageindex}
                    id='alerts-filter'
                    className="data-pagination"
                    defaultCurrent={pageindex}
                    total={rowCount}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    size="small"
            />: <></>}
        </div>
    );
};

export default AlertsTable;
