import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Line } from '@ant-design/plots';
import { getSKUGraphData } from '../../graphql/queryExecuters';
import { Select } from 'antd';
import { Notification } from '../../utilities';
import { corpLabel } from '../../constants/common';
import "./SKUGraph.scss"

const SKUGraph = (props) => {
    const [graphLoader, setGraphLoader] = useState(false);
    const [data, setGraphData] = useState([]);
    const [filter, setFilter] = useState('');
    const [sku, setSku] = useState('');
    // const sku = props && props.skuId ? props.skuId : '';

    const channelId = useSelector((state) => state.channelData.channelId);

    useEffect(() => {
        getGraphDataTrigger();
    }, [filter, sku]); //eslint-disable-line

    useEffect(() => {
        setGraphTrigger();
    }, []); //eslint-disable-line

    const getGraphDataTrigger = async () => {
        try {
            setGraphLoader(true);
            if(sku.length > 0){
                let graphdata = await getSKUGraphData(sku, filter);
                if(graphdata && graphdata.data){
                    let data= graphdata.data.map((item) => (
                        {
                            competitor: corpLabel[item.competitor],
                            dayId: item.dayId,
                            price: item.price
                        }
                    ));
                    setGraphData(data);
                }
            }
            setGraphLoader(false)
        } catch (err) {
            setGraphLoader(false)
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting graph Data. Please try again later.'
            );
        }
    };

    const setGraphTrigger = async() => {
        if(props && props.skuId && props.skuId !== sku){
            setSku(props.skuId);
        }
    }

    const config = {
        data,
        xField: 'dayId',
        yField: 'price',
        seriesField: 'competitor',
        xAxis: {
            type: 'time',
        },
        yAxis: {
            label: {
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
    };

    return (
            <div>
                <div className='graph-filters-container'>
                    <h3>Current & Competitors Lowest Historical Price</h3>
                    <Select
                        className="graph-dropdown"
                        onChange={(value) => {
                            setFilter(value);
                        }}
                        defaultValue={'week'}
                        size="large"
                        options={[
                            { value: 'week', label: 'Last Week' },
                            { value: 'half_month', label: 'last 15 days'},
                            { value: 'month', label: 'last Month'}
                        ]}
                        disabled={graphLoader}
                    />
                </div>
                <Line {...config} loading={graphLoader}/>
            </div>        
        );
};

export default SKUGraph;
