import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const updateMerchantAssignlevelConfigData = async (channelId, merchantid,levelsdata) => {
    try {
        const query = QueryCreators.createUpdateAssignMerchantLevelConfigQuery(channelId, merchantid,levelsdata);
        const response = await GraphQL.request(query);
        return response.createAssignment;
    } catch (err) {
        console.log(err);
    }
};

export const deleteMerchantAssignlevelConfigData = async (channelId, merchantId, levelsdata) => {
    try {
        const query = QueryCreators.deleteMerchantLevelConfigQuery(channelId, merchantId, levelsdata);
        const response = await GraphQL.request(query);
        return response;
    } catch (err) {
        console.log(err);
    }
};

export const checkMerchantAccess = async (channelId, merchantId, levelType, levelId, levelDesc) => {
    try {
        const query = QueryCreators.createCheckMerchantAccessQuery(channelId, merchantId, levelType, levelId, levelDesc);
        const response = await GraphQL.request(query);
        return response.checkMerchantAccess;
    } catch (err) {
        console.log(err);
    }
}

export const channelSkuAssigment = async (channelId, levelsdata) => {
    try {
        const query = QueryCreators.channelSkuAssigmentQuery(channelId, levelsdata);
        const response = await GraphQL.request(query);
        return response.createChannelSkuAssignment;
    } catch (err) {
        console.log(err);
    }
};

export const deleteSkuAssigment = async (channelId, levelsdata) => {
    try {
        const query = QueryCreators.deleteChannelAssigmentSkuQuery(channelId, levelsdata);
        const response = await GraphQL.request(query);
        return response.deleteChannelSkuAssignment;
    } catch (err) {
        console.log(err);
    }
};

