import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Notification } from '../../utilities';
import { Table, Pagination, Select } from 'antd';

import { ScraperDataTableColumns } from '../../constants/tableColumns';
import { ScrapersActions } from '../../redux/slices/scrapers.slice';
import { CompetitorsActions } from '../../redux/slices/competitors.slice';
import {
    getSKUScraperQuery,
    getAllCompetitors, 
    getAllScrapers
} from '../../graphql/queryExecuters';
import "./Tables.scss"

const SkuScraperTable = (props) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [pageindex, setPageindex] = useState(1);
    const [sortType, setSortType] = useState('ASC');
    const [filters, setFilter] = useState({
        data: {
            scraper: "",
            competitor: "",
        }
    });
    const [tableData, setTableData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [scraperData, setScraperData] = useState([{ value: 'all', label: 'All Scrapers' }]);
    const [compData, setCompData] = useState([{ value: 'all', label: 'All Competitors' }]);
    const { competitors } = useSelector((state)=> state.competitors);
    const { activeScrapers } = useSelector((state) => state.scrapers);
    const channelId = useSelector((state) => state.channelData.channelId);

    useEffect(() => {
        getAllSkuScraperDataTrigger();
    }, [pageindex, filters, sortType, props.count]); //eslint-disable-line

    //getting all sku Data
    const getAllSkuScraperDataTrigger = async () => {
        try {
            setLoading(true);
            const filter = `{
                data : {
                    scraper: "${filters.data.scraper}",
                    competitor: "${filters.data.competitor}"
                }
            }`
            let skuData = await getSKUScraperQuery(channelId, props.id, pageindex - 1, 10, filter);
            if (competitors?.length === 0) {
                const competitorsData = await getAllCompetitors();
                if (competitorsData) {
                    dispatch(CompetitorsActions.setCompetitors({ competitors: competitorsData.data }));
                }
            }
            if (activeScrapers.length === 0) {
                const scrapersData = await getAllScrapers();
                const activeScraper = scrapersData.data.filter((scrap) => scrap.status);
                if (activeScraper) {
                    dispatch(ScrapersActions.setActiveScrapers({ activeScrapers: activeScraper }));
                }
            }
            setTableData(skuData.data);
            setRowCount(skuData.dataCount)
            setScraperData(
                [{ value: 'all', label: 'All Scrapers' }, 
                ...activeScrapers.map((item) => ({ value: item.id, label: item.name}))]
            );
            setCompData(
                [{ value: 'all', label: 'All Competitors' }, 
                ...competitors.map((item) => ({ value: item.compId, label: item.name}))]
            );
            setLoading(false);
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting sku Data. Please try again later.'
            );
            setLoading(false);
        }
    };

    const handlePageChange = (event) => {
        setPageindex(event);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        let sort = sorter.order === 'ascend' ? 'ASC' : 'DESC';
        setSortType(sort);
    };
    
    return (
        <div className="table-container">
            <div className="inlineContainer">
            <Select
                className="table-dropdown"
                id='table-dropdown'
                onChange={(value) => {
                    setFilter({
                        data: {
                            scraper: value === 'all' ? '' : value,
                            competitor: filters.data.competitor                            
                        }
                    });
                }}
                defaultValue={'all'}
                size="large"
                options={scraperData}
            />
            <Select
                className="table-dropdown"
                id='table-select-dropdown'
                onChange={(value) => {
                    setFilter({
                        data: {
                            scraper: filters.data.scraper,
                            competitor: value === 'all' ? '' : value
                        }
                    });
                }}
                defaultValue={'all'}
                size="large"
                options={compData}
            />        
            </div>
            <Table
                rowKey="id"
                id="skuScraper-table"
                dataSource={tableData}
                columns={ScraperDataTableColumns}
                loading={loading}
                pagination={false}
                scroll={{ x: 2000 }}
                size="middle"
                className="table-striped-rows"
                onChange={handleTableChange}
            />
            {rowCount > 10 ? <Pagination
                    className="data-pagination"
                    id='skuScrapper-filter'
                    defaultCurrent={pageindex}
                    total={rowCount}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    size="small"
            />: <></>}
        </div>
    );
};

export default SkuScraperTable;
