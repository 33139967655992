import { gql } from "graphql-request";
import { prepareString } from "../../utilities";

//Get all Subclass Data
export const createGetAllSubclassDataQuery = (
  channelId,
  index,
  size,
  search,
  sortType
) => {
  try {
    return gql`
            query {
                getAllSubclasses(index: ${index}, channelId: "${channelId}", size: ${size}, search: ${prepareString(
      search
    )}, sortType: "${sortType}") {
                    data {
                        subclassId
                        subclassDesc
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};

export const createGetSubclassDetailsQuery = (subclassId, subclassDesc) => {
  try {
    return gql`
            query {
                getLevelDetails(level: "subclass", levelId: "${subclassId}", levelDesc: "${subclassDesc}") {
                    levelId
                    levelDesc
                    brandCount
                    skuCount
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};

export const createGetSubclassAtClassDataQuery = (
  channelId,
  id,
  index,
  size,
  search,
  sortType,
) => {
  try {
    return gql`
            query {
                getAllSubclassesAtClass(channelId: "${channelId}", classId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(
      search
    )}, sortType: "${sortType}") {
                    data {
                        subclassId
                        subclassDesc
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};

export const createGetAllAssignSubClassDataQuery = (
  channelId,
  merchantId,
  index,
  size,
  search,
  sortType
) => {
  try {
    return gql`
            query {
                getAllAssignSubclasses(channelId:"${channelId}", merchantId: "${merchantId}", index: ${index}, size: ${size}, search: ${prepareString(
      search
    )}, sortType: "${sortType}"){
                    data {
                        subclassId
                        subclassDesc
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};

export const createGetAssignSubclassAtClassDataQuery = (
  merchantId,
  id,
  index,
  size,
  search,
  sortType
) => {
  try {
    return gql`
            query {
                getAllAssignSubclassesAtClass(merchantId: "${merchantId}", classId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(
      search
    )}, sortType: "${sortType}") {
                    data {
                        subclassId
                        subclassDesc
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};

export const getAllMerchantAssignedSubClassesQuery = (channelId, merchantId, searchedtext) => {
  try {
    return gql`
            query {
              getMerchantSubclassesToAssignChannel(channelId: "${channelId}", merchantId: "${merchantId}", search: "${searchedtext}") {
                    data {
                        id
                        desc
                    }
                }
            }
        `;
  } catch (err) {
    console.log(err);
  }
};