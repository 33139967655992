import React, { useState, useEffect } from 'react';
import { Button, Input } from 'antd';
import { Notification } from '../../utilities';
import { getApprovalPolicy, updateApprovalPolicies } from '../../graphql/queryExecuters';

const ApprovalPolicies = () => {
  const [acceptedPriceIncrease, setAcceptedPriceIncrease] = useState('');
  const [acceptedPriceDecrease, setAcceptedPriceDecrease] = useState('');
  const [acceptedPriceNegativeMargin, setacceptedPriceNegativeMargin] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getApprovalPolicyInitiator();
  }, []); // eslint-disable-line

  const getApprovalPolicyInitiator = async () => {
    try {
      const res = await getApprovalPolicy();
      if (res) {
        const { acceptedPriceIncrease, acceptedPriceDecrease, acceptedPriceNegativeMargin } = res;
        setAcceptedPriceIncrease(acceptedPriceIncrease.toString());
        setAcceptedPriceDecrease(acceptedPriceDecrease.toString());
        setacceptedPriceNegativeMargin(acceptedPriceNegativeMargin.toString());
      } else {
        Notification('error', 'Failed to fetch approval policy');
      }
    } catch (err) {
      console.error("Error fetching approval policy:", err);
      Notification('error', 'An error occurred while fetching approval policy');
    }
  };

  const handleInputChange = (setter) => (e) => {
    const value = e.target.value;
    // Allow empty value or integer values only
    if (value === '' || /^[0-9]+$/.test(value)) {
      if (parseInt(value, 10) <= 100 || value === '') {
        setter(value);
      } else {
        Notification('warning', 'Value should not be greater than 100');
      }
    } else {
      Notification('warning', 'Please Enter only Integer values');
    }
  };

  const handleSubmit = async () => {
    try{
       setLoading(true);
       // Convert values to integers before using them
        const intacceptedPriceIncrease = parseInt(acceptedPriceIncrease, 10);
        const intsetacceptedPriceDecrease = parseInt(acceptedPriceDecrease, 10);
        const intsetacceptedPriceNegativeMargin = parseInt(acceptedPriceNegativeMargin, 10);

        const response = await updateApprovalPolicies(intacceptedPriceIncrease, intsetacceptedPriceDecrease, intsetacceptedPriceNegativeMargin);

        if (response === "Approval Policy Updated Successfully") {
          Notification('success', "Approval Policy Updated Successfully.");
        } else {
          Notification('error', response);
        }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Error in Updating approval policy:", err);
    }
  };

  return (
    <div>
      <div style={{ marginTop: '24px' }}>Accepted Price Increase %</div>
      <Input
        className='input-global'
        id='acceptedPriceIncrease'
        value={acceptedPriceIncrease}
        onChange={handleInputChange(setAcceptedPriceIncrease)}
        style={{ width: '500px' }}
      />

      <div style={{ marginTop: '24px' }}>Accepted Price Decrease %</div>
      <Input
        className='input-global'
        id='acceptedPriceDecrease'
        value={acceptedPriceDecrease}
        onChange={handleInputChange(setAcceptedPriceDecrease)}
        style={{ width: '500px' }}
      />

      <div style={{ marginTop: '24px' }}>Accepted Price Negative Margin %</div>
      <Input
        className='input-global'
        id='acceptedPriceNegativeMargin'
        value={acceptedPriceNegativeMargin}
        onChange={handleInputChange(setacceptedPriceNegativeMargin)}
        style={{ width: '500px' }}
      />

      <Button type='primary' onClick={handleSubmit} style={{ marginTop: '24px' }} loading={loading}>
        Submit
      </Button>
    </div>
  );
};

export default ApprovalPolicies;
