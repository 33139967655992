import { gql } from 'graphql-request';
import { prepareString } from '../../utilities';

export const createGetLevelLogsCountQuery = (channelId, configid, configlevel) => {
    try {
        return gql`
            query {
                getLevelLogsCount(channelId: "${channelId}", levelId: "${configid}", levelName: "${configlevel}"){
                    logCount
                    approvalCount
                    priceLogCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetSKUScraperLogsCountQuery = (configid) => {
    try {
        return gql`
            query {
                getSkuScraperCount(levelId: "${configid}"){
                    scrapeCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetChildSKUCountQuery = (channelId, configid) => {
    try {
        return gql`
            query {
                getChildSkuCount(channelId: "${channelId}", levelId: "${configid}"){
                    childSkuCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetLevelLogsQuery = (channelId, configid, configlevel,index, size) => {
    try {
        return gql`
            query {
                getLogsByLevel(channelId: "${channelId}", levelId: "${configid}", levelName: "${configlevel}", index: ${index},size: ${size}){
                    dataCount
                    data{
                        id
                        eventLevelId
                        eventName 
                        eventType
                        createdBy
                        createdAt
                        eventData
                    }
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetEngineLogsQuery = (channelId, configid, configlevel,index, size) => {
    try {
        return gql`
            query {
                getLogsByReprice(channelId: "${channelId}", levelId: "${configid}", levelName: "${configlevel}", index: ${index},size: ${size}){
                    dataCount
                    data{
                        id
                        eventLevelId
                        eventName 
                        eventType
                        createdBy
                        createdAt
                        eventData
                    }
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetUserLogsCountQuery = (channelId, userid) => {
    try {
        return gql`
            query {
                getLogsByUser(channelId: "${channelId}", userId: "${userid}"){
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetUserLogsQuery = (channelId, userid, size, index, eventType, configType, searchText) => {
    try {
        return gql`
            query {
                getLogsByUser(channelId: "${channelId}", userId: "${userid}",eventType: "${eventType}", configType: "${configType}",index: ${index},size: ${size},search: ${prepareString(searchText)}){
                    dataCount
                    data{
                        eventLevelId
                        eventLevel
                        eventName
                        eventType
                        createdBy
                        createdAt
                        id
                        eventData
                    }
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetLogFiltersQuery = (channelId, userId) => {
    try {
        return gql`
        query {
            getLogFiltersByUser(channelId: "${channelId}", userId: "${userId}")
        }
        `;
    } catch (err) {
        console.log(err);
    }
};