import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { bulkSelectedSkuDataTableColumns } from '../../constants/tableColumns';

import './Tables.scss';
import { SelectedSkuActions } from '../../redux/slices/selectedSkus.slice';

const SelectedSku = (props) => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');

    const { selectedRowKeysList, selectedSkuList } = useSelector((state) => state.selectedSku);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState(selectedSkuList);
    const [dataSource, setDataSource] = useState(selectedRows);
    useEffect(() => {
        let newDataSource =
            searchText !== ''
                ? selectedSkuList.filter((item) => item.skuId.includes(searchText))
                : selectedSkuList;
        setDataSource(newDataSource);
    }, [selectedSkuList, selectedRowKeysList]); //eslint-disable-line
                                                //do not want this to run when searchText changes
    const clearSelection = () => {
        // clearing selection
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setDataSource([]);
        dispatch(SelectedSkuActions.setSelectedSkuData({ selectedSkuData: [] }));
        dispatch(SelectedSkuActions.setSelectedRowKeysData({ selectedRowKeysData: [] }));
        dispatch(SelectedSkuActions.setSelectedSkuCount({ selectedSkuCount: 0 }));
        props.removeAllCallback();
    };
    const clearSelectedSelection = () => {
        // clearing selection
        const _remainingData = dataSource.filter(function (el) {
            return !selectedRowKeys.includes(el.skuId);
        });
        setDataSource(_remainingData);

        let remainingSelectedSkuList = selectedSkuList.filter(
            (el) => !selectedRowKeys.includes(el.skuId)
        );

        dispatch(
            SelectedSkuActions.setSelectedSkuData({
                selectedSkuData: remainingSelectedSkuList
            })
        );

        dispatch(
            SelectedSkuActions.setSelectedRowKeysData({
                selectedRowKeysData: remainingSelectedSkuList.map((el) => el.skuId)
            })
        );
        dispatch(
            SelectedSkuActions.setSelectedSkuCount({
                selectedSkuCount: remainingSelectedSkuList.length
            })
        );
        setSelectedRowKeys([]);
        setSelectedRows([]);
    };

    useEffect(() => {
        setDataSource(selectedSkuList);
    }, [props.tabIndexChanged]); //eslint-disable-line

    const onSelectChange = async (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
    };

    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys,
        selectedRows,
        onChange: onSelectChange
    };

    const onRowClick = (row) => {
        if (selectedRowKeys.includes(row.skuId)) {
            const newRowKeys = selectedRowKeys.filter((item) => item !== row.skuId);
            const newRowData = selectedRows.filter((item) => item.skuId !== row.skuId);
            onSelectChange(newRowKeys, newRowData);
        } else {
            const newRowkeys = [...selectedRowKeys, row.skuId];
            const newRowData = [...selectedRows, row];
            onSelectChange(newRowkeys, newRowData);
        }
    };

    const hasSelected = selectedRowKeys.length > 0;

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    //Search when user press ENTER
    const onPressEnterKeyHandler = (event) => {
        try {
            if (event.key === 'Enter') {
                if (searchText === '') {
                    setDataSource(selectedSkuList);
                } else {
                    const searchResult = selectedSkuList.filter((item) =>
                        item.skuId.includes(searchText)
                    );
                    setDataSource(searchResult);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="table-container">
            <div className="inlineContainer">
                <div className="inlineContainer-item">
                    <Input
                        className="data-search responsive-Search-Bar"
                        id="selected-sku-search"
                        onKeyDown={onPressEnterKeyHandler}
                        prefix={<SearchOutlined />}
                        onChange={handleSearchChange}
                        placeholder="Search for SKU"
                        suffix={<span className="press-enter-style">Press enter to search</span>}
                    />
                </div>
                <div className="inlineContainer-item">
                    <Button
                        style={{}}
                        id="selected-sku-remove-btn"
                        type="primary"
                        onClick={clearSelectedSelection}
                        disabled={!hasSelected}>
                        Remove Selected
                    </Button>
                </div>
                <div className="inlineContainer-item">
                    {selectedRowKeysList.length > 0 ? (
                        <Button type="primary" id="selected-sku-clear-btn" onClick={clearSelection}>
                            Clear All
                        </Button>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <Table
                rowKey="skuId"
                id="selectedSku-table"
                rowSelection={rowSelection}
                onRow={(row, rowIndex) => {
                    return {
                        onClick: (event) => {
                            onRowClick(row);
                        }
                    };
                }}
                dataSource={dataSource}
                columns={bulkSelectedSkuDataTableColumns}
                pagination={
                    dataSource.length > 10
                        ? {
                              position: ['', 'bottomCenter'],
                              showSizeChanger: false,
                              defaultCurrent: 1
                          }
                        : false
                }
                scroll={{ x: 2000 }}
                size="middle"
                className="table-striped-rows clickable-table"
            />
        </div>
    );
};

export default SelectedSku;
