import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Notification, encodeString } from "../../utilities";
import { Table, Input, Pagination, Checkbox, Spin, Divider } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { subClassTableColumns } from "../../constants/tableColumns";
import {
  getAllSubclassData,
  getSubclassAtClassData,
  getAllAssignSubClassData,
  getAssignSubclassAtClassData,
} from "../../graphql/queryExecuters";
import { SubclassActions } from "../../redux/slices/subclass.slice";

const SubclassTable = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [filterParams, setFilterParams] = useSearchParams();
  const urlParamsObj = new URLSearchParams(location.search);
  const urlParams = urlParamsObj.toString();

  const [loading, setLoading] = useState(false);
  const [pageindex, setPageindex] = useState(
    filterParams.get("subIndex") ? parseInt(filterParams.get("subIndex")) : 1
  );
  const [sortType, setSortType] = useState("ASC");
  const [rowCount, setRowCount] = useState(props.count.subclassCount);
  const [tableData, setTableData] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [searchText, setSearchText] = useState(
    filterParams.get("subSearch") ? filterParams.get("subSearch") : ""
  );
  const [userId, setUserId] = useState("");

  const { userType, id } = useSelector((state) => state.auth);
  const { level, index, search, sort, subclassList, count } = useSelector(
    (state) => state.subclassData
  );
  const channelId = useSelector((state) => state.channelData.channelId);

  const getIndexParam = () => parseInt(filterParams.get("subIndex")) || 1;

  useEffect(() => {
    checkSearchTextChange();
  }, [filterParams]); //eslint-disable-line

  useEffect(() => {
    if (props?.level && userType) {
      getAllSubclassDataTrigger();
    }
  }, [
    pageindex,
    sortType,
    props.reload,
    props.level,
    userType,
    channelId,
  ]); //eslint-disable-line

  const onRowClick = (row) => {
    navigate(
      `/channels/${channelId}/sku_data/subclass/${row.subclassId}_${encodeString(row.subclassDesc)}`,
      { state: { searchParams: urlParams, row } }
    );
  };

  //getting all subclass Data
  const getAllSubclassDataTrigger = async (searchToApply = null) => {
    try {
      setLoading(true);
      const searchValue = searchToApply !== null ? searchToApply : searchText;
        let subclassData = [];
        if (props.level === "all" || props.level === "bulkConfig") {
          if (userType === "MERCHANT" && channelId) {
            subclassData = await getAllAssignSubClassData(
              channelId,
              id,
              getIndexParam() - 1,
              10,
              searchValue,
              sortType
            );
          } else {
            subclassData = await getAllSubclassData(
              channelId,
              getIndexParam() - 1,
              10,
              searchValue,
              sortType
            );
          }
          const value = subclassData.dataCount;
          onSearchInput(value);
        } else if (props.level === "class") {
          if (userType === "MERCHANT") {
            subclassData = await getAssignSubclassAtClassData(
              id, //merchant id
              props.id, // classId
              getIndexParam() - 1,
              10,
              searchValue,
              sortType
            );
          } else {
            subclassData = await getSubclassAtClassData(
              channelId,
              props.id, // classId
              getIndexParam() - 1,
              10,
              searchValue,
              sortType
            );
          }
          const value = subclassData.dataCount;
          onSearchInput(value);
        } else if (props.level === "merchant") {
          subclassData = await getAllAssignSubClassData(
            props.channelId,
            props?.merchantId,
            getIndexParam() - 1,
            10,
            searchValue,
            sortType
          );
          const value = subclassData.dataCount;
          onSearchInput(value);
        }
        dispatch(
          SubclassActions.setSubclassData({
            level: props.level,
            index: pageindex,
            search: searchValue,
            sort: sortType,
            subclassData: subclassData.data,
            count: subclassData.dataCount,
          })
        );
        setTableData(subclassData.data);
        setRowCount(subclassData.dataCount);
        setUserId(props.merchantId);
        props.setReload && props.setReload("subclass", false);
      setReloadData(props.reload);
      setLoading(false);
    } catch (err) {
      console.log(err);
      Notification(
        "error",
        "Something went wrong while getting sub class Data. Please try again later."
      );
      setLoading(false);
    }
  };

  const checkSearchTextChange = () => {
    const searchParamText = filterParams.get("subSearch") || "";
    if (searchText !== searchParamText) {
      setSearchText(searchParamText);
      getAllSubclassDataTrigger(searchParamText);
    }
  };

  const onSearchInput = (value) => {
    if (value < (getIndexParam() - 1) * 10) {
      filterParams.set("subIndex", 1);
      filterParams.set("subSearch", searchText);
      setFilterParams(filterParams);
    }
  };

  const handlePageChange = (event) => {
    filterParams.set("subIndex", event);
    setFilterParams(filterParams);
    setPageindex(event);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleCheckboxSelection = async (checkedValues) => {
    const status = {
      value: checkedValues.target.options[0].value,
      status: checkedValues.target.checked,
    };
    props.updateTagValues(status);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let sort = sorter.order === "ascend" ? "ASC" : "DESC";
    setSortType(sort);
  };

  //Search when user press ENTER
  const onPressEnterKeyHandler = (event) => {
    try {
      if (event.key === "Enter") {
        filterParams.set("subIndex", 1);
        filterParams.set("subSearch", event.target.value);
        setFilterParams(filterParams);
        setPageindex(1);
        getAllSubclassDataTrigger();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="table-container">
      <Input
        className="data-search responsive-Search-Bar"
        id="subclass-search"
        prefix={<SearchOutlined />}
        onKeyDown={onPressEnterKeyHandler}
        onChange={handleSearchChange}
        value={searchText}
        placeholder={searchText ? searchText : "Search for Subclass"}
        suffix={
          <span className="press-enter-style">Press enter to search</span>
        }
        disabled={loading}
      />
      {props.level === "bulkConfig" ? (
        <Spin spinning={loading}>
          {tableData.map((item) => {
            return (
              <li
                key={item.subclassId}
                style={{
                  color: "#001952",
                  font: "10px",
                  marginBottom: "10px",
                }}
              >
                {
                  <Checkbox
                    key={item.desc}
                    checked={props.isTagChecked(
                      item.subclassDesc,
                      item.subclassId,
                      "subclass"
                    )}
                    options={[
                      {
                        label: item.subclassDesc,
                        value: {
                          desc: item.subclassDesc,
                          id: item.subclassId,
                          level: "subclass",
                        },
                      },
                    ]}
                    onChange={handleCheckboxSelection}
                  >
                    {item.subclassDesc}
                  </Checkbox>
                }{" "}
                <Divider style={{ margin: "15px" }} />
              </li>
            );
          })}
        </Spin>
      ) : (
        <Table
          rowKey="subclassId"
          id="subclass-Table"
          dataSource={tableData}
          columns={subClassTableColumns}
          loading={loading}
          pagination={false}
          scroll={{ x: 2000 }}
          size="middle"
          onRow={(row, rowIndex) => {
            return {
              onClick: (event) => {
                props.level !== "merchant" && onRowClick(row);
              },
            };
          }}
          className="table-striped-rows clickable-table"
          rowSelection={
            props.level === "merchant" ? props?.rowSelection : false
          }
          onChange={handleTableChange}
        />
      )}
      {rowCount > 10 && !loading ? (
        <Pagination
          className="data-pagination"
          id="subclass-bulk-filter"
          defaultCurrent={getIndexParam()}
          total={rowCount}
          onChange={handlePageChange}
          showSizeChanger={false}
          size="small"
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SubclassTable;
