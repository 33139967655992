import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const getSkuApproval = async (channelId, skuId, index, size, user, sortType, filters, approvalStatus) => {
    try {
        const query = QueryCreators.createGetSKUApprovalQuery(channelId, skuId, index, size, user, sortType, filters, approvalStatus);
        const response = await GraphQL.request(query);
        return response.getPriceRequestDataBySkuId;
    } catch (err) {
        console.log(err);
    }
};

export const getLevelApproval = async (channelId, level_id, level, index, size, user, searchText = '', sortType, filters, approvalStatus) => {
    try {
        const query = QueryCreators.createGetLevelApprovalQuery(channelId, level_id, level, index, size, user, searchText, sortType, filters, approvalStatus);
        const response = await GraphQL.request(query);
        return response.getPriceRequestDataByLevelId;
    } catch (err) {
        console.log(err);
    }
};

export const getAllApproval = async (channelId, index, size, userId = '', searchText = '', sortType, filters, approvalStatus) => {
    try {
        const query = QueryCreators.createGetApprovalQuery(channelId, index, size, userId, searchText, sortType, filters, approvalStatus);
        const response = await GraphQL.request(query);
        return response.getPriceRequestData;
    } catch (err) {
        console.log(err);
    }
};

export const getAllApprovalsCount = async (userId = '') => {
    try {
        const query = QueryCreators.createGetApprovalsCountQuery(userId);
        const response = await GraphQL.request(query);
        return response.getPriceRequestData;
    } catch (err) {
        console.log(err);
    }
};

export const updateApprovalStatus = async (channelId, id, sku_id, status, code) => {
    try {
        const query = QueryCreators.createUpdateApprovalStatusQuery(channelId, id, sku_id, status, code);
        const response = await GraphQL.request(query);
        return response.updatePriceRequestStatus;
    } catch (err) {
        console.log(err);
    }
};

export const updateApprovalStatusBulk = async (selectedSkus, status, code) => {
    try {
      const query = await QueryCreators.createUpdateApprovalStatusQueryBulk(selectedSkus, status, code)
      const response = await GraphQL.request(query)
      return response.updatePriceRequestStatusBulk
    } catch (err) {
      console.error(err)
    }
}

export const getAllUsers = async () => {
    try {
        const query = QueryCreators.createGetAllUsersQuery();
        const response = await GraphQL.request(query);
        return response.getAllUsers;
    } catch (err) {
        console.log(err);
    }
};

export const getAllAcceptRejectReason = async (channelId) => {
    try {
        const query = QueryCreators.createGetAllApprovalRejectReason(channelId);
        const response = await GraphQL.request(query);
        return response.getAcceptRejectFilters;
    } catch (err) {
        console.log(err)
    }
}
