import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const getAllScrapers = async () => {
    try {
        const query = QueryCreators.createGetAllScrapersQuery();
        const response = await GraphQL.request(query);
        return response.getAllScrapers;
    } catch (err) {
        console.log(err);
    }
};

export const getScraperByName = async (scraperName) => {
    try {
        const query = QueryCreators.createGetScraperByNameQuery(scraperName);
        const response = await GraphQL.request(query);
        return response.getScraperByName;
    } catch (err) {
        console.log(err);
    }
};

export const getScraperSku = async (page, limit, searchText, competitors, stockType, period, sortType) => {
    try {
        const query = QueryCreators.createGetScraperSKUQuery(
            page,
            limit,
            searchText,
            competitors,
            stockType,
            period,
            sortType
        );
        const response = await GraphQL.request(query);
        return response.getSkusList;
    } catch (err) {
        console.log(err);
    }
};

export const updateScraperDetails = async (scraperName, fieldsToUpdate) => {
    try {
        const query = QueryCreators.createUpdateScraperQuery(scraperName, fieldsToUpdate);
        const response = await GraphQL.request(query);
        return response.updateScraperDetails;
    } catch (err) {
        console.log(err);
    }
};

export const updateScraperTrigger = async (configId, configdesc, configLevel, competitors, expiryHour) => {
    try {
        const query = QueryCreators.createUpdateScraperTriggerQuery(configId, configdesc, configLevel, competitors, expiryHour);
        const response = await GraphQL.request(query);
        return response.updateScraperTriggerConfig;
    } catch (err) {
        console.log(err);
    }
};

export const getSKUScraperQuery = async (channelId, sku_id, index, size, filter) => {
    try {
        const query = QueryCreators.createGetSKUScraperQuery(channelId, sku_id, index, size, filter);
        const response = await GraphQL.request(query);
        return response.getSkuScraperList;
    } catch (err) {
        console.log(err);
    }
};

export const getAllScrapersBaseData = async () => {
    try {
        const query = QueryCreators.createGetAllScrapersBaseDataQuery();
        const response = await GraphQL.request(query);
        return response.getScrapersBaseData;
    } catch (err) {
        console.log(err);
    }
};
