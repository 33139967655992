import { createSlice } from "@reduxjs/toolkit";
import { merchantAccessTypes } from "../../constants/common";

// // Helper function to get channelId from localStorage
// const getStoredChannelId = () => {
//   const storedChannelId = localStorage.getItem('selectedChannelId');
//   return storedChannelId ? parseInt(storedChannelId, 10) : null;

// };

const initialState = {
  id: null,
  name: "",
  email: "",
  userType: "",
  accessRole: "",
  createdBy: "",
  editAccess: "",
  accessToken: "",
  // channelDetails: "",
  // channelId: getStoredChannelId() || null, // Get from localStorage or default to null
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    //Setting Auth state
    setAuth(state, action) {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.userType = action.payload.userType;
      state.accessRole = action.payload.accessRole;
      state.createdBy = action.payload.createdBy;
      state.editAccess = action.payload.editAccess
        ? action.payload.editAccess
        : merchantAccessTypes[0].value;
      state.accessToken = action.payload.accessToken;
    //   state.channelDetails = action.payload.channelDetails;
    //    // Set channelId from the stored value or default channel
    //    const storedChannelId = getStoredChannelId();
    //    if (storedChannelId) {
    //      state.channelId = storedChannelId;
    //    } else {
    //      const defaultChannel = action.payload.channelDetails?.find(channel => channel.default_status);
    //      state.channelId = defaultChannel ? defaultChannel.channel_id : null;
    //    }
    },
    //Resetting state
    reset(state) {
      state = { ...initialState };
      return state;
    },
     // New reducer to update the channelId
     updateChannelId(state, action) {
      state.channelId = action.payload;
       // Store the selected channelId in localStorage
       localStorage.setItem('selectedChannelId', action.payload);
    },
  },
});

export const AuthActions = authSlice.actions;
export const AuthReducer = authSlice.reducer;
export default authSlice;

