import dayjs from "dayjs";
import ReduxStore from '../redux/store';
import { engine_trigger } from "../constants/displayStrings";
import {priceRuleDesc} from '../../src/constants/common'

let allUsersList = [];

ReduxStore.subscribe(() => {
    const userState = ReduxStore.getState().users;
    allUsersList = userState.allUsersList;
});

export const getCookie = (key) => {
    var b = document?.cookie?.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
}

//convert to user's timeZone
export const convertToUserTimeZone = (currentDate) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return dayjs.utc(currentDate).tz(userTimezone);
};

// convert to utc time to send to backend , Function to convert local time to UTC
const convertToLocalTimeToUTC = (currentDate) => {
    // Get user's current time zone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Convert the given local time to the user's current time zone, then to UTC
    return dayjs.tz(currentDate, userTimeZone).utc().format();
};

export const genericPSTConverter = (currentDate) => {
        const intlDateObj = new Intl.DateTimeFormat('en-US', {timeZone: "America/Los_Angeles"});
        const pstDate = intlDateObj.format(currentDate)
    return pstDate;
}

export const dateSkipper = (current, maxDays = null, dates = null) => {
    let tooLate = false, tooEarly = false;
    
    // Get the UTC offset for the user's time zone
    const utcOffsetUserTimeZone = dayjs().utcOffset() / 60;

    // Get the UTC offset for PST in Hours from user time zone
    const currentInUserTimeZone = dayjs.utc(current).tz(dayjs.tz.guess());
    
    // Check if current date is in the past based on the user's time zone
    const disablePast = currentInUserTimeZone && currentInUserTimeZone < dayjs().startOf('day');

    if (!dates) return disablePast;

    if (Array.isArray(dates)) {
        // If dates is an array, check for both too late and too early
        tooLate = dates[0] && currentInUserTimeZone.diff(dates[0], 'days') >= maxDays;
        tooEarly = dates[1] && dates[1].diff(currentInUserTimeZone, 'days') >= maxDays;
    } else {
        // If dates is not an array, it's a start date
        tooLate = currentInUserTimeZone.diff(dates, 'days') > maxDays;
    }

    return tooLate || tooEarly || disablePast
};

export const disableFuture = (current) => {
    const utcOffsetUserTimeZone = dayjs().utcOffset()/60;  // Get the UTC offset for the user's time zone

    // Check if current date is in the future based on the user's time zone
    const disabledFuture = current && current > dayjs().add(utcOffsetUserTimeZone, 'hour').endOf('day');
    return disabledFuture;
}

//Helper for tables
export const getUserToDisplay = userId => {
    let user = '-';
    if (userId) {
        if (userId === 'system'){
            return engine_trigger;
        }
        const userData = allUsersList.find(user => String(user.id) === String(userId));
        if (userData) {
            user = userData.name;
        }
    }
    return user;
}

export const swapKeyValue = (object) =>
  // take an object and swap the keys with the values, returning a new object
  // e.g. {"a" : 1, "b" : 2"} becomes {1: "a", 2: "b"}
  Object.entries(object).reduce((swapped, [key, value]) => (
    { ...swapped, [value]: key }
  ), {});

// Getting storage reference based on user's session
export const getStorage = () => {
    const storage = localStorage;
    return storage;
}

export const compareLog = (logKey, oldChanges, newChanges, level, id, desc, applyAll = false) => {
    let changeLog = {}
    if (logKey === 'default') {
        if (oldChanges && newChanges) {
            for (const key in newChanges) {
                if (newChanges.hasOwnProperty(key) && oldChanges.hasOwnProperty(key) && oldChanges[key] !== newChanges[key]) {
                    changeLog[key] = typeof (newChanges[key]) !== 'string' && typeof (newChanges[key]) !== 'boolean'
                    ? String(newChanges[key])
                    : key.toLowerCase().includes('date') && dayjs.tz(newChanges[key]).isValid()
                    ? dayjs.tz(newChanges[key]).format('YYYY/MM/DD')
                    : newChanges[key];
                }
            }
        }
        else {
            for (const key in newChanges) {
                changeLog[key] = typeof (newChanges[key]) !== 'string' && typeof (newChanges[key]) !== 'boolean'
                ? String(newChanges[key])
                : key.toLowerCase().includes('date') && dayjs.tz(newChanges[key]).isValid()
                ? dayjs.tz(newChanges[key]).format('YYYY/MM/DD')
                : newChanges[key];
            }

        }
    }

    if (logKey === 'scraper') {
        if (oldChanges.length > 0) {
            for (const obj of newChanges) {
                const index = obj.index;
                const oldIdx = oldChanges.find((item) => item.index === index);

                if (oldIdx) {
                    for (const key in obj) {
                        if (obj.hasOwnProperty(key) && oldIdx[key] !== obj[key]) {
                            changeLog[key] = String(obj[key]);
                        }
                    }
                }
            }
        }
        else {
            for (const obj of newChanges) {
                for (const key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        changeLog[key] = String(obj[key]);
                    }
                }
            }
        }
    }

    changeLog['overrideAllFlag'] = String(applyAll);
    changeLog['configLevel'] = String(level);
    changeLog['configID'] = String(id);
    changeLog['configDesc'] = String(desc);
    return JSON.stringify(changeLog)
}

//Preparing search text to be align with sql queries
//All kind of search text cooking should be there
export const prepareString = text => {
    const preparedText = JSON.stringify(text)
    return preparedText;
}

//Helper for encode string
export const encodeString = string => {
    const encodedString = window.encodeURIComponent(string);
    return encodedString;
}

//Helper for decode string
export const decodeString = encodedString => {
    const decodedString = window.decodeURIComponent(encodedString);
    return decodedString;
}

//Derive MSRP price based on configuration
export const getMsrpPriceByConfiguration = configuration => {
    const msrpPrice = configuration?.msrpStartDate && //Checking any Interval present for msrp price
        configuration?.msrpEndDate && 
        dayjs.tz().isBetween(dayjs.tz(configuration?.msrpStartDate).startOf('day'), dayjs.tz(configuration?.msrpEndDate).endOf('day'))? 
            configuration?.tempMsrpPrice || configuration?.listPrice : configuration?.listPrice || null //Setting price based on availability
        
    return msrpPrice;
}

//Derive MAP price based on configuration
export const getMapPriceByConfiguration = configuration => {
    const mapPrice = configuration?.mapStartDate && //Checking any Interval present for map price
        configuration?.mapEndDate && 
        dayjs.tz().isBetween(dayjs.tz(configuration?.mapStartDate).startOf('day'), dayjs.tz(configuration?.mapEndDate).endOf('day')) ? // Checking interval comes under current date
        configuration?.tempMapPrice || configuration?.mapPrice : configuration?.mapPrice || null //Setting price based on availability
        
    return mapPrice;
}

//Helper to calculate margin
export const calculateMargin = (price, margin = 0, fractionDigits = 2) => {
    try {
        if (typeof price === 'number') {
            const calculatedMargin = Number((price / (1 - 0.01 * margin)).toFixed(fractionDigits))
            return calculatedMargin;
        } else return 0;
    } catch (err) {
        console.log('Error - Helpers: Calculate margin - Error while calculating margin ', err);
        return null;
    }
}

//Capitalizing text data
export const capitalizeFirstLetter = data => {
    let text = data.toLowerCase();
    const convertedStrings = text.split(' ').map(word => {
        if (word === "map" || word === "msrp") {
            return word.toUpperCase();
        } else {
            return word.charAt(0).toUpperCase() + word.substring(1);
        }
    });
    return convertedStrings.join(' ');
}


// Helpers to Format Log Data 

function formatKey(key) {
    return key.replace(/([a-z])([A-Z])|_/g, '$1 $2') ;
}

function isJSONParsable(str, flag) {
    try {
        const eventData = str;
        let parsedData;
        if (flag === 0) {
            parsedData = JSON.parse(eventData)
        }
        else {

            const startIndex = eventData.indexOf('{');
            const endIndex = eventData.lastIndexOf('}');
            parsedData = JSON.parse(eventData.substring(startIndex, endIndex + 1));
        }
        return parsedData;
    } catch (error) {
        return false;
    }
}



export const formatLogData = (logData, level) => {
    try {
        for (const item in logData) {
            if (logData[item].eventName === "TEMPORARY_GUARDRAIL_CONFIGURATION_UPDATE" ||
                logData[item].eventName === "PRICE_CONFIGURATION_UPDATE" ||
                logData[item].eventName === "SCRAPER_CONFIGURATION_UPDATE" ||
                logData[item].eventName === "TEMPORARY_GUARDRAIL_CONFIGURATION_SUBLEVEL_UPDATE" ||
                logData[item].eventName === "PRICE_CONFIGURATION_SUBLEVEL_UPDATE" ||
                logData[item].eventName === "COMPETITOR_CONFIGURATION_SUBLEVEL_UPDATE" ||
                logData[item].eventName === "SCRAPER_CONFIGURATION_SUBLEVEL_UPDATE") {
                
                const parsedEventData = isJSONParsable(logData[item].eventData, 0);
                if (parsedEventData !== false) {
                    const parsedEventData = JSON.parse(logData[item].eventData)
                    let formattedLog = ''

                    for (const key in parsedEventData) {
                        if(parsedEventData[key] !== 'null' && parsedEventData[key] !== ''){
                            const formattedKey = formatKey(key)
                            if(key === 'priceRule')
                            {
                                parsedEventData[key] = priceRuleDesc[parsedEventData[key]]
                            }
                            formattedLog += `${capitalizeFirstLetter(formattedKey)} : ${parsedEventData[key]}, `
                        }
                    }
                    logData[item].eventData = formattedLog;
                }
            }

            if (logData[item].eventName === "BULK_SKU_UPDATE") {
                let formattedLog = '';
                if (logData[item]?.eventData?.length === 0){
                    logData[item].eventData = '---';
                }
                const parsedEventData = isJSONParsable(logData[item].eventData, 1);
                if (parsedEventData !== false) {
                    for (const key in parsedEventData) {
                        const nestedObj = parsedEventData[key];
                        if(level === 'user'){

                            formattedLog += `${key} = `;
                        }
                        else{
                            formattedLog = '';
                        }
                        for (const nestedKey in nestedObj) {
                            if(nestedObj[nestedKey] !== null && nestedObj[nestedKey] !== '' )
                            {
                                if(nestedKey === 'price_rule')
                                {
                                    nestedObj[nestedKey] = priceRuleDesc[nestedObj[nestedKey]]
                                }
                                const formattedKey = formatKey(nestedKey)
                                formattedLog += `  ${capitalizeFirstLetter(formattedKey)} : ${nestedObj[nestedKey]}, `;
                            }
                        }
                    }
                    logData[item].eventData = formattedLog;
                }
            }
        }

        return logData
    }
    catch (err) {

    }
}

export const getUrlParamString = (level, id, desc) => level === 'sku' ? id : `${id}_${encodeString(desc)}`