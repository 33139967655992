import { gql } from 'graphql-request';
import { prepareString } from '../../utilities';

export const createGetSKUApprovalQuery = (channelId, skuId, index, size, user = '', sortType = 'DESC', filters, approvalStatus = '') => {
    try {
        return gql`
            query {
                getPriceRequestDataBySkuId(channelId: "${channelId}", skuId: "${skuId}", index: ${index}, size: ${size}, userId: "${user}", sortType: "${sortType}", filter:${filters}, status: "${approvalStatus}"){
                    data {
                        id
                        skuId
                        price
                        priceReason
                        rejectCode
                        acceptCode
                        status
                        configData
                        createdBy
                        updatedBy
                        createdAt
                        updatedAt
                        isDiscarded
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetLevelApprovalQuery = (channelId, levelId, level, index, size, user = '', searchText = '', sortType = 'DESC', filters, approvalStatus = '') => {
    try {
        return gql`
            query {
                getPriceRequestDataByLevelId(channelId: "${channelId}", levelId: "${levelId}", level: "${level}", index: ${index}, size: ${size}, userId: "${user}", searchText: ${prepareString(searchText)}, sortType: "${sortType}", filter: ${filters}, status: "${approvalStatus}"){
                    data {
                        id
                        skuId
                        price
                        priceReason
                        rejectCode
                        acceptCode
                        status
                        configData
                        createdBy
                        updatedBy
                        createdAt
                        updatedAt
                        isDiscarded
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetApprovalQuery = (channelId, index, size, userId = '', searchText = '', sortType = 'DESC', filters, approvalStatus = '') => {
    try {
        return gql`
            query {
                getPriceRequestData(channelId: "${channelId}", index: ${index}, size: ${size}, userId: "${userId}", searchText: "${searchText}", sortType: "${sortType}", filter: ${filters}, status: "${approvalStatus}"){
                    data {
                        id
                        skuId
                        price
                        priceReason
                        rejectCode
                        acceptCode
                        status
                        configData
                        createdBy
                        updatedBy
                        createdAt
                        updatedAt
                        isDiscarded
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetApprovalsCountQuery = (userId = '') => {
    try {
        return gql`
            query {
                getPriceRequestData(index: ${0}, size: ${100000000}, userId: "${userId}"){
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createUpdateApprovalStatusQuery = (channelId, approval_id, sku_id, status, code) => {
    try {
        return gql`
            mutation {
                updatePriceRequestStatus(
                    channelId: "${channelId}",
                    id: "${approval_id}",
                    skuId: "${sku_id}",
                    status: "${status}",
                    code: "${code}"
                )
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createUpdateApprovalStatusQueryBulk = (selectedApprovals, status, code) => {
    let selected = selectedApprovals.map((approval) => `{id: "${approval.id}", sku_id: ${approval.skuId}}`).join(', ')
  try {
      return gql`
            mutation {
                updatePriceRequestStatusBulk(
                    selectedApprovals: [${selected}]
                    status: "${status}",
                    code: "${code}"
                )
            }
        `;
  } catch (err) {
      console.log(err);
  }
};


export const createGetAllUsersQuery = () => {
    try {
        return gql`
            query {
                getAllUsers {
                    id
                    name
                    email
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAllApprovalRejectReason = (channelId) => {
    try {
        return gql`
            query {
                getAcceptRejectFilters(channelId: "${channelId}") {
                    rejectCodes,
                    acceptCodes,
                    priceReasons,
            }
        }`
    } catch (err) {
        console.log(err)
    }
}
