import { Select } from "antd";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

import StackIcon from "../assets/icons/svg/StackIcon.svg";
import CompTableReScrapeModal from "../components/Modals/CompTableReScrapeModal";
import ApprovalStatusModal from "../components/Modals//ApprovalStatusModal";
import {
  corpLabel,
  priceRuleDesc,
  oos_status,
  inviteStatus,
  merchantAccessDisplayTypes,
} from "../constants/common";
import UserStateTrigger from "../components/UserStateTrigger/UserStateTrigger";
import {
  capitalizeFirstLetter,
  encodeString,
  getUserToDisplay,
  convertToUserTimeZone
} from "../utilities";
import {
  competitor_product_link,
  created_at,
  created_by,
  description,
  petco_product_link,
} from "./displayStrings";
import ChannelActions from "../components/ChannelActions/ChannelActions";

export const ScrapperStackIcon = ({ style }) => (
  <img alt="Stack Icon" style={style} className="anticon" src={StackIcon} />
);

const calculateCompetitors = (_, { activeComp }) => {
  const compList = activeComp ? activeComp.split(",") : [];
  if (compList.length === 0) {
    return <div>{`-`}</div>;
  }
  if (compList.length === 1) {
    return <div>{`${corpLabel[compList[0]]}`}</div>;
  } else {
    return <div>{`${corpLabel[compList[0]]}, +${compList.length - 1}`}</div>;
  }
};

const renderGuardrailMin = (record) => {
  let levelCheck;
  let priceToConsider =
    record["defaultGuardMin"] === "MSRP"
      ? record["listPrice"]
      : record["mapPrice"];

  if (record["defaultGuardMin"] === "MSRP") {
    levelCheck = record.hasOwnProperty("skuId")
      ? record["listPrice"] !== undefined && record["listPrice"] !== null
      : record["defaultGuardMinPrice"] !== undefined &&
        record["defaultGuardMinPrice"] !== null &&
        record["defaultGuardMinPriceSuffix"];
  } else {
    levelCheck = record.hasOwnProperty("skuId")
      ? record["mapPrice"] !== undefined && record["mapPrice"] !== null
      : record["defaultGuardMinPrice"] !== undefined &&
        record["defaultGuardMinPrice"] !== null &&
        record["defaultGuardMinPriceSuffix"];
  }

  return (
    <>
      {record["defaultGuardMin"] ? record["defaultGuardMin"] : "MAP"}
      {record["defaultGuardMin"]
        ? record["defaultGuardMin"] === "Margin"
          ? ` ${record["defaultGuardMinPrice"]}%`
          : record["defaultGuardMin"] === "Fixed" &&
            record["defaultGuardMinPrice"] !== null
          ? ` $${record["defaultGuardMinPrice"]}`
          : levelCheck
          ? ` ${
              record["defaultGuardMinPriceSuffix"] === "$" || "None" ? "$" : ""
            }${priceToConsider}`
          : " "
        : " "}
    </>
  );
};

const renderGuardrailMax = (record) => {
  let levelCheck;
  let priceToConsider =
    record["defaultGuardMax"] === "MSRP"
      ? record["listPrice"]
      : record["mapPrice"];
  if (record["defaultGuardMax"] === "MSRP") {
    levelCheck = record.hasOwnProperty("skuId")
      ? record["listPrice"] !== undefined && record["listPrice"] !== null
      : record["defaultGuardMaxPrice"] !== undefined &&
        record["defaultGuardMaxPrice"] !== null &&
        record["defaultGuardMaxPriceSuffix"];
  } else {
    levelCheck = record.hasOwnProperty("skuId")
      ? record["mapPrice"] !== undefined && record["mapPrice"] !== null
      : record["defaultGuardMaxPrice"] !== undefined &&
        record["defaultGuardMaxPrice"] !== null &&
        record["defaultGuardMaxPriceSuffix"];
  }

  return (
    <>
      {record["defaultGuardMax"] ? record["defaultGuardMax"] : "MSRP"}
      {record["defaultGuardMax"]
        ? record["defaultGuardMax"] === "Margin"
          ? ` ${record["defaultGuardMaxPrice"]}%`
          : record["defaultGuardMax"] === "Fixed" &&
            record["defaultGuardMaxPrice"] !== null
          ? ` $${record["defaultGuardMaxPrice"]}`
          : levelCheck
          ? ` ${
              record["defaultGuardMaxPriceSuffix"] === "$" || "None" ? "$" : ""
            }${priceToConsider}`
          : " "
        : " "}
    </>
  );
};

export const competitorsTableColumns = [
  {
    title: "Competitors",
    dataIndex: "name",
    key: "name",
    fixed: "left",
    width: 100,
    sorter: true,
  },
  {
    title: "Matched SKUs",
    dataIndex: "matchedSkus",
    key: "matchedSkus",
    width: 100,
  },
  {
    title: "Map SKUs",
    dataIndex: "mapSkus",
    key: "mapSkus",
    width: 100,
  },
  {
    title: "Break Map SKUs",
    dataIndex: "breakMapSkus",
    key: "breakMapSkus",
    width: 100,
    render: (_, { breakMapSkus }) => <div>{`$${breakMapSkus}`}</div>,
  },
  {
    title: "% of SKUs Breaking MAP",
    dataIndex: "skuBreakingMap",
    key: "skuBreakingMap",
    width: 100,
    render: (_, { skuBreakingMap }) => <div>{`$${skuBreakingMap}`}</div>,
  },
  {
    title: "Avg price % below MAP",
    dataIndex: "averagePriceBelowMap",
    key: "averagePriceBelowMap",
    width: 100,
    render: (_, { averagePriceBelowMap }) => (
      <div>{`$${averagePriceBelowMap.toFixed(2)}`}</div>
    ),
  },
];

export const competitorsDetailedTableColumns = [
  {
    title: "Competitors",
    dataIndex: "name",
    key: "name",
    fixed: "left",
    width: 100,
    sorter: (a, b) => a.name > b.name,
  },
  {
    title: "Last Scraped Date",
    dataIndex: "lastScrapedDate",
    key: "lastScrapedDate",
    width: 100,
    render: (_, { lastScrapedDate }) =>
      lastScrapedDate ? (
        <div>{convertToUserTimeZone(lastScrapedDate).format("MM/DD/YYYY HH:mm:ss")}</div>
      ) : (
        "---"
      ),
  },
  {
    title: "SKUs",
    dataIndex: "skuScraped",
    key: "skuScraped",
    width: 100,
    render: (_, { skuScraped }) => (
      <div>{`${skuScraped ? skuScraped : 0}`}</div>
    ),
  },
  {
    title: "Scrapers",
    dataIndex: "scrapers",
    key: "scrapers",
    width: 100,
    render: (_, { scrapers }) => {
      const scrapersList = scrapers.split(",");
      if (scrapersList.length === 0) {
        return <div>{`-`}</div>;
      }
      if (scrapersList.length === 1) {
        return <div>{scrapersList.join()}</div>;
      } else {
        return <div>{`${scrapersList[0]}, +${scrapersList.length - 1}`}</div>;
      }
    },
  },
  {
    title: "Value Added",
    dataIndex: "valueAdder",
    key: "valueAdder",
    width: 100,
    render: (_, { valueAdder }) => (
      <div>{`$ ${valueAdder ? valueAdder.toFixed(2) : 0}`}</div>
    ),
  },
  {
    title: "Value Subtracted",
    dataIndex: "valueSubtracted",
    key: "valueSubtracted",
    width: 100,
    render: (_, { valueSubtracted }) => (
      <div>{`$ ${valueSubtracted ? valueSubtracted.toFixed(2) : 0}`}</div>
    ),
  },
  {
    title: "% Added",
    dataIndex: "perValueAdder",
    key: "perValueAdder",
    width: 100,
    render: (_, { perValueAdder }) => (
      <div>{`${perValueAdder ? perValueAdder.toFixed(2) : 0} %`}</div>
    ),
  },
  {
    title: "% Subtracted",
    dataIndex: "perValueSubtracted",
    key: "perValueSubtracted",
    width: 100,
    render: (_, { perValueSubtracted }) => (
      <div>{`${perValueSubtracted ? perValueSubtracted.toFixed(2) : 0} %`}</div>
    ),
  },
];

export const channelTableColumns = (userType, getChannelsListInitiator) => [
  {
    title: "Channel Id",
    dataIndex: "channelId",
    key: "channelId",
    fixed: "left",
    width: 20,
    //sorter: true,
    ellipsis: true,
    //defaultSortOrder: "ascend",
  },
  {
    title: "Channel Name",
    dataIndex: "channelName",
    key: "channelName",
    width: 50,
    ellipsis: true,
  },
  {
    title: "Channel Admin",
    dataIndex: "channelOwner",
    key: "channelOwner",
    width: 50,
  },
  {
    title: "Default Status",
    dataIndex: "defaultStatus",
    key: "defaultStatus",
    width: 50,
    render: (text) => (
      typeof text === 'string' && text.length > 0 
        ? text.charAt(0).toUpperCase() + text.slice(1) 
        : text
    )
  },
  {
    title: "Actions",
    // width: userType === "MERCHANT" ? 6 : 12,
    width: 12,
    fixed: "right",
    render: (isActive, row) => <ChannelActions channelData={row} getChannelsListInitiator={getChannelsListInitiator}/>,
    onCell: () => ({
      onClick: (event) => {
        if (event.stopPropagation) event.stopPropagation();
      },
    }),
  }
];

export const classTableColumns = [
  {
    title: "Class Id",
    dataIndex: "classId",
    key: "classId",
    fixed: "left",
    width: 100,
    sorter: true,
  },
  {
    title: "Class Description",
    dataIndex: "classDesc",
    key: "classDesc",
    fixed: "left",
    width: 200,
    ellipsis: true,
    render: (title, { classId }, record) => (
      <Link
        to={`/sku_data/class/${classId}_${encodeString(title)}`}
        onClick={(event) => event.preventDefault()}
        state={record}
        style={{ color: "black" }}
      >
        <u>{title}</u>
      </Link>
    ),
  },
  {
    title: "Subclass",
    dataIndex: "subclassCount",
    key: "subclassCount",
    width: 100,
  },
  {
    title: "Brands",
    dataIndex: "brandCount",
    key: "brandCount",
    width: 100,
  },
  {
    title: "SKUs",
    dataIndex: "skuCount",
    key: "skuCount",
    width: 100,
  },
  {
    title: "Markdown Price",
    dataIndex: "markdownPrice",
    key: "markdownPrice",
    width: 150,
    render: (text) => <>{text ? "Active" : "Disabled"}</>,
  },
  {
    title: "Base Price",
    dataIndex: "basePrice",
    key: "basePrice",
    width: 100,
    render: (text) => <>{text ? "Active" : "Disabled"}</>,
  },
  {
    title: "Active Competitors",
    dataIndex: "activeComp",
    key: "activeComp",
    width: 200,
    ellipsis: true,
    render: calculateCompetitors,
  },
  {
    title: "Price Rule",
    dataIndex: "priceRule",
    key: "priceRule",
    width: 300,
    ellipsis: true,
    render: (priceRule) => (
      <>
        {priceRule
          ? priceRuleDesc[priceRule]
          : priceRuleDesc["do_not_match_competitor"]}
      </>
    ),
  },
  {
    title: "Guardrail Min",
    dataIndex: [
      "defaultGuardMin",
      "defaultGuardMinPrice",
      "defaultGuardMinPriceSuffix",
    ],
    key: "defaultGuardMin",
    width: 200,
    render: (text, record) => renderGuardrailMin(record),
  },
  {
    title: "Guardrail Max",
    dataIndex: [
      "defaultGuardMax",
      "defaultGuardMaxPrice",
      "defaultGuardMaxPriceSuffix",
    ],
    key: "defaultGuardMax",
    width: 200,
    render: (text, record) => renderGuardrailMax(record),
  },
];

export const subClassTableColumns = [
  {
    title: "Subclass Id",
    dataIndex: "subclassId",
    key: "subclassId",
    fixed: "left",
    width: 100,
    sorter: true,
  },
  {
    title: "Subclass Description",
    dataIndex: "subclassDesc",
    key: "subclassDesc",
    fixed: "left",
    width: 200,
    ellipsis: true,
    render: (title, { subclassId }, record) => (
      <Link
        to={`/sku_data/subclass/${subclassId}_${encodeString(title)}`}
        onClick={(event) => event.preventDefault()}
        state={record}
        style={{ color: "black" }}
      >
        <u>{title}</u>
      </Link>
    ),
  },
  {
    title: "Brands",
    dataIndex: "brandCount",
    key: "brandCount",
    width: 100,
  },
  {
    title: "SKUs",
    dataIndex: "skuCount",
    key: "skuCount",
    width: 100,
  },
  {
    title: "Markdown Price",
    dataIndex: "markdownPrice",
    key: "markdownPrice",
    width: 150,
    render: (text) => <>{text ? "Active" : "Disabled"}</>,
  },
  {
    title: "Base Price",
    dataIndex: "basePrice",
    key: "basePrice",
    width: 100,
    render: (text) => <>{text ? "Active" : "Disabled"}</>,
  },
  {
    title: "Active Competitors",
    dataIndex: "activeComp",
    key: "activeComp",
    width: 200,
    ellipsis: true,
    render: calculateCompetitors,
  },
  {
    title: "Price Rule",
    dataIndex: "priceRule",
    key: "priceRule",
    width: 300,
    ellipsis: true,
    render: (priceRule) => (
      <>
        {priceRule
          ? priceRuleDesc[priceRule]
          : priceRuleDesc["do_not_match_competitor"]}
      </>
    ),
  },
  {
    title: "Guardrail Min",
    dataIndex: [
      "defaultGuardMin",
      "defaultGuardMinPrice",
      "defaultGuardMinPriceSuffix",
    ],
    key: "defaultGuardMin",
    width: 200,
    render: (text, record) => renderGuardrailMin(record),
  },
  {
    title: "Guardrail Max",
    dataIndex: [
      "defaultGuardMax",
      "defaultGuardMaxPrice",
      "defaultGuardMaxPriceSuffix",
    ],
    key: "defaultGuardMax",
    width: 200,
    render: (text, record) => renderGuardrailMax(record),
  },
];

export const brandsTableColumns = [
  {
    title: "Brand Id",
    dataIndex: "brandId",
    key: "brandId",
    fixed: "left",
    width: 100,
    sorter: true,
  },
  {
    title: "Brand Description",
    dataIndex: "brandDesc",
    key: "brandDesc",
    fixed: "left",
    width: 200,
    ellipsis: true,
    render: (title, { brandId }, record) => (
      <Link
        to={`/sku_data/brand/${brandId}_${encodeString(title)}`}
        onClick={(event) => event.preventDefault()}
        state={record}
        style={{ color: "black" }}
      >
        <u>{title}</u>
      </Link>
    ),
  },
  {
    title: "SKUs",
    dataIndex: "skuCount",
    key: "skuCount",
    width: 100,
  },
  {
    title: "Markdown Price",
    dataIndex: "markdownPrice",
    key: "markdownPrice",
    width: 150,
    render: (text) => <>{text ? "Active" : "Disabled"}</>,
  },
  {
    title: "Base Price",
    dataIndex: "basePrice",
    key: "basePrice",
    width: 100,
    render: (text) => <>{text ? "Active" : "Disabled"}</>,
  },
  {
    title: "Active Competitors",
    dataIndex: "activeComp",
    key: "activeComp",
    width: 200,
    ellipsis: true,
    render: calculateCompetitors,
  },
  {
    title: "Price Rule",
    dataIndex: "priceRule",
    key: "priceRule",
    width: 300,
    ellipsis: true,
    render: (priceRule) => (
      <>
        {priceRule
          ? priceRuleDesc[priceRule]
          : priceRuleDesc["do_not_match_competitor"]}
      </>
    ),
  },
  {
    title: "Guardrail Min",
    dataIndex: [
      "defaultGuardMin",
      "defaultGuardMinPrice",
      "defaultGuardMinPriceSuffix",
    ],
    key: "defaultGuardMin",
    width: 200,
    render: (text, record) => renderGuardrailMin(record),
  },
  {
    title: "Guardrail Max",
    dataIndex: [
      "defaultGuardMax",
      "defaultGuardMaxPrice",
      "defaultGuardMaxPriceSuffix",
    ],
    key: "defaultGuardMax",
    width: 200,
    render: (text, record) => renderGuardrailMax(record),
  },
];

export const unPricedSkuTableColumns = [
  {
    title: "SKU Id",
    dataIndex: "skuId",
    key: "skuId",
    fixed: "left",
    width: 100,
    sorter: true,
    defaultSortOrder: "ascend",
    render: (_, { skuId }) => (
      <Link
        to={`/sku_data/sku/${skuId}`}
        onClick={(event) => event.preventDefault()}
        style={{ color: "black" }}
      >
        <u>{skuId}</u>
      </Link>
    ),
  },
  {
    title: "SKU Description",
    dataIndex: "skuDesc",
    key: "skuDesc",
    width: 250,
    ellipsis: true,
  },
  {
    title: "SBU",
    dataIndex: "sbuDesc",
    key: "sbuDesc",
    width: 250,
    ellipsis: true,
  },
  {
    title: "Brand",
    dataIndex: "skuBrandDesc",
    key: "skuBrandDesc",
    width: 250,
    ellipsis: true,
  },
  {
    title: "Base cost",
    dataIndex: "costPrice",
    key: "costPrice",
    width: 100,
    render: (costPrice) => (
      <>{costPrice ? parseFloat(costPrice).toFixed(2) : "---"}</>
    ),
  },
  {
    title: "MAP",
    dataIndex: "mapPrice",
    key: "mapPrice",
    width: 100,
    render: (mapPrice) => (
      <>{mapPrice ? parseFloat(mapPrice).toFixed(2) : "---"}</>
    ),
  },
  {
    title: "MSRP",
    dataIndex: "listPrice",
    key: "listPrice",
    width: 100,
    render: (listPrice) => (
      <>{listPrice ? parseFloat(listPrice).toFixed(2) : "---"}</>
    ),
  },
  {
    title: "Reprice Error",
    dataIndex: "lastRepriceError",
    key: "lastRepriceError",
    width: 250,
    ellipsis: true,
  },
];

export const skuTableColumns = [
  {
    title: "SKU Id",
    dataIndex: "skuId",
    key: "skuId",
    fixed: "left",
    width: 100,
    sorter: true,
  },
  {
    title: "SKU Description",
    dataIndex: "skuDesc",
    key: "skuDesc",
    fixed: "left",
    width: 250,
    ellipsis: true,
    render: (title, { skuId }, record) => (
      <Link
        to={`/sku_data/sku/${skuId}`}
        onClick={(event) => event.preventDefault()}
        state={record}
        style={{ color: "black" }}
      >
        <u>{title}</u>
      </Link>
    ),
  },
  {
    title: "Published",
    dataIndex: ["skuPblshInd", "skuBuyableInd"],
    key: "published",
    width: 150,
    render: (text, record) => (
      <>
        {record["skuPblshInd"] &&
        record["skuPblshInd"] === 1 &&
        record["skuBuyableInd"] &&
        record["skuBuyableInd"] === 1
          ? "Published"
          : "Not Published"}
      </>
    ),
  },
  {
    title: "Base cost",
    dataIndex: "costPrice",
    key: "costPrice",
    width: 100,
    render: (costPrice) => (
      <>{costPrice ? parseFloat(costPrice).toFixed(2) : "---"}</>
    ),
  },
  {
    title: "MAP",
    dataIndex: "mapPrice",
    key: "mapPrice",
    width: 100,
    render: (mapPrice) => (
      <>{mapPrice ? parseFloat(mapPrice).toFixed(2) : "---"}</>
    ),
  },
  {
    title: "MSRP",
    dataIndex: "listPrice",
    key: "listPrice",
    width: 100,
    render: (listPrice) => (
      <>{listPrice ? parseFloat(listPrice).toFixed(2) : "---"}</>
    ),
  },
  {
    title: "Markdown Price",
    dataIndex: "markdownPriceEnabled",
    key: "markdownPriceEnabled",
    width: 150,
    render: (text) => <>{text ? "Active" : "Disabled"}</>,
  },
  {
    title: "Base Price",
    dataIndex: "basePriceEnabled",
    key: "basePriceEnabled",
    width: 100,
    render: (text) => <>{text ? "Active" : "Disabled"}</>,
  },
  {
    title: "Active Competitors",
    dataIndex: "activeComp",
    key: "activeComp",
    width: 200,
    ellipsis: true,
    render: calculateCompetitors,
  },
  {
    title: "Price Rule",
    dataIndex: "priceRule",
    key: "priceRule",
    width: 300,
    ellipsis: true,
    render: (priceRule) => (
      <>
        {priceRule
          ? priceRuleDesc[priceRule]
          : priceRuleDesc["do_not_match_competitor"]}
      </>
    ),
  },
  {
    title: "Guardrail Min",
    dataIndex: [
      "defaultGuardMin",
      "defaultGuardMinPrice",
      "defaultGuardMinPriceSuffix",
    ],
    key: "defaultGuardMin",
    width: 200,
    render: (text, record) => renderGuardrailMin(record),
  },
  {
    title: "Guardrail Max",
    dataIndex: [
      "defaultGuardMax",
      "defaultGuardMaxPrice",
      "defaultGuardMaxPriceSuffix",
    ],
    key: "defaultGuardMax",
    width: 200,
    render: (text, record) => renderGuardrailMax(record),
  },
];

export const petcoUsersTableColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    fixed: "left",
    width: 250,
    sorter: true,
    ellipsis: true,
    defaultSortOrder: "ascend",
  },
  {
    title: "Email Address",
    dataIndex: "email",
    key: "email",
    width: 250,
    ellipsis: true,
  },
  {
    title: "Invite Status",
    dataIndex: "invitationStatus",
    key: "invitationStatus",
    width: 200,
    render: (_, { invitationStatus }) =>
      typeof invitationStatus === "string" ? (
        <div>{inviteStatus[invitationStatus]}</div>
      ) : (
        <div>{inviteStatus["0"]}</div>
      ),
    ellipsis: true,
  },
  {
    title: "Last Logged In",
    dataIndex: "lastLogin",
    key: "lastLogin",
    width: 200,
    render: (_, { lastLogin }) =>
      lastLogin ? (
        <div>{convertToUserTimeZone(lastLogin).format("MM/DD/YYYY HH:mm:ss")}</div>
      ) : (
        "---"
      ),
  },
  {
    title: "Assigned Channels",
    dataIndex: "channelDetails",
    key: "channelDetails",
    width: 200,
    render: (text) => text ? text : '---',
    ellipsis: true,
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 200,
    ellipsis: true,
    render: (_, { createdAt }) =>
      createdAt ? (
        <div>{convertToUserTimeZone(createdAt).format("MM/DD/YYYY HH:mm:ss")}</div>
      ) : (
        "---"
      ),
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
    key: "createdBy",
    width: 200,
    render: (createdBy) => (
      <div>{createdBy ? getUserToDisplay(createdBy) : "-"}</div>
    ),
  },
  {
    title: "Updated At",
    dataIndex: "updatedAt",
    key: "updatedAt",
    width: 200,
    render: (_, { updatedAt }) =>
      updatedAt ? (
        <div>{convertToUserTimeZone(updatedAt).format("MM/DD/YYYY HH:mm:ss")}</div>
      ) : (
        "---"
      ),
  },
  {
    title: "Updated By",
    dataIndex: "updatedBy",
    key: "updatedBy",
    width: 200,
    render: (updatedBy) => (
      <div>{updatedBy ? getUserToDisplay(updatedBy) : "-"}</div>
    ),
  },
  {
    title: "Active User",
    dataIndex: "isActive",
    key: "isActive",
    fixed: "right",
    width: 150,
    render: (isActive, row) => <UserStateTrigger userData={row} />,
    onCell: () => ({
      onClick: (event) => {
        if (event.stopPropagation) event.stopPropagation();
      },
    }),
  },
];

export const merchantsTableColumns = [
  {
    title: "Merchant Name",
    dataIndex: "name",
    key: "name",
    fixed: "left",
    width: 250,
    sorter: true,
    ellipsis: true,
    defaultSortOrder: "ascend",
  },
  {
    title: "Email Address",
    dataIndex: "email",
    key: "email",
    width: 250,
    ellipsis: true,
  },
  {
    title: "Access Type",
    dataIndex: "editAccess",
    key: "editAccess",
    render: (editAccess) => (
      <div>
        {Object.keys(merchantAccessDisplayTypes).includes(editAccess)
          ? merchantAccessDisplayTypes[editAccess]
          : merchantAccessDisplayTypes.VIEW}
      </div>
    ),
    width: 150,
  },
  {
    title: "Class",
    dataIndex: "classCount",
    key: "classCount",
    width: 100,
  },
  {
    title: "Subclass",
    dataIndex: "subclassCount",
    key: "subclassCount",
    width: 100,
  },
  {
    title: "Brands",
    dataIndex: "brandCount",
    key: "brandCount",
    width: 100,
  },
  {
    title: "SKUs",
    dataIndex: "skuCount",
    key: "skuCount",
    width: 100,
  },
  {
    title: "Merchant Logs Count",
    dataIndex: "merchantLogCount",
    key: "merchantLogCount",
    width: 200,
  },
  {
    title: "Invite Status",
    dataIndex: "invitationStatus",
    key: "invitationStatus",
    width: 200,
    render: (_, { invitationStatus }) =>
      typeof invitationStatus === "string" ? (
        <div>{inviteStatus[invitationStatus]}</div>
      ) : (
        <div>{inviteStatus["0"]}</div>
      ),
  },
  {
    title: "Last Logged In",
    dataIndex: "lastLogin",
    key: "lastLogin",
    width: 200,
    render: (_, { lastLogin }) =>
      lastLogin ? (
        <div>{convertToUserTimeZone(lastLogin).format("MM/DD/YYYY HH:mm:ss")}</div>
      ) : (
        "---"
      ),
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 200,
    ellipsis: true,
    render: (_, { createdAt }) =>
      createdAt ? (
        <div>{convertToUserTimeZone(createdAt).format("MM/DD/YYYY HH:mm:ss")}</div>
      ) : (
        "---"
      ),
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
    key: "createdBy",
    width: 200,
    render: (createdBy) => (
      <div>{createdBy ? getUserToDisplay(createdBy) : "-"}</div>
    ),
  },
  {
    title: "Updated At",
    dataIndex: "updatedAt",
    key: "updatedAt",
    width: 200,
    render: (_, { updatedAt }) =>
      updatedAt ? (
        <div>{convertToUserTimeZone(updatedAt).format("MM/DD/YYYY HH:mm:ss")}</div>
      ) : (
        "---"
      ),
  },
  {
    title: "Updated By",
    dataIndex: "updatedBy",
    key: "updatedBy",
    width: 200,
    render: (updatedBy) => (
      <div>{updatedBy ? getUserToDisplay(updatedBy) : "-"}</div>
    ),
  },
  {
    title: "Active User",
    dataIndex: "isActive",
    key: "isActive",
    fixed: "right",
    width: 150,
    render: (isActive, row) => <UserStateTrigger userData={row} />,
    onCell: () => ({
      onClick: (event) => {
        if (event.stopPropagation) event.stopPropagation();
      },
    }),
  },
];

export const scheduledAlertsTableColumns = [
  {
    title: "SKU",
    dataIndex: "sku",
    key: "sku",
    fixed: "left",
    width: 100,
    sorter: true,
    render: (_, { skuId }) => (
      <Link to={`/sku_data/sku/${skuId}`} style={{ color: "black" }}>
        <u>{skuId}</u>
      </Link>
    ),
  },
  {
    title: "Alert Status",
    dataIndex: "status",
    key: "status",
    width: 100,
  },
  {
    title: "MSRP",
    dataIndex: "currentMsrp",
    key: "currentMsrp",
    width: 100,
    render: (_, { currentMsrp }) => <div>{`$${currentMsrp}`}</div>,
  },
  {
    title: "Sale Price Less than",
    dataIndex: "lessThanPrice",
    key: "lessThanPrice",
    width: 100,
    render: (lessThanPrice) => (
      <>{lessThanPrice ? parseFloat(lessThanPrice).toFixed(2) : "---"}</>
    ),
  },
  {
    title: "Sale Price Greater than",
    dataIndex: "greaterThanPrice",
    key: "greaterThanPrice",
    width: 100,
    render: (greaterThanPrice) => (
      <>{greaterThanPrice ? parseFloat(greaterThanPrice).toFixed(2) : "---"}</>
    ),
  },
  {
    title: "Start Date / End Date",
    key: "interval",
    width: 100,
    render: (_, { startDate, endDate }) => (
      <div>{`${startDate} - ${endDate}`}</div>
    ),
  },
];

export const triggeredAlertsTableColumns = [
  {
    title: "SKUs",
    dataIndex: "sku",
    key: "sku",
    fixed: "left",
    width: 100,
    sorter: true,
  },
  {
    title: "Triggered On",
    dataIndex: "triggeredOn",
    key: "triggeredOn",
    width: 100,
  },
  {
    title: "Trigger",
    dataIndex: "trigger",
    key: "trigger",
    width: 100,
  },
  {
    title: "Assigned Value",
    dataIndex: "assignedValue",
    key: "assignedValue",
    width: 100,
    render: (_, { assignedValue }) => <div>{`$${assignedValue}`}</div>,
  },
  {
    title: "MSRP",
    dataIndex: "currentMsrp",
    key: "currentMsrp",
    width: 100,
    render: (_, { currentMsrp }) => <div>{`$${currentMsrp}`}</div>,
  },
  {
    title: "Change (%)",
    dataIndex: "change",
    key: "change",
    width: 100,
    render: (_, { change }) => <div>{`${change}%`}</div>,
  },
];

export const CompetitorsColumns = [
  {
    title: "Competitors",
    dataIndex: "competitor",
    key: "competitor",
    fixed: "left",
    width: 100,
    sorter: (a, b) => a.competitor > b.competitor,
    render: (_, { competitor }) => <div>{corpLabel[competitor]}</div>,
  },
  {
    title: "Scraper Priority",
    dataIndex: "priority",
    key: "priority",
    width: 100,
    render: (_, { priority }) => <div>{corpLabel[priority]}</div>,
  },
  {
    title: "Adder",
    dataIndex: "adder",
    key: "adder",
    width: 100,
  },
  {
    title: "In Cart",
    dataIndex: "incart",
    key: "incart",
    width: 100,
  },
  {
    title: "PDP",
    dataIndex: "pdp",
    key: "pdp",
    width: 100,
  },
];

export const getCompetitorsWithRescrapeColumns = (
  configId,
  configdesc,
  configLevel,
  callback,
  scraper
) => {
  if (configId && configdesc && configLevel) {
    return [
      {
        title: "Competitors",
        dataIndex: "competitor",
        key: "competitor",
        fixed: "left",
        width: 100,
        sorter: true,
        render: (_, { competitor }) => <div>{corpLabel[competitor]}</div>,
      },
      {
        title: "Scraper Priority",
        dataIndex: "priority",
        key: "priority",
        width: 100,
        render: (_, { priority }) => <div>{corpLabel[priority]}</div>,
      },
      {
        title: "Adder",
        dataIndex: "adder",
        key: "adder",
        width: 100,
      },
      {
        title: "In Cart",
        dataIndex: "incart",
        key: "incart",
        width: 100,
      },
      {
        title: "PDP",
        dataIndex: "pdp",
        key: "pdp",
        width: 100,
      },
      {
        title: "Last Scraped Date",
        dataIndex: "lastScrapedDate",
        key: "lastScrapedDate",
        width: 150,
        render: (_, { lastScrapedDate }) => (
          <div>
            {lastScrapedDate
              ? convertToUserTimeZone(lastScrapedDate).format("MM/DD/YYYY HH:mm:ss")
              : "---"}
          </div>
        ),
      },
      {
        title: "Scrape Expiration",
        dataIndex: "scrapeExpiration",
        key: "scrapeExpiration",
        width: 100,
      },
      {
        title: "Rescrape",
        dataIndex: "rescrape",
        key: "rescrape",
        width: 115,
        fixed: "right",
        render: (_, record) => (
          <>
            <CompTableReScrapeModal
              configId={configId}
              configdesc={configdesc}
              competitor={record.competitor}
              configLevel={configLevel}
              scraper={record.priority}
              disableReScraperButton={record.scrapeRunStatus}
              callback={callback}
            />
          </>
        ),
      },
    ];
  }
};

export const getCompetitorsWithRescrapePriceColumns = (
  configId,
  configdesc,
  configLevel,
  callback,
  scraper
) => {
  if (configId && configdesc && configLevel) {
    return [
      {
        title: "Competitors",
        dataIndex: "competitor",
        key: "competitor",
        fixed: "left",
        width: 100,
        sorter: (a, b) => a.competitor > b.competitor,
        render: (_, { competitor }) => <div>{corpLabel[competitor]}</div>,
      },
      {
        title: "Scraper Priority",
        dataIndex: "priority",
        key: "priority",
        width: 100,
        render: (_, { priority }) => <div>{corpLabel[priority]}</div>,
      },
      {
        title: "PDP Price",
        dataIndex: "exactPdpPrice",
        key: "exactPdpPrice",
        width: 100,
        render: (_, { exactPdpPrice }) => (
          <>
            {parseFloat(exactPdpPrice)
              ? "$ " + parseFloat(exactPdpPrice).toFixed(2)
              : "---"}
          </>
        ),
      },
      {
        title: "PDP + Adder",
        dataIndex: "pdpPrice",
        key: "pdpPrice",
        width: 100,
        render: (_, { pdpPrice }) => (
          <>
            {parseFloat(pdpPrice)
              ? "$ " + parseFloat(pdpPrice).toFixed(2)
              : "---"}
          </>
        ),
      },
      {
        title: "In-Cart Price",
        dataIndex: "exactInCartPrice",
        key: "exactInCartPrice",
        width: 100,
        render: (_, { exactInCartPrice }) => (
          <>
            {parseFloat(exactInCartPrice)
              ? "$ " + parseFloat(exactInCartPrice).toFixed(2)
              : "---"}
          </>
        ),
      },
      {
        title: "In-Cart Price + Adder",
        dataIndex: "inCartPrice",
        key: "inCartPrice",
        width: 100,
        render: (_, { inCartPrice }) => (
          <>
            {parseFloat(inCartPrice)
              ? "$ " + parseFloat(inCartPrice).toFixed(2)
              : "---"}
          </>
        ),
      },
      {
        title: "OOS Status",
        dataIndex: "unmatchedOosReliability",
        key: "unmatchedOosReliability",
        width: 150,
        render: (_, { unmatchedOosReliability }) => (
          <div>
            {unmatchedOosReliability
              ? oos_status[unmatchedOosReliability]
              : "NULL/Other"}
          </div>
        ),
      },
      {
        title: "Adder",
        dataIndex: "adder",
        key: "adder",
        width: 100,
      },
      {
        title: "In Cart",
        dataIndex: "incart",
        key: "incart",
        width: 100,
      },
      {
        title: "PDP",
        dataIndex: "pdp",
        key: "pdp",
        width: 100,
      },
      {
        title: "Last Scraped Date",
        dataIndex: "lastScrapedDate",
        key: "lastScrapedDate",
        width: 150,
        render: (_, { lastScrapedDate }) => (
          <div>
            {lastScrapedDate
              ? convertToUserTimeZone(lastScrapedDate).format("MM/DD/YYYY HH:mm:ss")
              : "---"}
          </div>
        ),
      },
      {
        title: "Scrape Expiration",
        dataIndex: "scrapeExpiration",
        key: "scrapeExpiration",
        width: 100,
      },
      {
        title: "Rescrape",
        dataIndex: "rescrape",
        key: "rescrape",
        width: 115,
        fixed: "right",
        render: (_, record) => (
          <>
            <CompTableReScrapeModal
              configId={configId}
              configdesc={configdesc}
              competitor={record.competitor}
              configLevel={configLevel}
              scraper={record.priority}
              disableReScraperButton={record.scrapeRunStatus}
              callback={callback}
            />
          </>
        ),
      },
    ];
  }
};

export const scrapersTableColumns = [
  {
    title: "Scraper",
    dataIndex: "name",
    key: "name",
    fixed: "left",
    width: 50,
    sorter: (a, b) => a.name > b.name,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: 40,
    render: (_, { status }) => (
      <div
        className={`scraper-status scraper-${status ? "active" : "inactive"}`}
      >
        {status ? "Active" : "Inactive"}
      </div>
    ),
  },
  {
    title: "Last Scraped Date",
    dataIndex: "lastScrapedDate",
    key: "lastScrapedDate",
    width: 50,
    render: (_, { lastScrapedDate }) =>
      lastScrapedDate ? (
        <div>{convertToUserTimeZone(lastScrapedDate).format("MM/DD/YYYY HH:mm:ss")}</div>
      ) : (
        "---"
      ),
  },
  {
    title: "SKUs",
    dataIndex: "skuScraped",
    key: "skuScraped",
    width: 50,
    render: (_, { skuScraped }) => (skuScraped ? skuScraped : "0"),
  },
  {
    title: "Competitors",
    dataIndex: "competitors",
    key: "competitors",
    width: 40,
    render: (_, { competitors }) => {
      const compList = competitors ? competitors.split(",") : "";
      if (compList.length === 0) {
        return <div>{`---`}</div>;
      }
      if (compList.length === 1) {
        return <div>{`${corpLabel[compList[0]]}`}</div>;
      } else {
        return (
          <div>{`${corpLabel[compList[0]]}, +${compList.length - 1}`}</div>
        );
      }
    },
  },
  {
    title: "PDP Matching",
    dataIndex: "pdpMatching",
    key: "pdpMatching",
    width: 40,
    render: (_, { pdpMatching }) => (
      <div>{pdpMatching ? "Active" : "Disabled"}</div>
    ),
  },
  {
    title: "In-Cart Matching",
    dataIndex: "inCartMatching",
    key: "inCartMatching",
    width: 40,
    render: (_, { incartMatching }) => (
      <div>{incartMatching ? "Active" : "Disabled"}</div>
    ),
  },
  {
    title: "Expiry Date",
    dataIndex: "expiryDate",
    key: "expiryDate",
    width: 100,
    render: (_, { expiryDate }) => (
      <div>
        {expiryDate
          ? convertToUserTimeZone(expiryDate).format("MM/DD/YYYY HH:mm:ss")
          : "---"}
      </div>
    ),
  },
];

export const ScraperDataTableColumnsForScrapers = [
  {
    title: "SKU Id",
    dataIndex: "skuId",
    key: "skuId",
    fixed: "left",
    width: 100,
    sorter: true,
    render: (_, { skuId }) => <div>{skuId ? skuId : "---"}</div>,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 400,
    ellipsis: true,
    render: (_, { name }) => <div>{name ? name : "---"}</div>,
  },
  {
    title: "Date",
    dataIndex: "dayId",
    key: "dayId",
    width: 200,
    render: (_, { dayId }) => (
      <div>{dayId ? convertToUserTimeZone(dayId).format("MM/DD/YYYY HH:mm:ss") : "---"}</div>
    ),
  },
  {
    title: "Competitor",
    dataIndex: "competitor",
    key: "competitor",
    width: 150,
    ellipsis: true,
    render: (_, { competitor }) => (
      <div>{competitor ? corpLabel[competitor] : "---"}</div>
    ),
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    width: 100,
    render: (price) => <>{price ? parseFloat(price).toFixed(2) : "---"}</>,
  },

  {
    title: "Part Number",
    key: "partNumber",
    dataIndex: "partNumber",
    width: 150,
    ellipsis: true,
    render: (_, { partNumber }) => <div>{partNumber ? partNumber : "---"}</div>,
  },
  {
    title: "URL",
    key: "url",
    dataIndex: "url",
    width: 400,
    render: (_, { url }) => <div>{url ? url : "---"}</div>,
    ellipsis: true,
  },
  {
    title: "Inventory Stock",
    key: "inventoryStatus",
    dataIndex: "inventoryStatus",
    width: 150,
    render: (_, { inventoryStatus }) => (
      <div>{inventoryStatus ? inventoryStatus : "---"}</div>
    ),
  },
  {
    title: "Match Type",
    key: "matchType",
    dataIndex: "matchType",
    width: 100,
    render: (_, { matchType }) => <div>{matchType ? matchType : "---"}</div>,
  },
  {
    title: "Availability",
    key: "availability",
    dataIndex: "availability",
    width: 100,
    render: (_, { availability }) => (
      <div>{availability ? availability : "---"}</div>
    ),
  },
  {
    title: "Third Party",
    key: "thirdParty",
    dataIndex: "thirdParty",
    width: 100,
    render: (_, { thirdParty }) => (
      <div>{thirdParty ? (thirdParty === true ? "True" : "False") : "---"}</div>
    ),
  },
  {
    title: "Amazon FBA",
    key: "amazonFba",
    dataIndex: "amazonFba",
    width: 100,
    render: (_, { amazonFba }) => (
      <div>{amazonFba ? (amazonFba === true ? "True" : "False") : "---"}</div>
    ),
  },
  {
    title: "FBW",
    key: "fbw",
    dataIndex: "fbw",
    width: 100,
    render: (_, { fbw }) => (
      <div>{fbw ? (fbw === true ? "True" : "False") : "---"}</div>
    ),
  },
];

export const ScraperDataTableColumnsForCompetitors = [
  {
    title: "SKU Id",
    dataIndex: "skuId",
    key: "skuId",
    fixed: "left",
    width: 100,
    sorter: true,
    render: (_, { skuId }) => <div>{skuId ? skuId : "---"}</div>,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 300,
    render: (_, { name }) => <div>{name ? name : "---"}</div>,
    ellipsis: true,
  },
  {
    title: "Date",
    dataIndex: "dayId",
    key: "dayId",
    width: 200,
    render: (_, { dayId }) => (
      <div>{dayId ? convertToUserTimeZone(dayId).format("MM/DD/YYYY HH:mm:ss") : "---"}</div>
    ),
  },
  {
    title: "Scraper",
    dataIndex: "scraper",
    key: "scraper",
    width: 150,
    render: (_, { scraper }) => <div>{scraper ? scraper : "---"}</div>,
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    width: 100,
    render: (price) => <>{price ? parseFloat(price).toFixed(2) : "---"}</>,
  },

  {
    title: "Part Number",
    key: "partNumber",
    dataIndex: "partNumber",
    width: 150,
    ellipsis: true,
    render: (_, { partNumber }) => <div>{partNumber ? partNumber : "---"}</div>,
  },
  {
    title: "URL",
    key: "url",
    dataIndex: "url",
    width: 400,
    render: (_, { url }) => <div>{url ? url : "---"}</div>,
    ellipsis: true,
  },
  {
    title: "Inventory Stock",
    key: "inventoryStatus",
    dataIndex: "inventoryStatus",
    width: 150,
    render: (_, { inventoryStatus }) => (
      <div>{inventoryStatus ? inventoryStatus : "---"}</div>
    ),
  },
  {
    title: "Match Type",
    key: "matchType",
    width: 100,
    dataIndex: "matchType",
    render: (_, { matchType }) => <div>{matchType ? matchType : "---"}</div>,
  },
  {
    title: "Availability",
    key: "availability",
    dataIndex: "availability",
    width: 100,
    render: (_, { availability }) => (
      <div>{availability ? availability : "---"}</div>
    ),
  },
  {
    title: "Third Party",
    key: "thirdParty",
    dataIndex: "thirdParty",
    width: 100,
    render: (_, { thirdParty }) => (
      <div>{thirdParty ? (thirdParty === true ? "True" : "False") : "---"}</div>
    ),
  },
  {
    title: "Amazon FBA",
    key: "amazonFba",
    dataIndex: "amazonFba",
    width: 100,
    render: (_, { amazonFba }) => (
      <div>{amazonFba ? (amazonFba === true ? "True" : "False") : "---"}</div>
    ),
  },
  {
    title: "FBW",
    key: "fbw",
    dataIndex: "fbw",
    width: 100,
    render: (_, { fbw }) => (
      <div>{fbw ? (fbw === true ? "True" : "False") : "---"}</div>
    ),
  },
];

export const ScraperDataTableColumns = [
  {
    title: "Date",
    dataIndex: "dayId",
    key: "dayId",
    fixed: "left",
    width: 200,
    render: (_, { dayId }) => (
      <div>{dayId ? convertToUserTimeZone(dayId).format("MM/DD/YYYY HH:mm:ss") : "---"}</div>
    ),
  },
  {
    title: "SKU Id",
    dataIndex: "skuId",
    key: "skuId",
    fixed: "left",
    width: 100,

    render: (_, { skuId }) => <div>{skuId ? skuId : "---"}</div>,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 400,
    render: (_, { name }) => <div>{name ? name : "---"}</div>,
    ellipsis: true,
  },
  {
    title: "Scraper",
    dataIndex: "scraper",
    key: "scraper",
    width: 100,
    render: (_, { scraper }) => <div>{scraper ? scraper : "---"}</div>,
  },
  {
    title: "Competitor",
    dataIndex: "competitor",
    key: "competitor",
    width: 150,
    render: (_, { competitor }) => (
      <div>{competitor ? corpLabel[competitor] : "---"}</div>
    ),
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    width: 100,
    render: (price) => <>{price ? parseFloat(price).toFixed(2) : "---"}</>,
  },

  {
    title: "Part Number",
    key: "partNumber",
    dataIndex: "partNumber",
    width: 150,
    ellipsis: true,
    render: (_, { partNumber }) => <div>{partNumber ? partNumber : "---"}</div>,
  },
  {
    title: "URL",
    key: "url",
    dataIndex: "url",
    width: 400,
    render: (_, { url }) => <div>{url ? url : "---"}</div>,
    ellipsis: true,
  },
  {
    title: "Inventory Stock",
    key: "inventoryStatus",
    dataIndex: "inventoryStatus",
    width: 150,
    render: (_, { inventoryStatus }) => (
      <div>{inventoryStatus ? inventoryStatus : "---"}</div>
    ),
  },
  {
    title: "Match Type",
    key: "matchType",
    dataIndex: "matchType",
    width: 100,
    render: (_, { matchType }) => <div>{matchType ? matchType : "---"}</div>,
  },
  {
    title: "Availability",
    key: "availability",
    dataIndex: "availability",
    width: 100,
    render: (_, { availability }) => (
      <div>{availability ? availability : "---"}</div>
    ),
  },
  {
    title: "Third Party",
    key: "thirdParty",
    dataIndex: "thirdParty",
    width: 100,
    render: (_, { thirdParty }) => (
      <div>{thirdParty ? (thirdParty === true ? "True" : "False") : "---"}</div>
    ),
  },
  {
    title: "Amazon FBA",
    key: "amazonFba",
    dataIndex: "amazonFba",
    width: 100,
    render: (_, { amazonFba }) => (
      <div>{amazonFba ? (amazonFba === true ? "True" : "False") : "---"}</div>
    ),
  },
  {
    title: "FBW",
    key: "fbw",
    dataIndex: "fbw",
    width: 100,
    render: (_, { fbw }) => (
      <div>{fbw ? (fbw === true ? "True" : "False") : "---"}</div>
    ),
  },
];

export const ScraperAlertsTableColumns = [
  {
    title: "SKUs",
    dataIndex: "sku",
    key: "sku",
    fixed: "left",
    width: 100,
    sorter: true,
  },
  {
    title: "Brands",
    dataIndex: "brands",
    key: "brands",
    width: 100,
  },
  {
    title: "Original ($)",
    dataIndex: "original",
    key: "original",
    width: 100,
    render: (_, { original }) => <div>{`$${original}`}</div>,
  },
  {
    title: "Change ($)",
    dataIndex: "change",
    key: "change",
    width: 100,
    render: (_, { change }) => <div>{`$${change}`}</div>,
  },
  {
    title: "Change (%)",
    dataIndex: "chnagePercentage",
    key: "chnagePercentage",
    width: 100,
    render: (_, { chnagePercentage }) => <div>{`${chnagePercentage}%`}</div>,
  },
  {
    title: "Alert Reason",
    dataIndex: "reason",
    key: "reason",
    width: 200,
  },
  {
    title: "Actions",
    key: "Actions",
    width: 100,
    render: (_, row) => (
      <div>
        <Select
          options={[
            { value: "Val 1", label: "Label 1" },
            { value: "Val 2", label: "Label 2" },
          ]}
        />
      </div>
    ),
  },
];

export const getApprovalTableColumns = (channelId, callback) => {
  return [
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      ellipsis: true,
      fixed: "left",
      // sorter: true,
      defaultSortOrder: "descend",
      render: (_, { createdAt }) =>
        createdAt ? (
          <div>{convertToUserTimeZone(createdAt).format("MM/DD/YYYY HH:mm:ss")}</div>
        ) : (
          "---"
        ),
    },
    {
      title: "SKU",
      dataIndex: "skuId",
      key: "skuId",
      width: 100,
      render: (_, { skuId }) => (
        <Link to={`/channels/${channelId}/sku_data/sku/${skuId}`} style={{ color: "black" }}>
          <u>{skuId}</u>
        </Link>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 100,
      render: (price) => (
        <>{price ? `$${parseFloat(price).toFixed(2)}` : "---"}</>
      ),
    },
    {
      title: "Price reason",
      dataIndex: "priceReason",
      key: "priceReason",
      render: (priceReason) => <>{capitalizeFirstLetter(priceReason)}</>,
      width: 350,
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: 150,
      render: (createdBy) => (
        <div>{createdBy ? getUserToDisplay(createdBy) : "-"}</div>
      ),
    },
    {
      title: "Reject Code",
      dataIndex: "rejectCode",
      key: "rejectCode",
      width: 150,
      ellipsis: true,
    },
    {
      title: "Updated at",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 160,
      render: (_, { updatedAt }) =>
        updatedAt ? (
          <div>{convertToUserTimeZone(updatedAt).format("MM/DD/YYYY HH:mm:ss")}</div>
        ) : (
          "---"
        ),
    },
    {
      title: "Approve Code",
      dataIndex: "acceptCode",
      key: "acceptCode",
      width: 250,
      ellipsis: true,
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      key: "updatedBy",
      width: 150,
      render: (updatedBy) => (
        <div>{updatedBy ? getUserToDisplay(updatedBy) : "-"}</div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      fixed: "right",
      render: (_, record) => (
        <>
          <ApprovalStatusModal
            requestId={record.id}
            skuId={record.skuId}
            requestStatus={record.status}
            isDiscarded={record.isDiscarded}
            rejectCode={record.rejectCode}
            callback={callback}
          />
        </>
      ),
    },
  ];
};

export const levelConfigurationLogsColumns = [
  {
    title: "Log Date",
    dataIndex: "createdAt",
    key: "createdAt",
    fixed: "left",
    width: 150,
    render: (_, { createdAt }) =>
      createdAt ? (
        <div>{convertToUserTimeZone(createdAt).format("MM/DD/YYYY HH:mm:ss")}</div>
      ) : (
        "---"
      ),
  },
  {
    title: "Change Type",
    dataIndex: "eventName",
    key: "eventName",
    width: 200,
    ellipsis: true,
    render: (eventName) => <>{eventName.replaceAll("_", " ")}</>,
  },
  {
    title: "Status",
    dataIndex: "eventType",
    key: "eventType",
    width: 100,
  },
  {
    title: "Change By",
    dataIndex: "createdBy",
    key: "createdBy",
    width: 100,
    ellipsis: true,
    render: (createdBy) => (
      <div>{createdBy ? getUserToDisplay(createdBy) : "-"}</div>
    ),
  },
  {
    title: "Log Details",
    dataIndex: "eventData",
    key: "eventData",
    render: (eventData) => (
      <div>{eventData ? capitalizeFirstLetter(eventData) : "-"}</div>
    ),
    width: 400,
    ellipsis: true,
  },
];

export const userConfigurationLogsColumns = [
  {
    title: "Log Date",
    dataIndex: "createdAt",
    key: "createdAt",
    fixed: "left",
    width: 150,
    render: (_, { createdAt }) =>
      createdAt ? (
        <div>{convertToUserTimeZone(createdAt).format("MM/DD/YYYY HH:mm:ss")}</div>
      ) : (
        "---"
      ),
  },
  {
    title: "Hierarchy Updated",
    dataIndex: "eventLevel",
    key: "eventLevel",
    width: 100,
  },
  {
    title: "Change Assigned To",
    dataIndex: "eventLevelId",
    key: "eventLevelId",
    width: 100,
    ellipsis: true,
  },
  {
    title: "Change Type",
    dataIndex: "eventName",
    key: "eventName",
    width: 200,
    ellipsis: true,
    render: (eventName) => (
      <>{capitalizeFirstLetter(eventName.replaceAll("_", " "))}</>
    ),
  },
  {
    title: "Change Status",
    dataIndex: "eventType",
    key: "eventType",
    width: 100,
  },
  {
    title: "Log Details",
    dataIndex: "eventData",
    key: "eventData",
    width: 400,
    ellipsis: true,
  },
];

export const userSubscriptionsDataColumns = [
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 100,
    fixed: "left",
    sorter: true,
    defaultSortOrder: "descend",
  },
  {
    title: "SKU",
    dataIndex: "skuId",
    key: "eventLevelId",
    fixed: "left",
    width: 50,
    render: (_, { skuId }) => (
      <Link to={`/sku_data/sku/${skuId}`} style={{ color: "black" }}>
        <u>{skuId}</u>
      </Link>
    ),
  },
  {
    title: "Alert Status",
    dataIndex: "status",
    key: "status",
    fixed: "left",
    width: 50,
  },
  {
    title: "Less Than Price",
    dataIndex: "lessThanPrice",
    key: "lessThanPrice",
    width: 70,
  },
  {
    title: "Greater Than Price",
    dataIndex: "greaterThanPrice",
    key: "greaterThanPrice",
    width: 50,
  },
  {
    title: "Start Date/End Date",
    dataIndex: "subDate",
    key: "subDate",
    width: 100,
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
    key: "createdBy",
    width: 100,
    render: (createdBy) => (
      <div>{createdBy ? getUserToDisplay(createdBy) : "-"}</div>
    ),
  },
  {
    title: "Updated By",
    dataIndex: "updatedBy",
    key: "updatedBy",
    width: 100,
    render: (updatedBy) => (
      <div>{updatedBy ? getUserToDisplay(updatedBy) : "-"}</div>
    ),
  },
];

export const matchIssuesTableColumns = [
  {
    title: `${created_at}`,
    dataIndex: "createdAt",
    key: "createdAt",
    width: 100,
    sorter: true,
    fixed: "left",
    defaultSortOrder: "descend",
  },
  {
    title: "SKU",
    dataIndex: "skuId",
    key: "skuId",
    fixed: "left",
    width: 50,
    render: (_, { skuId }) => (
      <Link to={`/sku_data/sku/${skuId}`} style={{ color: "black" }}>
        <u>{skuId}</u>
      </Link>
    ),
  },
  {
    title: `${petco_product_link}`,
    dataIndex: "petcoProductLink",
    key: "petcoProductLink",
    width: 100,
    ellipsis: true,
  },
  {
    title: `${competitor_product_link}`,
    dataIndex: "competitorProductLink",
    key: "competitorProductLink",
    width: 100,
    ellipsis: true,
  },
  {
    title: `${description}`,
    dataIndex: "description",
    key: "description",
    width: 100,
    ellipsis: true,
  },
  {
    title: `${created_by}`,
    dataIndex: "createdBy",
    key: "createdBy",
    width: 100,
    render: (createdBy) => (
      <div>{createdBy ? getUserToDisplay(createdBy) : "-"}</div>
    ),
    ellipsis: true,
  },
];

export const AlertsTableColumns = [
  {
    title: "Triggered On",
    dataIndex: "createdAt",
    key: "createdAt",
    fixed: "left",
    width: 100,
    sorter: true,
    defaultSortOrder: "descend",
    render: (_, { createdAt }) =>
      createdAt ? (
        <div>{convertToUserTimeZone(createdAt).format("MM/DD/YYYY HH:mm:ss")}</div>
      ) : (
        "---"
      ),
  },
  {
    title: "SKU",
    dataIndex: "skuId",
    key: "skuId",
    fixed: "left",
    width: 50,
    render: (_, { skuId }) => (
      <Link to={`/sku_data/sku/${skuId}`} style={{ color: "black" }}>
        <u>{skuId}</u>
      </Link>
    ),
  },
  {
    title: "Trigger Reason",
    dataIndex: "alertName",
    key: "alertName",
    width: 100,
  },
  {
    title: "Subscribed By",
    dataIndex: "subscribedBy",
    key: "subscribedBy",
    width: 100,
    ellipsis: true,
    render: (subscribedBy) => (
      <div>{subscribedBy ? getUserToDisplay(subscribedBy) : "-"}</div>
    ),
  },
  {
    title: "Recommended Price",
    dataIndex: "alertDetails",
    key: "alertDetails",
    width: 50,
    render: (_, { alertDetails }) =>
      alertDetails ? (
        // <div>${JSON.parse(alertDetails).candiate_price?.toFixed(2)}</div>
        <>---</>
      ) : (
        "---"
      ),
  },
  {
    title: "Current Price",
    dataIndex: "alertDetails",
    key: "alertDetails",
    width: 50,
    render: (_, { alertDetails }) =>
      alertDetails ? (
        // <div>${JSON.parse(alertDetails).current_price?.toFixed(2)}</div>
        <>---</>
      ) : (
        "---"
      ),
  },
  {
    title: "Change %",
    dataIndex: "alertDetails",
    key: "alertDetails",
    width: 50,
    render: (_, { alertDetails }) =>
      alertDetails ? (
        // <div style={{ color: "red" }}>
        //   {JSON.parse(alertDetails).percent_change?.toFixed(2)} %
        // </div>
        <>---</>
      ) : (
        "---"
      ),
  },
];

export const bulkSelectedSkuDataTableColumns = [
  {
    title: "Channel Id",
    dataIndex: "channelId",
    key: "channelId",
    fixed: "left",
    width: 100,
  },
  {
    title: "SKU Id",
    dataIndex: "skuId",
    key: "skuId",
    fixed: "left",
    width: 100,
    sorter: (a, b) => a.skuId - b.skuId,
  },
  {
    title: "SKU Description",
    dataIndex: "skuDesc",
    key: "skuDesc",
    //fixed: "left",
    width: 250,
    ellipsis: true,
  },
  {
    title: "Class",
    dataIndex: "classDesc",
    key: "classDesc",
    width: 200,
    ellipsis: true,
  },
  {
    title: "Subclass",
    dataIndex: "subClassDesc",
    key: "subClassDesc",
    width: 200,
    ellipsis: true,
  },
  {
    title: "Brand",
    dataIndex: "skuBrandDesc",
    key: "skuBrandDesc",
    width: 150,
    ellipsis: true,
  },
];

export const bulkAllSkuDataTableColumns = [
  {
    title: "SKU Id",
    dataIndex: "skuId",
    key: "skuId",
    fixed: "left",
    width: 100,
    sorter: true,
  },
  {
    title: "SKU Description",
    dataIndex: "skuDesc",
    key: "skuDesc",
    fixed: "left",
    width: 250,
    ellipsis: true,
  },
  {
    title: "Class",
    dataIndex: "classDesc",
    key: "classDesc",
    width: 200,
    ellipsis: true,
  },
  {
    title: "Subclass",
    dataIndex: "subClassDesc",
    key: "subClassDesc",
    width: 200,
    ellipsis: true,
  },
  {
    title: "Brand",
    dataIndex: "skuBrandDesc",
    key: "skuBrandDesc",
    width: 150,
    ellipsis: true,
  },
];
