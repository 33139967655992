import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Table, Pagination, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { userSubscriptionsDataColumns } from '../../constants/tableColumns';
import { Notification, convertToUserTimeZone } from '../../utilities';
import { getUserSubscriptionData } from '../../graphql/queryExecuters';

import './Tables.scss';
import './UserLogs.table.scss';

const ScheduledAlertsTable = (propsData) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(false);
    const [pageindex, setPageindex] = useState(searchParams.get('index') ? Number(searchParams.get('index')) : 1);
    const [searchText, setSearchText] = useState(searchParams.get('search') ? searchParams.get('search') : '');
    const [sortType, setSortType] = useState('DESC');
    const [user, setUser] = useState(propsData.userId || '');
    const [tableData, setTableData] = useState([]);
    const [rowCount, setRowCount] = useState(propsData.userSubsCount);

    const channelId = useSelector((state) => state.channelData.channelId);

    useEffect(() => {
        getUserSubscriptionsDataTrigger();
    }, [user, sortType, propsData?.reload]); //eslint-disable-line

    useEffect(() => {
        checkPageIndexChange();
    }, [searchParams]); //eslint-disable-line

    const getUserSubscriptionsDataTrigger = async (pageNumber = null) => {
        try {
            setLoading(true);
            let subsData = [];
            const page = pageNumber || pageindex;

            subsData = await getUserSubscriptionData(channelId, user, searchText, page - 1, sortType);
            const value = subsData.dataCount ; 
            onSearchInput(value);
            let _subsData=[]
            if(subsData.data){
                _subsData = subsData.data.map((item) => {
                    return {
                        skuId: item.skuId,
                        lessThanPrice: item.lessThanPrice && item.lessThanPriceSuffix ? item.lessThanPriceSuffix === "$" ? `$${item.lessThanPrice.toFixed(2)}` : `${item.lessThanPrice}%` : '-',
                        greaterThanPrice: item.greaterThanPrice && item.greaterThanPriceSuffix ? item.greaterThanPriceSuffix==="$"? `$${item.greaterThanPrice.toFixed(2)}` : `${item.greaterThanPrice}%` : '-',
                        subDate: `${item.subStartDate && item.subStartDate ? convertToUserTimeZone(item.subStartDate).format('MM/DD/YYYY') + ' - ' + convertToUserTimeZone(item.subEndDate).format('MM/DD/YYYY') : '-'}`,
                        createdAt: item.createdAt ? convertToUserTimeZone(item.createdAt).format('MM/DD/YYYY HH:mm:ss') : '-',
                        createdBy: item.createdBy,
                        updatedBy: item.updatedBy
                    }
                })
            }
            if (subsData.dataCount < (page - 1) * 10) setPageindex(1);
            setTableData(_subsData);
            setRowCount(subsData.dataCount);
            setLoading(false);
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting Subscriptions. Please try again later.'
            );
            setLoading(false);
        }
    };

    const checkPageIndexChange = () => {
        const index = searchParams.get('index') || 1;
        if (index) {
            const indexToBeSwitch = Number(index);
            if (indexToBeSwitch !== pageindex) {
                setPageindex(indexToBeSwitch);
                getUserSubscriptionsDataTrigger(indexToBeSwitch);
            }
        }
    }
    
    const onSearchInput = (value) => {
        if(value < (pageindex - 1) * 10) {
            setPageindex(1);
            if (propsData.level === 'all') {
                searchParams.set('index', 1);
                setSearchParams(searchParams);
            }
        }
    }
    
    const handlePageChange = (event) => {
        setPageindex(event);
        if (propsData.level === 'all') {
            searchParams.set('index', event);
            setSearchParams(searchParams);
        }
        getUserSubscriptionsDataTrigger(event);
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    //Search when user press ENTER
    const onPressEnterKeyHandler = (event) => {
        try {
            if (event.key === 'Enter') {
                getUserSubscriptionsDataTrigger();
                if (propsData.level === 'all') {
                    searchParams.set('search', searchText);
                    setSearchParams(searchParams);
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        let sort = sorter.order === 'ascend' ? 'ASC' : sorter.order === 'descend' ? 'DESC' : '';
        setSortType(sort);
    };

    return (
        <div className="table-container">
            <div className="inlineContainer">
                <Input
                    value={searchText}
                    className="data-search"
                    id="scheduled-alerts-search"
                    prefix={<SearchOutlined />}
                    onKeyDown={onPressEnterKeyHandler}
                    onChange={handleSearchChange}
                    placeholder="Search for SKU"
                    suffix={<span className='press-enter-style'>Press enter to search</span>}
                />
                <Select
                    className="user-filter-dropdown"
                    id='schedulealerts-users'
                    onChange={(value) => {
                        value === 'all' ? setUser('') : setUser(value);
                    }}
                    defaultValue={'all'}
                    size="large"
                    options={propsData.userData}
                />
            </div>
            <Table
                rowKey="id"
                id="scheduledAlerts-table"
                style={{marginBottom: '16px'}}
                dataSource={tableData}
                columns={userSubscriptionsDataColumns}
                loading={loading}
                pagination={false}
                size="middle"
                className="table-striped-rows"
                scroll={{ x: 1000 }}
                onChange={handleTableChange}
            />
            {rowCount > 10 ? <Pagination
                current={pageindex}
                className="data-pagination"
                defaultCurrent={pageindex}
                total={rowCount}
                onChange={handlePageChange}
                showSizeChanger={false}
                size="small"
            /> : <></>}
        </div>
    );
};

export default ScheduledAlertsTable;
