import React, {useState} from "react";
import { Pagination, Checkbox, Divider } from 'antd';

import './Tables.scss'
import { priceRequest } from "../../constants/common";

const RejectCodeTable = ({ level, codes, updateTagValues, handleCheck }) => {
    const [pageIndex, setPageindex] = useState(1);
    const pageSize = priceRequest.pageSize;
    const rowCount = codes.length;

    const handleCheckboxSelection = async (checkedValues) => {
        const status = {
            value: checkedValues.target.options[0].value,
            checked: checkedValues.target.checked
        }
        updateTagValues(status)
    };

    const getDataForCurrentPage = () => {
        const startIndex = (pageIndex -1 ) * pageSize;
        const endIndex = startIndex + pageSize;
        return codes.slice(startIndex, endIndex)
    }

    const paginatedData = getDataForCurrentPage();

    return(
        <div className="table-container">
                {paginatedData.map((reason, index) => {
                    return (
                        <li
                            key={index}
                            style={{ color: '#001952', font: '10px', marginBottom: '10px' }}>
                            {
                                <Checkbox
                                    key={`${reason} + ${index}`}
                                    checked={handleCheck(reason, index, level)}
                                    options={[
                                        {
                                            label: reason,
                                            value: {
                                                desc: reason,
                                                id: index,
                                                level: level
                                            }
                                        }
                                    ]}
                                    onChange={handleCheckboxSelection}>
                                    {reason}
                                </Checkbox>
                            }{' '}
                           <Divider style={{margin: '15px'}} />
                        </li>
                    );
                })}
            {rowCount > 10 ? (
                <Pagination
                    className="data-pagination"
                    id="reject-alerts-filter"
                    defaultCurrent={pageIndex}
                    defaultPageSize={pageSize}
                    total={rowCount}
                    onChange={(event) => {setPageindex(event)}}
                    showSizeChanger={false}
                    size="small"
                />
            ) : (
                <></>
            )}
        </div>
    );
}

export default RejectCodeTable;