import GraphQL from "../../configs/graphQL.config";

import * as QueryCreators from "../queryCreators";

export const getAllBrandsData = async (
  channelId,
  index,
  size,
  search,
  sortType
) => {
  try {
    const query = QueryCreators.createGetAllBrandsDataQuery(
      channelId,
      index,
      size,
      search,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllBrands;
  } catch (err) {
    console.log(err);
  }
};

export const getBrandDetails = async (brandId, brandDesc) => {
  try {
    const query = QueryCreators.createGetBrandsDataByIdQuery(
      brandId,
      brandDesc
    );
    const response = await GraphQL.request(query);
    return response.getLevelDetails;
  } catch (err) {
    console.log(err);
  }
};

export const getBrandAtClassData = async (
  channelId,
  id,
  index,
  size,
  search,
  sortType
) => {
  try {
    const query = QueryCreators.createGetBrandAtClassDataQuery(
      channelId,
      id,
      index,
      size,
      search,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllBrandsAtClass;
  } catch (err) {
    console.log(err);
  }
};

export const getBrandAtSubclassData = async (
  channelId,
  id,
  index,
  size,
  search,
  sortType
) => {
  try {
    const query = QueryCreators.createGetBrandAtSubclassDataQuery(
      channelId,
      id,
      index,
      size,
      search,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllBrandsAtSubclass;
  } catch (err) {
    console.log(err);
  }
};

export const getAllAssignBrandData = async (
  channelId,
  merchantId,
  index,
  size,
  search,
  sortType
) => {
  try {
    const query = QueryCreators.createGetAllAssignBrandDataQuery(
      channelId,
      merchantId,
      index,
      size,
      search,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllAssignBrands;
  } catch (err) {
    console.log(err);
  }
};

export const getAssignBrandAtClassData = async (
  merchantId,
  id,
  index,
  size,
  search,
  sortType
) => {
  try {
    const query = QueryCreators.createGetAssignBrandAtClassDataQuery(
      merchantId,
      id,
      index,
      size,
      search,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllAssignBrandsAtClass;
  } catch (err) {
    console.log(err);
  }
};

export const getAssignBrandAtSubclassData = async (
  merchantId,
  id,
  index,
  size,
  search,
  sortType
) => {
  try {
    const query = QueryCreators.createGetAssignBrandAtSubclassDataQuery(
      merchantId,
      id,
      index,
      size,
      search,
      sortType
    );
    const response = await GraphQL.request(query);
    return response.getAllAssignBrandsAtSubclass;
  } catch (err) {
    console.log(err);
  }
};

export const getAllMerchantAssignedBrandData = async (channelId, merchantId, searchedtext) => {
  try {
    const query = QueryCreators.getAllMerchantAssignedBrandQuery(channelId, merchantId, searchedtext);
    const response = await GraphQL.request(query);
    return response.getMerchantBrandsToAssignChannel;
  } catch (err) {
    console.log(err);
  }
};